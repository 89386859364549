import React, { useEffect, useState } from "react";

import { Profile } from "./utils";

import { CardWithLoadingState, Tabs } from "../../../components";
import { getProfileSettings, postPreferredLanguage } from "../../../api";
import { preferredLanguages } from "../../../utils";

const AccountSettings = ({ isInitialLoading, email }) => {
  const tabs = [
    {
      name: "Profile",
    },
  ];

  // Loading
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(tabs[0]); // Default tab is Profile

  // Profile data
  const [name, setName] = useState(null);
  const [profileEmail, setProfileEmail] = useState(null);
  const [title, setTitle] = useState(null);
  const [department, setDepartment] = useState(null);
  const [language, setLanguage] = useState(null);

  const sendPreferredLanguage = async (setErrorMessage, setSuccessMessage) => {
    setErrorMessage("");
    setSuccessMessage("");

    const filteredPreferredLanguages = preferredLanguages.filter(
      (preferredLanguage) =>
        language === `${preferredLanguage.name} ${preferredLanguage.flag}`,
    );

    const requestBody = {};

    if (filteredPreferredLanguages.length > 0) {
      requestBody.preferredLanguage =
        filteredPreferredLanguages[0].languageCode;
    } else {
      setErrorMessage("An error has occurred. Please try again.");

      return;
    }

    const postPreferredLanguageResponse =
      await postPreferredLanguage(requestBody);

    if (Object.keys(postPreferredLanguageResponse.error).length > 0) {
      console.error(postPreferredLanguageResponse.error.message);
      setErrorMessage("An error has occurred. Please try again.");
    } else {
      setSuccessMessage("Your profile has been updated successfully.");
    }
  };

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const handleClickSaveProfile = (setErrorMessage, setSuccessMessage) => {
    sendPreferredLanguage(setErrorMessage, setSuccessMessage);
  };

  useEffect(() => {
    const loadProfileSettings = async () => {
      setIsLoading(true);

      const getProfileSettingsResponse = await getProfileSettings();

      if (Object.keys(getProfileSettingsResponse.error).length > 0) {
        console.error(getProfileSettingsResponse.error.message);
      } else {
        const { name, profileEmail, title, department, language } =
          getProfileSettingsResponse.result;

        setName(name);
        setProfileEmail(profileEmail);
        setTitle(title);
        setDepartment(department);

        const filteredPreferredLanguages = preferredLanguages.filter(
          (preferredLanguage) => language === preferredLanguage.languageCode,
        );

        if (filteredPreferredLanguages.length > 0) {
          setLanguage(
            `${filteredPreferredLanguages[0].name} ${filteredPreferredLanguages[0].flag}`,
          );
        } else {
          setLanguage("English 🇺🇸");
        }
      }

      setIsLoading(false);
    };

    if (!isInitialLoading && email) {
      loadProfileSettings();
    }
  }, [isInitialLoading, email]);

  return (
    <CardWithLoadingState loadingVariable={isLoading}>
      <div>
        <Tabs
          data={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div>
        {selectedTab && selectedTab.name === "Profile" && (
          <Profile
            name={name}
            profileEmail={profileEmail}
            title={title}
            department={department}
            language={language}
            onChangeLanguage={handleChangeLanguage}
            onClickSaveProfile={handleClickSaveProfile}
          />
        )}
      </div>
    </CardWithLoadingState>
  );
};

export default AccountSettings;
