import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { DataTable } from "../../../components";

import { getUsersByManager } from "../../../api";

import { headers } from "../../../utils/constants/platform/search-employee";

function InsightsYourReports({
  isInitialLoading,
  email,
  setIsPlatformLoading,
}) {
  // Users selector
  const [users, setUsers] = useState(null);

  const { state } = useLocation();

  useEffect(() => {
    const loadUsers = async () => {
      if (email === "aaron.chavez@dune.demo") {
        setUsers([
          {
            firstName: "Aaron",
            lastName: "Chavez (You)",
            email: "aaron.chavez@dune.demo",
            department: "Capital",
            role: "Chief Financial Officer",
            compliant: true,
            complianceFrameworks: [],
            riskScore: 14,
          },
          {
            firstName: "Lisa",
            lastName: "MD",
            email: "lisa.md@dune.demo",
            department: "Capital",
            role: "Head of ESG & Impact",
            compliant: true,
            complianceFrameworks: [],
            riskScore: 14,
          },
          {
            firstName: "Kyle",
            lastName: "Walker",
            email: "kyle.walker@dune.demo",
            department: "Capital",
            role: "Capital Manager",
            compliant: true,
            complianceFrameworks: [],
            riskScore: 43,
          },
          {
            firstName: "Michael",
            lastName: "Waite",
            email: "michael.waite@dune.demo",
            department: "Capital",
            role: "Capital Director",
            compliant: false,
            complianceFrameworks: [],
            riskScore: 97,
          },
        ]);
      } else {
        const usersData = await getUsersByManager();

        if (Object.keys(usersData.error).length > 0) {
          console.error(usersData.error.message);
        } else {
          const { users } = usersData.result;

          // Check if the signed in user is in the users list. If they are, note that it is them.
          const updatedUsers = users.map(({ lastName, ...user }) => {
            if (user.email === email) {
              return {
                lastName: `${lastName} (You)`,
                ...user,
              };
            }

            return {
              lastName,
              ...user,
            };
          });

          setUsers(updatedUsers);
        }
      }
    };

    const updateComponent = async () => {
      setIsPlatformLoading(true);
      await loadUsers();
      setIsPlatformLoading(false);
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [isInitialLoading, email, state, setIsPlatformLoading]);

  return (
    <div className="mt-1.25">
      {users && (
        <DataTable
          data={users}
          headers={headers}
          defaultOrder="desc"
          defaultOrderBy="riskScore"
        />
      )}
    </div>
  );
}

export default InsightsYourReports;
