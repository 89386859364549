import React, { useState, useRef, useEffect } from "react";
import { DropDownArrowIcon } from "../../../utils";
import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";

const Dropdown = ({
  options,
  onSelect,
  label,
  description,
  placeholder = "Select",
  width = "100%",
  reset,
  uniqueKey,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect && onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (reset) {
      setSelectedOption();
    }
  }, [reset]);

  return (
    <div className="lg:flex flex-row justify-between items-center mb-2">
      <div className="w-1/2">
        {label && <div className="text-h5">{label}</div>}
        {description && (
          <div className="text-description text-gray-surface-secondary">
            {description}
          </div>
        )}
      </div>
      <div
        className="w-1/2 max-w-35 relative mr-3.625 md:mt-1 mt-1"
        ref={dropdownRef}
        style={{ width }}
      >
        <div
          className={`border ${isOpen ? "border-green" : "border-gray border-opacity-35"} px-1 py-0.75 flex justify-between items-center rounded-medium text-gray text-opacity-65 cursor-pointer`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={selectedOption ? "text-white" : ""}>
            {selectedOption || placeholder}
          </div>
          <div className="text-gray text-opacity-65">
            <DropDownArrowIcon stroke="#848483" />
          </div>
        </div>

        {isOpen && (
          <div className="absolute top-full left-0 w-full bg-black border border-gray border-opacity-35 rounded-medium shadow-lg z-10 text-gray text-opacity-65 mt-0.5">
            <ul>
              {options.map((option, index) => (
                <div>
                  <li
                    key={`${uniqueKey}-${index}`}
                    className={`cursor-pointer px-1 py-0.5 hover:bg-gray hover:bg-opacity-35 text-gray-surface-contrast ${index === options.length - 1 ? "rounded-b-medium" : ""} ${index === 0 ? "rounded-t-medium" : ""}`}
                    onClick={() => handleOptionSelect(option)}
                  >
                    {capitalizeFirstWords(option)}
                  </li>
                  {index !== options.length - 1 && (
                    <div className=" text-gray text-opacity-35">
                      <hr />
                    </div>
                  )}
                </div>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
