import React from "react";

import { ButtonContainedGreen } from "../../../../../components";

const StepTwo = ({ onClickRequestLaunch }) => {
  const configurations = [
    {
      name: "Initial Test Duration",
      description: "Number of weeks the initial assessment lasts",
      number: 5,
    },
    {
      name: "Social Engineering tests per week",
      description: "Number of emails sent to new users each week",
      number: 3,
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">
          Kick Off Your Initial Testing
        </h4>
      </div>
      <div className="flex flex-col mt-2 px-0.625 gap-3 max-w-[452px] w-full">
        {configurations.map((configuration, index) => (
          <div
            className="flex items-center justify-between"
            key={`configuration-${index}`}
          >
            <div className="flex flex-col gap-0.25 max-w-[248px] w-full">
              <h5 className="body">{configuration.name}</h5>
              <p className="description text-gray-surface-secondary">
                {configuration.description}
              </p>
            </div>
            <div className="flex justify-center max-w-[136px] w-full">
              <p className="h2">{configuration.number}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="pt-2 mt-2">
        <ButtonContainedGreen
          title="Request the launch of your initial testing."
          onClick={onClickRequestLaunch}
        >
          Request Launch
        </ButtonContainedGreen>
      </div>
    </div>
  );
};

export default StepTwo;
