export const ANOMALOUS_ACTIVITY_STATUSES = [
  {
    firstName: "Michael",
    lastName: "Waite",
    activity: "Unauthorized IP location",
    activityTime: "11/15/2024 at 2:15 pm",
    riskScore: 98,
    dataFrom: "Entra ID",
    initials: "MW",
  },
  {
    firstName: "Andy",
    lastName: "Cottle",
    activity: "Unauthorized IP location",
    activityTime: "11/15/2024 at 1:46 pm",
    riskScore: 97,
    dataFrom: "Entra ID",
    initials: "AC",
  },
  {
    firstName: "Anna",
    lastName: "Philips",
    activity: "Anomalous Login time",
    activityTime: "11/15/2024 at 11:12 am",
    riskScore: 94,
    dataFrom: "Entra ID",
    initials: "AC",
  },
  {
    firstName: "Sherry",
    lastName: "Mackey",
    activity: "Multiple Login Attempts",
    activityTime: "11/14/2024 at 3:15 pm",
    riskScore: 87,
    dataFrom: "Entra ID",
    initials: "AC",
  },
  {
    firstName: "Suzanne",
    lastName: "McIntosh",
    activity: "Unprovisioned Device Login",
    activityTime: "11/14/2024 at 2:15 pm",
    riskScore: 85,
    dataFrom: "Crowdstrike",
    initials: "AC",
  },
];
