import React from "react";
import { formatPercentage } from "../../../utils";

const Legend = ({ data }) => {
  const COLORS = ["#00cc66", "#ffb800", "#ff7733", "#e64545"];

  return (
    <div className="flex flex-col gap-0.25">
      {Object.entries(data).map(([name, percentage], index) => (
        <div
          className="flex items-center gap-0.25"
          key={`legend-item-${index}`}
        >
          <div
            className="w-[16px] h-[8px] rounded-[1px]"
            style={{ background: COLORS[index % COLORS.length] }}
          />
          <span className="detailed">{name}</span>
          <span className="detailed">{formatPercentage(percentage)}</span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
