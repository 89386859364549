import { getResponseGet } from "../utils";

const getAnomalousActivityStatuses = async () => {
  let getAnomalousActivityStatusesResponse = {
    result: {
      anomalousActivityStatuses: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/anomalous-activity-statuses/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      getAnomalousActivityStatusesResponse.result.anomalousActivityStatuses =
        data;
    }
  } catch (error) {
    getAnomalousActivityStatusesResponse.error = error;
  }

  return getAnomalousActivityStatusesResponse;
};

export default getAnomalousActivityStatuses;
