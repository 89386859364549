import React from "react";

import {
  ButtonOutlinedGreen,
  CustomLinkGreen,
  List,
} from "../../../../../components";

const StepOne = () => {
  const handleClickDownload = () => {
    const url = "https://d3oo9a669kwmx7.cloudfront.net/onboarding/manifest.xml";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const steps = [
    {
      description: (
        <>
          Go to the{" "}
          <CustomLinkGreen
            href="https://admin.microsoft.com/"
            title="Go to the Microsoft 365 Admin Center."
            target="_blank"
          >
            Microsoft 365 Admin Center
          </CustomLinkGreen>{" "}
          and login using admin credentials.
        </>
      ),
    },
    {
      description: (
        <>
          Select Admin in the left panel and search for “
          <span className="font-medium">integrated apps</span>” in the search
          bar to find the relevant section.
        </>
      ),
    },
    {
      description: (
        <>
          Click on <span className="font-medium">Upload custom apps</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Choose <span className="font-medium">Office add-in</span> as the app
          type from the options provided.
        </>
      ),
    },
    {
      description: (
        <>
          <p>
            Click on <span className="font-medium">Upload Manifest file</span>{" "}
            (.xml) from device.
          </p>
          <div className="mt-0.25">
            <ButtonOutlinedGreen
              title="Download manifest.xml"
              onClick={handleClickDownload}
            >
              Download manifest.xml
            </ButtonOutlinedGreen>
          </div>
        </>
      ),
    },
    {
      description: "Browse and select the Watchtower manifest.xml.",
    },
    {
      description:
        "Assign the app to your entire organization to ensure all users have access to Dune Watchtower.",
    },
  ];

  return (
    <div>
      <List data={steps} keyName="description" textSize="onboarding-step" />
    </div>
  );
};

export default StepOne;
