import { getResponseGet } from "../utils";

const getUsersByMFAProtectionStatus = async (mfaProtectionStatus) => {
  let getUsersByMFAProtectionStatusResponse = {
    result: {
      users: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/mfa-protection-statuses-drilldown/?status=${mfaProtectionStatus.toLowerCase()}`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      const users = data.map(({ risk_score, ...fields }) => ({
        riskScore: risk_score ? Math.round(risk_score) : 0,
        status: mfaProtectionStatus,
        ...fields,
      }));

      getUsersByMFAProtectionStatusResponse.result.users = users;
    }
  } catch (error) {
    getUsersByMFAProtectionStatusResponse.error = error;
  }

  return getUsersByMFAProtectionStatusResponse;
};

export default getUsersByMFAProtectionStatus;
