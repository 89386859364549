import React from "react";

const TwoColumn = ({ firstCard, secondCard }) => {
  return (
    <div className="flex flex-col w-full mt-2 lg:flex-row">
      {firstCard && (
        <div className="flex flex-col w-full lg:w-1/2 lg:pr-1">
          <div className="flex h-full">{firstCard}</div>
        </div>
      )}
      {secondCard && (
        <div className="flex flex-col w-full mt-2 lg:w-1/2 lg:pl-1 lg:mt-0">
          <div className="flex h-full">{secondCard}</div>
        </div>
      )}
    </div>
  );
};

export default TwoColumn;
