import React, { useEffect, useState } from "react";

import { DataTable, DonutChart, Legend, Tabs } from "..";

const defaultHeaders = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "riskScore",
    label: "Risk score",
    isSortable: true,
    isSearchable: false,
    align: "left",
  },
  {
    id: "riskLevel",
    label: "Risk level",
    isSortable: false,
    isSearchable: false,
    align: "left",
  },
];

const DonutChartWithTable = ({
  donutChartData,
  tableData,
  selectedTab,
  setSelectedTab,
  filterField = "",
  title,
  minHeight,
  defaultOrderBy = "name",
  noDataMessage = "No users found",
  headers = defaultHeaders,
}) => {
  const [filteredTableData, setFilteredTableData] = useState([]);

  const handleClickCell = (name) => {
    setSelectedTab({ name });
  };

  useEffect(() => {
    if (selectedTab && selectedTab.name && tableData && filterField) {
      const filteredTableData = tableData.filter(
        (user) => user[filterField] === selectedTab.name,
      );

      setFilteredTableData(filteredTableData);
    }
  }, [selectedTab, tableData, filterField]);

  useEffect(() => {
    if (
      donutChartData &&
      Object.keys(donutChartData).length > 0 &&
      !(selectedTab && selectedTab.name)
    ) {
      setSelectedTab({ name: Object.keys(donutChartData)[0] });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donutChartData]);

  return (
    <div className="flex flex-col gap-0 md:flex-row md:gap-2">
      <div className="flex flex-col w-full md:justify-start md:mt-2 md:max-w-[200px]">
        <div className="md:max-w-[200px]">
          <DonutChart
            data={donutChartData}
            title={title}
            onClickCell={handleClickCell}
            minHeight={minHeight}
          />
        </div>
        <div className="flex justify-center">
          <div className="flex flex-col mt-1">
            <Legend data={donutChartData} />
          </div>
        </div>
      </div>
      <div className="w-full mt-1 md:mt-0">
        <div>
          <Tabs
            data={Object.keys(donutChartData).map((key) => ({
              name: key,
            }))}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
        {tableData && (
          <div className="w-full mt-1">
            <DataTable
              data={filteredTableData}
              headers={headers}
              defaultOrderBy={defaultOrderBy}
              rowsPerPage={5}
              disableFilters
              noDataMessage={noDataMessage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DonutChartWithTable;
