import React from "react";

import { List } from "../../../../../components";

const StepEight = () => {
  const steps = [
    {
      description:
        "Go to the Assignments tab in the Dune Security SSO application.",
    },
    {
      description:
        "Assign the appropriate users and groups that you want to provision to Dune Security.",
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">
          Assign Users and Groups for Provisioning
        </h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepEight;
