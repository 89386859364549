import React from "react";

const List = ({ data = [], keyName = "", textSize = "description" }) => {
  return (
    <ol className="w-full list-none">
      {data.map((dataItem, index) => (
        <li
          className={`flex items-center w-full mt-1 first:mt-0 ${textSize}`}
          key={`list-${index}`}
        >
          <span className="inline-block font-semibold border-b-2 border-green pb-0.125 max-w-1.25 w-full text-center mr-0.5">
            {index + 1}
          </span>
          <div className="inline-block w-full">{dataItem[keyName]}</div>
        </li>
      ))}
    </ol>
  );
};

export default List;
