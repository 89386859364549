import React from "react";

const DropDownFilledIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="5"
      viewBox="0 0 7 5"
      fill="none"
    >
      <path
        d="M0.289022 1.9285L2.81389 4.45337C3.19408 4.83356 3.80824 4.83356 4.18843 4.45337L6.7133 1.9285C7.32746 1.31435 6.88877 0.261505 6.02116 0.261505H0.971418C0.103799 0.261505 -0.325136 1.31435 0.289022 1.9285Z"
        fill="#F6F6F6"
      />
    </svg>
  );
};

export default DropDownFilledIcon;
