import { getResponseGet } from "../utils";

const getProfileSettings = async () => {
  let getProfileSettingsResponse = {
    result: {
      name: "",
      profileEmail: "",
      title: "",
      department: "",
      language: "",
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/edit-account/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      getProfileSettingsResponse.result.name =
        data.first_name || data.last_name
          ? `${data.first_name && data.first_name}${data.first_name && data.last_name ? " " : ""}${data.last_name && data.last_name}`
          : "";
      getProfileSettingsResponse.result.profileEmail = data.email
        ? data.email
        : "";
      getProfileSettingsResponse.result.title = data.title ? data.title : "";
      getProfileSettingsResponse.result.department = data.department
        ? data.department
        : "";
      getProfileSettingsResponse.result.language = data.preferred_language
        ? data.preferred_language
        : "";
    }
  } catch (error) {
    getProfileSettingsResponse.error = error;
  }

  return getProfileSettingsResponse;
};

export default getProfileSettings;
