import React from "react";

import {
  Autocomplete,
  Divider,
  List,
  TextField,
} from "../../../../../components";

const StepTwo = ({
  ssoURL,
  setSSOURL,
  entityIDIDP,
  setEntityIDIDP,
  certificate,
  setCertificate,
  emailDomains,
  setEmailDomains,
}) => {
  const handleChangeCertificate = (event) => {
    setCertificate(event.target.value);
  };

  const handleChangeEntityIDIDP = (event) => {
    setEntityIDIDP(event.target.value);
  };

  const handleChangeSSOURL = (event) => {
    setSSOURL(event.target.value);
  };

  const steps = [
    {
      description: (
        <>
          Copy the content from the Google Admin Console and paste here and then
          click <span className="font-medium">Continue</span>.
        </>
      ),
    },
  ];

  return (
    <div className="-mt-4">
      <div>
        <h4 className="font-medium onboarding-step">Configure SAML Settings</h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
      <div className="my-2">
        <div className="flex flex-col py-1 md:flex-row md:items-center md:gap-1">
          <div className="max-w-[172px] w-full">
            <p className="body">SSO URL*</p>
          </div>
          <TextField
            value={ssoURL}
            placeholder="SSO URL"
            onChangeValue={handleChangeSSOURL}
          />
        </div>
        <Divider />
        <div className="flex flex-col py-1 md:flex-row md:items-center md:gap-1">
          <div className="max-w-[172px] w-full">
            <p className="body">Entity ID*</p>
          </div>
          <TextField
            value={entityIDIDP}
            placeholder="Entity ID"
            onChangeValue={handleChangeEntityIDIDP}
          />
        </div>
        <Divider />
        <div className="flex flex-col py-1 md:flex-row md:items-center md:gap-1">
          <div className="max-w-[172px] w-full">
            <p className="body">Certificate*</p>
          </div>
          <TextField
            value={certificate}
            placeholder="Certificate"
            multiline
            rows={2}
            onChangeValue={handleChangeCertificate}
          />
        </div>
        <Divider />
        <div className="flex flex-col py-1 md:flex-row md:items-center md:gap-1">
          <div className="flex flex-col gap-0.25 max-w-[172px] w-full">
            <p className="body">Email Domain*</p>
            <p className="detailed text-gray-surface-secondary">
              Press Enter after each domain.
            </p>
          </div>
          <Autocomplete
            values={emailDomains}
            setValues={setEmailDomains}
            placeholder="Example: dune.security"
          />
        </div>
        <Divider />
      </div>
    </div>
  );
};

export default StepTwo;
