import { getResponseGet } from "../utils";

const getUserActivityHeatmap = async () => {
  let getUserActivityHeatmapResponse = {
    result: {
      userActivityHeatmap: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/user-activity-heatmap/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      getUserActivityHeatmapResponse.result.userActivityHeatmap = data;
    }
  } catch (error) {
    getUserActivityHeatmapResponse.error = error;
  }

  return getUserActivityHeatmapResponse;
};

export default getUserActivityHeatmap;
