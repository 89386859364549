const protectionStatuses = [
  {
    type: "MFA",
    protectionStatus: "Protected",
  },
  {
    type: "Device Protection",
  },
];

const deviceListChoiceA = [
  {
    device: "Windows 10 Enterprise",
    lastActivityTime: "11/15/2024 at 3:15 p.m.",
    chasisType: "Desktop",
  },

  {
    device: "Windows 10",
    lastActivityTime: "11/15/2024 at 2:48 p.m.",
    chasisType: "Laptop",
    deviceStatus: "Protected",
  },
];

const deviceListChoiceB = [
  {
    device: "Windows 11 Enterprise",
    lastActivityTime: "11/15/2024 at 8:53 p.m.",
    chasisType: "Desktop",
  },

  {
    device: "Windows 11",
    lastActivityTime: "11/15/2024 at 3:15 p.m.",
    chasisType: "Laptop",
    deviceStatus: "Protected",
  },
];

const chosenDeviceList =
  Math.random() < 0.5 ? deviceListChoiceA : deviceListChoiceB;

const isProtected = Math.random() < 0.5;
protectionStatuses[1].protectionStatus = isProtected
  ? "Protected"
  : "1 Unprotected";
chosenDeviceList[0].deviceStatus = isProtected ? "Protected" : "Unprotected";

export const PROTECTION_STATUSES = [...protectionStatuses];
export const DEVICE_LIST = [...chosenDeviceList];
