import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getTrainingCompletion, getUsers } from "../../../api";

import {
  ButtonOutlinedGreen,
  CardWithLoadingState,
  DonutChartWithTable,
} from "../../../components";

import {
  ADMIN_TRAINING_STATUSES,
  getAdminUsersByTrainingStatus,
} from "../../../utils";

const TrainingStatus = ({
  isInitialLoading,
  email,
  setBreadcrumbs,
  setNewTitle,
}) => {
  const [selectedStatus, setSelectedStatus] = useState({ name: "Completed" });
  const [trainingStatuses, setTrainingStatuses] = useState(null);
  const [users, setUsers] = useState(null);

  const navigate = useNavigate();

  const handleClickConfigureTrainings = useCallback(() => {
    navigate("/configure/trainings/");
  }, [navigate]);

  useEffect(() => {
    const loadTrainingStatuses = async () => {
      if (email === "david@dune.demo") {
        setTrainingStatuses(ADMIN_TRAINING_STATUSES);

        // Get all users first and then get demo data for users' training statuses.
        const usersData = await getUsers();

        if (Object.keys(usersData.error).length > 0) {
          console.error(usersData.error.message);
        } else {
          const usersResult = usersData.result;
          setUsers(getAdminUsersByTrainingStatus(usersResult));
        }
      } else {
        const getTrainingStatusesResponse = await getTrainingCompletion();

        if (Object.keys(getTrainingStatusesResponse.error).length > 0) {
          console.error(getTrainingStatusesResponse.error.message);
        } else {
          const { trainingCompletion, users } =
            getTrainingStatusesResponse.result;

          setTrainingStatuses(trainingCompletion);
          setUsers(users);
        }
      }
    };

    if (!isInitialLoading && email) {
      loadTrainingStatuses();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, email]);

  useEffect(() => {
    // Update breadcrumbs.
    const timeout = setTimeout(() => {
      // Update breadcrumbs last.
      setNewTitle(
        <div className="flex items-center justify-between gap-1">
          <h2 className="h2">Training Status</h2>
          <ButtonOutlinedGreen onClick={handleClickConfigureTrainings}>
            Configure Trainings
          </ButtonOutlinedGreen>
        </div>,
      );

      setBreadcrumbs([
        {
          name: "Organization",
          path: "/insights/organization/",
        },
        {
          name: "Training Status",
        },
      ]);
    }, 0);

    return () => clearTimeout(timeout);
  }, [handleClickConfigureTrainings, setBreadcrumbs, setNewTitle]);

  return (
    <div>
      <CardWithLoadingState loadingVariable={trainingStatuses}>
        <DonutChartWithTable
          donutChartData={trainingStatuses}
          tableData={users}
          title="Total Users"
          filterField="trainingStatus"
          defaultOrderBy="name"
          selectedTab={selectedStatus}
          setSelectedTab={setSelectedStatus}
        />
      </CardWithLoadingState>
    </div>
  );
};

export default TrainingStatus;
