import { getResponseGet } from "../utils";

const getTestingCatalogueData = async (pageNumber) => {
  try {
    const response = await getResponseGet(
      `/testing-dashboard/?page=${pageNumber}`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;
    if (!data) {
      throw new Error("No data returned from API");
    }

    return data;
  } catch (error) {
    console.error("Error fetching training data:", error);
    return {
      error: {
        message: error.message || "An error occurred while fetching data",
        details: error,
      },
    };
  }
};

export default getTestingCatalogueData;
