import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  getUsers,
  postDataReportConfig,
  getTrainingCompletion,
  getComplianceFrameworks,
  getUsersByComplianceFramework,
} from "../../../api";

import {
  ButtonOutlinedGreen,
  DataTable,
  Dropdown,
  MultiSelectDropdown,
  Tabs,
} from "../../../components";

import { GreenDownloadIcon } from "../../../utils";

import {
  userRiskInteractionDataHeaders,
  trainingDataHeaders,
  daysOfWeek,
  data,
} from "../../../utils/constants/platform/reporting";

import { capitalizeOnlyFirstWords } from "../../../utils/helper-functions/capitalize";

import { exportToCSV } from "../../../utils/helper-functions/export-to-csv";

import { headers } from "../../../utils/constants/platform/compliance";

const Reporting = ({ isInitialLoading, email }) => {
  const [users, setUsers] = useState([]);

  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [deliveryDay, setDeliveryDay] = useState("");
  const [trainingData, setTrainingData] = useState([]);
  const [currentComplianceFrameworks, setCurrentComplianceFrameworks] =
    useState([]);
  const [selectedComplianceFramework, setSelectedComplianceFramework] =
    useState({
      name: "",
    });
  const [percentageCompliantUsers, setPercentageCompliantUsers] =
    useState(null);

  const [complianceDataCache, setComplianceDataCache] = useState({});

  const { state } = useLocation();

  const [selectedInteraction, setSelectedInteraction] = useState({
    name: "USER RISK DATA",
    value: "users-risk-interaction-data",
  });
  const [resetDropdown, setResetDropdown] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const tabOptions = {
    "users-risk-interaction-data": userRiskInteractionDataHeaders.map(
      (header) => header.label,
    ),
    "training-completion-data": trainingDataHeaders.map(
      (header) => header.label,
    ),
    "compliance-data": headers.map((header) => header.label),
  };

  // Fetch training completion data
  useEffect(() => {
    const fetchTrainingData = async () => {
      try {
        const response = await getTrainingCompletion();
        setTrainingData(response?.result?.users);
      } catch (error) {
        console.error("Error fetching training completion data:", error);
      }
    };

    fetchTrainingData();
  }, []);

  const handleClickInteractionTab = (tab) => {
    setSelectedInteraction(tab);
  };

  useEffect(() => {
    setSelectedColumns([]);
    setSelectedFrequency("");
    setDeliveryDay("");
    setEmails([]);
    setInputValue("");
    setResetDropdown((prev) => !prev);
  }, [selectedInteraction]);

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      if (isValidEmail(inputValue)) {
        if (inputValue.trim()) {
          const emailToAdd = inputValue.trim();
          if (!emails.includes(emailToAdd)) {
            setEmails([...emails, emailToAdd]);
          } else {
            console.warn(`Email "${emailToAdd}" is already in the list.`);
          }
          setInputValue("");
        }
      }
    }
  };

  const handleInputChange = (e) => {
    if (isValidEmail) {
      setInputValue(e.target.value);
    } else {
      console.warn("Invalid email address.");
    }
  };

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleClickSave = async () => {
    if (!selectedFrequency) {
      setSuccessMessage("");
      setErrorMessage("Please select a frequency.");
      return;
    }

    if (deliveryDay === "") {
      setSuccessMessage("");
      setErrorMessage("Please select a delivery day.");
      return;
    }

    if (selectedColumns.length === 0) {
      setSuccessMessage("");
      setErrorMessage("Please select at least one column.");
      return;
    }

    if (emails.length === 0) {
      setSuccessMessage("");
      setErrorMessage("Please enter at least one email address.");
      return;
    }

    const data = {
      frequency: selectedFrequency,
      day_of_week: deliveryDay,
      report_type_fields: selectedColumns,
      recipients: emails,
    };

    try {
      const response = await postDataReportConfig(data);
      setErrorMessage("");
      setSuccessMessage("Report Successfully Sent.");
      console.log("Report Configuration Saved:", response);

      setSelectedColumns([]);
      setSelectedFrequency("");
      setDeliveryDay("");
      setEmails([]);
      setInputValue("");
      setSelectedInteraction({
        name: "USER RISK DATA",
        value: "users-risk-interaction-data",
      });
      setResetDropdown((prev) => !prev);
    } catch (error) {
      setErrorMessage("Error Saving Report Configuration.");
    }
  };

  const handleClickExportReport = (users, fileName) => {
    exportToCSV(
      users,
      fileName,
      ({ compliant, complianceFrameworks, ...fields }) => {
        const row = { ...fields };

        // Process compliance frameworks dynamically
        if (
          complianceFrameworks &&
          typeof complianceFrameworks === "object" &&
          Object.keys(complianceFrameworks).length > 0
        ) {
          Object.entries(complianceFrameworks).forEach(([key, value]) => {
            const frameworkName = complianceFrameworks[key]?.name || key;

            if (
              selectedComplianceFramework.name === "All" ||
              selectedComplianceFramework.name === frameworkName
            ) {
              row[frameworkName] =
                value !== null ? (value ? "yes" : "no") : "N/A";
            }
          });
        }

        return row;
      },
    );
  };

  // separate the export and add a row with name complianceType with the values as the compliance frameworks
  const handleExportComplianceData = (users, fileName) => {
    exportToCSV(
      users,
      fileName,
      ({ compliant, complianceFrameworks, ...fields }) => {
        const row = { ...fields };

        // Process compliance frameworks dynamically
        if (
          complianceFrameworks &&
          typeof complianceFrameworks === "object" &&
          Object.keys(complianceFrameworks).length > 0
        ) {
          Object.entries(complianceFrameworks).forEach(([key, value]) => {
            const frameworkName = complianceFrameworks[key]?.name || key;

            if (
              selectedComplianceFramework.name === "All" ||
              selectedComplianceFramework.name === frameworkName
            ) {
              row[frameworkName] =
                value !== null ? (value ? "yes" : "no") : "N/A";
            }
          });
        }

        row["Compliance Type"] = selectedComplianceFramework.name;

        return row;
      },
    );
  };

  const handleExportTrainingData = (trainingData, fileName) => {
    exportToCSV(
      trainingData,
      fileName,
      ({ user, completionStatus, ...fields }) => ({
        ...fields,
        "User Name": `${user?.firstName || ""} ${user?.lastName || ""}`.trim(),
        "Completion Status": completionStatus ? "Complete" : "Incomplete",
      }),
    );
  };

  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
    const loadUsers = async () => {
      const usersData = await getUsers();

      if (Object.keys(usersData.error).length > 0) {
        console.error(usersData.error.message);
      } else {
        const users = usersData.result;

        // Remove double occurrence of Michael Waite demo account.
        if (email === "david@dune.demo") {
          // Demo account
          const usersDemoEndUser = users.filter(
            (user) => user.email === "michael.waite@dune.demo",
          );
          let updatedUsers = users.filter(
            (user) => user.email !== "michael.waite@dune.demo",
          );

          if (usersDemoEndUser.length > 0) {
            usersDemoEndUser[0].riskScore = 97;
            usersDemoEndUser[0].compliant = false;

            updatedUsers = [...updatedUsers, usersDemoEndUser[0]];
          }

          setUsers(updatedUsers);
        } else {
          setUsers(users);
        }
      }
    };

    const updateComponent = async () => {
      await loadUsers();
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    const loadComplianceFrameworks = async () => {
      const complianceFrameworksData = await getComplianceFrameworks();

      if (Object.keys(complianceFrameworksData.error).length > 0) {
        console.error(complianceFrameworksData.error.message);
      } else {
        const { complianceFrameworks } = complianceFrameworksData.result;

        setCurrentComplianceFrameworks(complianceFrameworks);
      }
    };

    const loadUsers = async () => {
      const usersData = await getUsers();

      if (Object.keys(usersData.error).length > 0) {
        console.error(usersData.error.message);
      } else {
        const users = usersData.result;

        if (currentComplianceFrameworks.length > 0) {
          let compliantUsers = 0;

          for (let i = 0; i < users.length; i++) {
            if (users[i].compliant) {
              compliantUsers++;
            }
          }

          let percentageCompliantUsers = compliantUsers / users.length;

          if (email === "david@dune.demo") {
            // Demo account
            compliantUsers = 9679;
            percentageCompliantUsers = 0.92;
          }

          setPercentageCompliantUsers(percentageCompliantUsers);
          setUsers(users);

          setComplianceDataCache({
            All: {
              compliantUsers,
              percentageCompliantUsers,
              users,
            },
          });
        }
      }
    };

    const updateComponent = async () => {
      if (email === "david@dune.demo") {
        setCurrentComplianceFrameworks([
          {
            name: "SOC 2 Type 2",
          },
          {
            name: "HIPAA",
          },
          {
            name: "ISO27001",
          },
        ]);
      } else {
        await loadComplianceFrameworks();
      }

      if (currentComplianceFrameworks.length > 0) {
        // The current compliance frameworks does have at least 1 framework.
        await loadUsers();

        setSelectedComplianceFramework(currentComplianceFrameworks[0]);
      } else {
        setUsers([]);

        setPercentageCompliantUsers(0);
      }
    };

    if (!isInitialLoading && email) {
      if (state && state.complianceFramework) {
        // Filter by compliance framework if it exists in state.
        setSelectedComplianceFramework(state.complianceFramework);
      }

      updateComponent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, email, state, currentComplianceFrameworks.length]);

  useEffect(() => {
    const loadUsersByComplianceFramework = async () => {
      // Check if data for this compliance framework is already cached.
      if (complianceDataCache[selectedComplianceFramework.name]) {
        setPercentageCompliantUsers(percentageCompliantUsers);
        setUsers(users);

        return; // Return early as we have cached data.
      }

      if (email === "david@dune.demo") {
        let updatedCompliantUsers = 0;
        let updatedPercentageCompliantUsers = 0;

        if (selectedComplianceFramework.name === "SOC 2 Type 2") {
          updatedCompliantUsers = 9644;
          updatedPercentageCompliantUsers = 0.92;
        } else if (selectedComplianceFramework.name === "HIPAA") {
          updatedCompliantUsers = 9854;
          updatedPercentageCompliantUsers = 0.94;
        } else if (selectedComplianceFramework.name === "ISO27001") {
          updatedCompliantUsers = 9540;
          updatedPercentageCompliantUsers = 0.91;
        }

        const shuffledUsers = [...users].sort(() => Math.random() - 0.5);
        const updatedUsers = shuffledUsers.map((user) => ({
          ...user,
          compliant: Math.random() < updatedPercentageCompliantUsers,
        }));

        setPercentageCompliantUsers(updatedPercentageCompliantUsers);
        setUsers(updatedUsers);

        const updatedComplianceDataCache = { ...complianceDataCache };
        updatedComplianceDataCache[selectedComplianceFramework.name] = {
          compliantUsers: updatedCompliantUsers,
          percentageCompliantUsers,
          updatedPercentageCompliantUsers,
          users,
        };
      } else if (selectedComplianceFramework.name !== "All") {
        // If the selected compliance framework is "All", make sure to use the cache once it is loaded from loadUsers().
        const usersData = await getUsersByComplianceFramework(
          selectedComplianceFramework,
        );

        if (Object.keys(usersData.error).length > 0) {
          console.error(usersData.error.message);
        } else {
          const { compliantUsers, percentageCompliantUsers, users } =
            usersData.result;

          setPercentageCompliantUsers(percentageCompliantUsers);
          setUsers(users);

          const updatedComplianceDataCache = { ...complianceDataCache };
          updatedComplianceDataCache[selectedComplianceFramework.name] = {
            compliantUsers,
            percentageCompliantUsers,
            users,
          };

          setComplianceDataCache(updatedComplianceDataCache);
        }
      }
    };

    if (selectedComplianceFramework && selectedComplianceFramework.name) {
      loadUsersByComplianceFramework();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComplianceFramework]);

  return (
    <div className="p-1 text-white border border-gray border-opacity-35 rounded-medium max-w-full overflow-hidden">
      <Tabs
        data={data}
        selectedTab={selectedInteraction}
        setSelectedTab={handleClickInteractionTab}
      />

      <div className="mt-2.625 mb-2 flex justify-between items-center">
        <div className="text-h3">
          {capitalizeOnlyFirstWords(selectedInteraction.name)}
        </div>
        <ButtonOutlinedGreen
          onClick={() => {
            if (selectedInteraction.value === "users-risk-interaction-data") {
              handleClickExportReport(users, "user_risk_interaction_data.csv");
            } else if (
              selectedInteraction.value === "training-completion-data"
            ) {
              handleExportTrainingData(
                trainingData,
                "training_completion_data.csv",
              );
            } else if (selectedInteraction.value === "compliance-data") {
              const fileName =
                selectedComplianceFramework.name === "All"
                  ? "compliance_data_all.csv"
                  : `${selectedComplianceFramework.name}_compliance_data.csv`;

              handleExportComplianceData(users, fileName);
            }
          }}
        >
          <div className="flex items-center justify-center">
            <div className="mr-0.5">
              <GreenDownloadIcon />
            </div>
            <div>Export as CSV</div>
          </div>
        </ButtonOutlinedGreen>
      </div>

      {selectedInteraction.value === "users-risk-interaction-data" && (
        <DataTable
          data={users}
          headers={userRiskInteractionDataHeaders}
          disableFilters
          disableSearchBar
          rowsPerPage={4}
          defaultOrderBy="name"
          fixedTotal={email === "david@dune.demo" ? 10483 : null}
          onExportSelectedReports={handleClickExportReport}
        />
      )}

      {selectedInteraction.value === "training-completion-data" && (
        <DataTable
          data={trainingData}
          headers={trainingDataHeaders}
          rowsPerPage={4}
          disableFilters
          disableSearchBar
          defaultOrderBy="name"
          searchPlaceholder="Search for a user"
          isForUserTraining={true}
          fixedTotal={email === "david@dune.demo" ? 10483 : null}
        />
      )}

      {selectedInteraction.value === "compliance-data" && (
        <div>
          <div>
            <Tabs
              data={currentComplianceFrameworks.filter(
                (framework) => framework.name !== "All",
              )}
              selectedTab={selectedComplianceFramework}
              setSelectedTab={setSelectedComplianceFramework}
            />
          </div>

          <div className="mt-2">
            <DataTable
              disableFilters
              disableSearchBar
              data={users}
              rowsPerPage={4}
              headers={headers}
              fixedTotal={email === "david@dune.demo" ? 10483 : null}
            />
          </div>
        </div>
      )}

      <div className="mt-2 mb-3 text-h4">Setup Email Report Delivery</div>

      <div className="mb-2">
        <div className="flex-1 min-w-[300px]">
          <Dropdown
            uniqueKey={`frequency-${selectedInteraction.value}`}
            reset={resetDropdown}
            label="Select Frequency*"
            description="Choose how often you'd like to receive this report."
            placeholder="Select Frequency"
            options={["Weekly", "Biweekly", "Monthly"]}
            onSelect={setSelectedFrequency}
          />
        </div>
        <div className="text-gray text-opacity-35">
          <hr />
        </div>
      </div>

      <div className="mb-2">
        <div className="flex-1 min-w-[300px]">
          <Dropdown
            key={`delivery-day-${selectedInteraction.value}`}
            reset={resetDropdown}
            label="Select Delivery Day*"
            description="Select the day of the week for report delivery. Reports will be sent at 9 AM on the chosen day."
            placeholder="Select Delivery Day"
            options={daysOfWeek.map((day) => day.day)}
            onSelect={(selectedDay) => {
              const selectedDayObj = daysOfWeek.find(
                (day) => day.day === selectedDay,
              );
              if (selectedDayObj) {
                setDeliveryDay(selectedDayObj.idx);
              }
            }}
          />
        </div>
        <div className="text-gray text-opacity-35">
          <hr />
        </div>
      </div>

      <div className="mb-2">
        <div className="flex-1 min-w-[300px]">
          <MultiSelectDropdown
            uniqueKey={`columns-${selectedInteraction.value}`}
            reset={resetDropdown}
            label="Select Report Columns*"
            description="Customize the report by selecting the columns you'd like to include."
            placeholder="Select Columns"
            options={tabOptions[selectedInteraction.value] || []}
            onSelect={setSelectedColumns}
          />
        </div>
        <div className="text-gray text-opacity-35">
          <hr />
        </div>
      </div>

      <div className="mb-2 overflow-x-hidden">
        <div className="flex-1 min-w-[300px]">
          <div className="flex-row items-center mb-2 space-y-1 lg:flex">
            <div className="w-1/2">
              <div className="text-h5">Email To*</div>
              <div className="w-16 text-description text-gray-surface-secondary">
                Enter the email addresses of authorized recipients. <br />{" "}
                Separate multiple emails with a comma.
              </div>
            </div>

            <div
              className={`max-w-35 w-full mr-3.625 text-opacity-35 border rounded-medium p-1 ${
                isFocused ? "border-green" : "border-gray border-opacity-35"
              }`}
            >
              <div className="flex flex-wrap gap-0.25">
                {emails.map((email, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-gray-800 text-white rounded-md border border-white space-x-0.625 px-0.5"
                  >
                    <span>{email}</span>
                    <button onClick={() => removeEmail(email)}>✕</button>
                  </div>
                ))}
              </div>
              <textarea
                placeholder="Comma separated email list"
                className="box-border text-white bg-transparent border-none resize-none max-w-35 focus:outline-none focus:border-green text-opacity-65 placeholder:text-gray placeholder:text-opacity-65"
                rows={5}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            </div>
          </div>
        </div>
        <div className="text-gray text-opacity-35">
          <hr />
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div>
          <div className="text-green">
            {successMessage.trim().length !== 0 && successMessage}
          </div>
          <div className="text-red">
            {errorMessage.trim().length !== 0 && errorMessage}
          </div>
        </div>
        <ButtonOutlinedGreen onClick={handleClickSave}>
          Save
        </ButtonOutlinedGreen>
      </div>
    </div>
  );
};

export default Reporting;
