import React from "react";

const TrainingVideoActive = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle
        cx="8"
        cy="8"
        r="7.33333"
        stroke="#47FFA5"
        stroke-width="1.33333"
      />
    </svg>
  );
};

export default TrainingVideoActive;
