import React from "react";

import { List } from "../../../../../components";

const StepFour = () => {
  const stepsLogin = [
    {
      description: (
        <>
          Login to <span className="font-medium">Google Cloud Console</span>{" "}
          with admin credentials.
        </>
      ),
    },
  ];

  const stepsAPI = [
    {
      description: (
        <>
          Navigate to <span className="font-medium">APIs & Services</span> {">"}{" "}
          <span className="font-medium">Library</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Search for "<span className="font-medium">Admin SDK</span>" and enable
          it.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <List
          data={stepsLogin}
          keyName="description"
          textSize="onboarding-step"
        />
      </div>
      <div className="mt-2">
        <h4 className="font-medium onboarding-step">Enable Admin SDK API</h4>
      </div>
      <div className="mt-1">
        <List
          data={stepsAPI}
          keyName="description"
          textSize="onboarding-step"
        />
      </div>
    </div>
  );
};

export default StepFour;
