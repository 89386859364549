import React, { useState, useEffect } from "react";
import { DropDownArrowIcon } from "../../../utils/icons";
import { ButtonOutlinedGreen, Tooltip } from "../../../components";
import { Link } from "react-router-dom";
import { getPostUserGuardRails, getBraTestingSchedule } from "../../../api";

const Testing = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [minimumTests, setMinimumTests] = useState();
  const [maximumTests, setMaximumTests] = useState();
  const [frequencyData, setFrequencyData] = useState({});
  const [initialTestDuration, setInitialTestDuration] = useState();
  const [socialEngineeringTests, setSocialEngineeringTests] = useState();

  useEffect(() => {
    const fetchBraData = async () => {
      const braTestingResponse = await getBraTestingSchedule();

      if (braTestingResponse.error.length > 0) {
        console.log("Error fetching data:", braTestingResponse.error);
      } else {
        setInitialTestDuration(braTestingResponse.result.num_weeks);
        setSocialEngineeringTests(braTestingResponse.result.tests_per_week);
      }
    };

    fetchBraData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPostUserGuardRails();
        const data = response.Result;

        if (data) {
          setMinimumTests(data.test_frequency_groups.frequency_data.all[0]);
          setMaximumTests(data.test_frequency_groups.frequency_data.all[1]);
          setFrequencyData(data.test_frequency_groups.frequency_data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="flex-col items-start justify-center mt-2 text-white md:flex md:flex-row md:space-x-2">
      <div className="w-full md:w-1/2">
        <div className="flex justify-between items-center mb-0.5">
          <div className="flex items-center justify-center">
            <div className="text-h4 mr-0.25">Initial Testing Schedule</div>
            <Tooltip text="Schedule initial testing period and frequency to newly onboarded users." />
          </div>
          <Link to="/configure/testing/schedule/">
            <ButtonOutlinedGreen>Modify</ButtonOutlinedGreen>
          </Link>
        </div>
        <div className="border bg-gray-background border-gray border-opacity-35 min-h-2 rounded-medium">
          <div className="flex justify-between items-center pl-1 pr-2 pt-2.625 pb-3">
            <div>
              <div className="text-h5">Social Engineering Tests per Week</div>
              <div className="text-h6 w-14">
                Number of emails sent to new users each week
              </div>
            </div>
            <div className="text-h2">{socialEngineeringTests}</div>
          </div>
          <div className="flex justify-between items-center pl-1 pr-2 pt-2.625 pb-4">
            <div>
              <div className="text-h5">Initial Test Duration</div>
              <div className="text-h6 w-14">
                Number of weeks the initial assessment lasts
              </div>
            </div>
            <div className="text-h2">{initialTestDuration}</div>
          </div>
          <div className="text-h6 pl-1 pr-2 mt-2.5 mb-0.5">
            Initial tests help us determine the base risk scores for your users.
          </div>
          {!socialEngineeringTests && !initialTestDuration && (
            <div className="flex items-center justify-between p-1">
              <div>No schedule has been setup yet.</div>
              <Link to="/configure/testing/schedule/">
                <ButtonOutlinedGreen>
                  {" "}
                  Set up initial testing
                </ButtonOutlinedGreen>
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="w-full mt-2 md:w-1/2 md:mt-0">
        <div className="flex justify-between items-center mb-0.5">
          <div className="flex items-center justify-center">
            <div className="text-h4 v mr-0.25">Ongoing Testing Schedule</div>
            <Tooltip text="Schedule recurring testing period and frequency for existing users." />
          </div>
          <Link to="/configure/testing/schedule/">
            <ButtonOutlinedGreen>Modify/Add</ButtonOutlinedGreen>
          </Link>
        </div>
        <div className="border bg-gray-background border-gray border-opacity-35 min-h-2 rounded-medium">
          <div className="pt-1 pl-1 pr-2 font-medium text-h4">
            Organization rules
          </div>
          <div className="flex justify-between items-center pl-1 pr-2 pt-2.625 pb-2">
            <div>
              <div className="text-h5">Minimum tests per quarter</div>
            </div>
            <div className="text-h2">{minimumTests ? minimumTests : 0}</div>
          </div>
          <div className="flex justify-between items-center pl-1 pr-2 pt-2.625 pb-3.625">
            <div>
              <div className="text-h5">Maximum tests per quarter</div>
            </div>
            <div className="text-h2">{maximumTests ? maximumTests : 0}</div>
          </div>
          <div className="pb-1 mx-1">
            <div
              className="flex items-center justify-between px-2 py-1 cursor-pointer border-y border-gray border-opacity-35"
              onClick={toggleAccordion}
            >
              <div>Custom rules for User Groups</div>
              <div
                tabIndex="0"
                role="button"
                className={`transform transition ease-in-out duration-500 ${isAccordionOpen ? "rotate-180" : ""}`}
              >
                <DropDownArrowIcon />
              </div>
            </div>
            {isAccordionOpen && (
              <div>
                {Object.keys(frequencyData).length === 0 ? (
                  <div className="text-h5 text-white mt-2.5 flex justify-between items-center">
                    <div className="w-12">
                      No rules have been set for user groups yet.
                    </div>
                    <Link to="/configure/testing/schedule/">
                      <ButtonOutlinedGreen>
                        Create custom rules
                      </ButtonOutlinedGreen>
                    </Link>
                  </div>
                ) : (
                  Object.entries(frequencyData).map(
                    ([group, [min, max]]) =>
                      group !== "all" && (
                        <div key={group}>
                          <div className="flex items-center justify-between pl-1 pr-2 my-2">
                            <div>
                              <div className="capitalize text-h5">
                                {group.replace("_", " ")} Risk Users
                              </div>
                              <div className="text-h6">
                                Minimum tests per Quarter
                              </div>
                            </div>
                            <div className="text-h2">{min}</div>
                          </div>
                          <div className="flex items-center justify-between pl-1 pr-2 my-2">
                            <div>
                              <div className="capitalize text-h5">
                                {group.replace("_", " ")} Risk Users
                              </div>
                              <div className="text-h6">
                                Maximum tests per Quarter
                              </div>
                            </div>
                            <div className="text-h2">{max}</div>
                          </div>
                          <div className="p-1 text-gray text-opacity-35">
                            <hr />
                          </div>
                        </div>
                      ),
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testing;
