import React from "react";

import {
  CardHeader,
  CardWithLoadingState,
} from "../../../../../common";

function CardConfigureTesting({ initialTestWeeks, initialTestsPerWeek }) {
  const configurations = [
    {
      name: "Initial Test Duration",
      description: "Number of weeks the initial assessment lasts",
      value: initialTestWeeks,
    },
    {
      name: "Social engineering tests per week",
      description: "Number of emails sent to new users each week",
      value: initialTestsPerWeek,
    },
  ];

  return (
    <CardWithLoadingState
      loadingVariable={initialTestsPerWeek}
      minHeight="174.17px"
    >
      <CardHeader
        header="Initial Test Schedule"
        tooltip="Schedule initial testing period and frequency to newly onboarded users."
      />
      <div className="flex flex-col gap-0.375 -mt-0.5">
        {configurations.map((configuration, index) => (
          <div
            className="flex justify-between w-full gap-1"
            key={`configuration-${index}`}
          >
            <div className="flex flex-col gap-0.25">
              <h4 className="description-emphasized">{configuration.name}</h4>
              <p className="detailed">{configuration.description}</p>
            </div>
            <div className="flex justify-center max-w-[80px] w-full">
              <p className="h3">{configuration.value}</p>
            </div>
          </div>
        ))}
      </div>
    </CardWithLoadingState>
  );
}

export default CardConfigureTesting;
