import { getResponseGet } from "../utils";

const getDMIEmailProvider = async () => {
  let getDMIEmailProviderResponse = {
    result: {
      dmiEmailProvider: "",
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/dmi-email-provider/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && data.dmi_email_provider) {
      getDMIEmailProviderResponse.result.dmiEmailProvider =
        data.dmi_email_provider;
    }
  } catch (error) {
    getDMIEmailProviderResponse.error = error;
  }

  return getDMIEmailProviderResponse;
};

export default getDMIEmailProvider;
