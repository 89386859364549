import React from "react";

import { CardHeader, CardWithLoadingState } from "../../../../../common";

function CardQuickInsights({ weekNumber, totalWeeks, totalSimulationsSent }) {
  return (
    <CardWithLoadingState
      loadingVariable={totalSimulationsSent}
      minHeight="174.17px"
    >
      <CardHeader header="Quick Insights" tooltip="" />
      <div className="flex justify-between gap-2">
        <div className="max-w-[120px] w-full">
          <h4 className="h3">Week {weekNumber ? weekNumber : 1}</h4>
          <p className="description mt-0.25">
            of {totalWeeks} of Initial testing
          </p>
        </div>
        <div className="max-w-[120px] w-full">
          <p className="h3">{totalSimulationsSent}</p>
          <p className="description mt-0.25">Total phishing simulations sent</p>
        </div>
      </div>
    </CardWithLoadingState>
  );
}

export default CardQuickInsights;
