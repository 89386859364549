import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { StepFive, StepFour, StepOne, StepThree, StepTwo } from "./utils";

import {
  postDMIEmailProvider,
  postIntegrationDetails,
  postOnboardingStatus,
} from "../../../api";

import {
  ButtonContainedGreen,
  ButtonOutlinedGreen,
  StepTemplate,
} from "../../../components";

const SetupDMIWithExchange = () => {
  const navigate = useNavigate();

  const totalSteps = 5;

  // Details
  const [secretValue, setSecretValue] = useState("");
  const [clientID, setClientID] = useState("");
  const [tenantID, setTenantID] = useState("");

  // Steps
  const [stepNumber, setStepNumber] = useState(1); // Default value is 1.
  const [stepPercentage, setStepPercentage] = useState(1 / totalSteps);

  // Message
  const [errorMessage, setErrorMessage] = useState("");

  const sendDMIEmailProvider = async (emailProvider) => {
    const requestBody = {
      emailProvider,
    };

    const postDMIEmailProviderResponse =
      await postDMIEmailProvider(requestBody);

    if (Object.keys(postDMIEmailProviderResponse.error).length > 0) {
      console.error(postDMIEmailProviderResponse.error.message);
      setErrorMessage(postDMIEmailProviderResponse.error.message);
    }
  };

  const sendIntegrationDetails = async (id) => {
    const data = {};

    data.applicationID = clientID;
    data.tenantID = tenantID;
    data.clientSecret = secretValue;

    // Convert the JSON data to a Blob.
    const jsonBlob = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });

    const postIntegrationDetailsResponse = await postIntegrationDetails(
      jsonBlob,
      "microsoft-entra-id",
    );

    if (Object.keys(postIntegrationDetailsResponse.error).length > 0) {
      console.error(postIntegrationDetailsResponse.error.message);

      return true;
    }

    return false;
  };

  const sendOnboardingStatus = async (stepName) => {
    const requestBody = {
      stepName,
    };

    const postOnboardingStatusResponse =
      await postOnboardingStatus(requestBody);

    if (Object.keys(postOnboardingStatusResponse.error).length > 0) {
      console.error(postOnboardingStatusResponse.error.message);
      setErrorMessage(postOnboardingStatusResponse.error.message);
    }
  };

  const handleClickBack = () => {
    setErrorMessage("");

    if (stepNumber === 1) {
      navigate("/onboarding/setup-dmi/");
    } else {
      const newStep = Math.max(1, stepNumber - 1);

      setStepNumber(newStep);
      setStepPercentage(newStep / totalSteps);
    }
  };

  const handleClickNext = async () => {
    setErrorMessage("");

    // Data validation
    if (stepNumber === 4) {
      if (!secretValue) {
        setErrorMessage("Enter the required details.");
        return;
      }
    } else if (stepNumber === 5) {
      if (!clientID || !tenantID) {
        setErrorMessage("Enter the required details.");
        return;
      }

      const isErrorSendIntegrationDetails = await sendIntegrationDetails();
      if (isErrorSendIntegrationDetails) {
        setErrorMessage("An error has occurred. Please try again.");
        return;
      }

      // Update DMI email provider.
      await sendDMIEmailProvider("microsoft");

      // Update onboarding status.
      await sendOnboardingStatus("is_dmi_completed");

      navigate("/onboarding/"); // Go back to the welcome screen after completion.
    }

    const newStep = Math.min(totalSteps, stepNumber + 1);

    setStepNumber(newStep);
    setStepPercentage(newStep / totalSteps);
  };

  return (
    <StepTemplate
      title="Setup DMI with Exchange"
      mainContent={
        <div className="flex flex-col justify-between h-full">
          <div className="px-2">
            <div className="flex justify-between">
              <h3 className="h2">Steps</h3>
              <p className="h4 text-gray-surface-secondary">
                {stepNumber}/{totalSteps}
              </p>
            </div>
            <div className="mt-2 pt-4.75 pb-2">
              {stepNumber === 1 && <StepOne />}
              {stepNumber === 2 && <StepTwo />}
              {stepNumber === 3 && <StepThree />}
              {stepNumber === 4 && (
                <StepFour
                  secretValue={secretValue}
                  setSecretValue={setSecretValue}
                />
              )}
              {stepNumber === 5 && (
                <StepFive
                  clientID={clientID}
                  setClientID={setClientID}
                  tenantID={tenantID}
                  setTenantID={setTenantID}
                />
              )}
            </div>
          </div>
          <div>
            <div
              className={`border-t-2 border-green`}
              style={{ width: `calc(${stepPercentage} * 100%)` }}
            ></div>
            <div
              className="flex items-center gap-2 pt-1.5 px-2"
              style={{ borderTop: "1px solid rgba(0, 248, 127, 0.30)" }}
            >
              <ButtonOutlinedGreen onClick={handleClickBack}>
                Back
              </ButtonOutlinedGreen>
              <ButtonContainedGreen onClick={handleClickNext}>
                {stepNumber === 4 || stepNumber === 5
                  ? "Save & Continue"
                  : "Next"}
              </ButtonContainedGreen>
              {errorMessage && (
                <div>
                  <p className="description text-red">{errorMessage}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      screenshotVideo={
        stepNumber === 1
          ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/dmi-exchange-step-1.mov"
          : stepNumber === 2
            ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/dmi-exchange-step-2.mov"
            : stepNumber === 3
              ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/dmi-exchange-step-3.mov"
              : stepNumber === 4
                ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/dmi-exchange-step-4.mov"
                : stepNumber === 5
                  ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/dmi-exchange-step-5.mov"
                  : ""
      }
      screenshotImage=""
    />
  );
};

export default SetupDMIWithExchange;
