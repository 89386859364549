import { getResponseGet } from "../utils";

const getAverageHourlyUserLogin = async () => {
  let getAverageHourlyUserLoginResponse = {
    result: {
      averageHourlyUserLogin: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/average-hourly-user-login/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      getAverageHourlyUserLoginResponse.result.averageHourlyUserLogin = data;
    }
  } catch (error) {
    getAverageHourlyUserLoginResponse.error = error;
  }

  return getAverageHourlyUserLoginResponse;
};

export default getAverageHourlyUserLogin;
