import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { getUser } from "../../../api";
import { ButtonContainedGreen, GreenItalicText } from "../../../components";
import { CertificateBadge, CulliganLogo } from "../../../utils";

// Importing images using web link do not work. In future, if we add more, we will have to add them locally
import certificateBackground from "./img-assets/CertificateBackground.png";
import duneLogo from "./img-assets/dune-logo.png";

const CertificateGenerator = forwardRef(
  ({ firstName, lastName, trainingName, date }, ref) => {
    const certificateRef = useRef(null);

    const [mode, setMode] = useState("preview");
    const [userEmail, setUserEmail] = useState("");

    const handleDownloadPDF = async () => {
      setMode("print");
      setTimeout(async () => {
        const canvas = await html2canvas(certificateRef.current, {
          useCORS: true,
          scale: 2,
        });

        const image = canvas.toDataURL("image/png");

        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [1920, 1080],
        });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(image, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("certificate.pdf");
        setMode("preview");
      }, 0);
    };

    useImperativeHandle(ref, () => ({
      handleDownloadPDF: () => {
        setMode("print");
        setTimeout(async () => {
          const canvas = await html2canvas(certificateRef.current, {
            useCORS: true,
            scale: 2,
          });

          const image = canvas.toDataURL("image/png");

          const pdf = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [1920, 1080],
          });

          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();

          pdf.addImage(image, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save("certificate.pdf");
          setMode("preview");
        }, 0);
      },
    }));

    useEffect(() => {
      const loadUser = async () => {
        const user = await getUser();
        setUserEmail(user.result.email);
      };

      loadUser();
    }, [userEmail]);

    return (
      <div className="text-white">
        {/* Certificate Container */}
        <div
          className="relative flex justify-center items-center overflow-hidden"
          style={{
            width: "100%",
            height: "auto",
            minHeight: "400px",
          }}
        >
          {/* Scaling Wrapper */}
          <div
            ref={certificateRef}
            className="relative bg-black"
            style={{
              width: "950px",
              height: "600px",
              transform: "scale(1)",
              transformOrigin: "center",
              backgroundImage: `url(${certificateBackground})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* Logo and Decorations */}
            <div className="absolute right-2 top-1">
              <div className="flex items-center space-x-1">
                <img
                  src={duneLogo}
                  alt="Dune Logo"
                  className={`h-2.5 w-12.125 -translate-y-0.75 ${
                    userEmail.includes("culligan") ? "mr-3" : "-mr-3"
                  }`}
                />
                <div
                  className={`absolute right-11 text-h3 font-medium -translate-y-0.5 ${
                    mode === "preview" ? "top-1.125" : "top-0.5"
                  }`}
                >
                  {userEmail.includes("culligan") ? "x" : ""}
                </div>
                <div className="h-5 flex items-center -translate-y-0.25">
                  {userEmail.includes("culligan") ? (
                    <CulliganLogo />
                  ) : (
                    <div className="h-5 w-5 bg-gray-500 rounded-full"></div>
                  )}
                </div>
              </div>
            </div>

            {/* Certificate Text */}
            <div className="absolute italic tracking-wider transform -translate-x-1/2 -translate-y-1/2 italic-font top-9 left-1/2 text-h1">
              <GreenItalicText text="Congratulations" />
            </div>

            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-14 left-1/2 text-h3 text-center">
              This certificate of completion certifies that
            </div>

            <div className="absolute italic italic-font top-21.5 left-1/2 transform -translate-x-1/2 -translate-y-5 text-h2">
              {firstName} {lastName}
            </div>

            <div
              className="absolute top-26 left-1/2 transform -translate-x-1/2 -translate-y-4 text-h3 text-center mt-0.75"
              style={{ width: "80%" }}
            >
              <div style={{ wordWrap: "break-word" }}>
                has successfully completed the {trainingName} training <br />{" "}
                with Dune Security on {date}
              </div>
            </div>

            {/* Certificate Badge */}
            <div className="absolute transform -translate-x-1/2 -translate-y-10 top-38 left-1/2 text-h3">
              <CertificateBadge />
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-1 mr-1">
          <ButtonContainedGreen onClick={handleDownloadPDF}>
            <div className="text-center">
              <span>Download Certificate</span>
            </div>
          </ButtonContainedGreen>
        </div>
      </div>
    );
  },
);

export default CertificateGenerator;
