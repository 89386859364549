import React from "react";

import { CopyField, List } from "../../../../../components";

const StepThree = () => {
  const stepsAdd = [
    {
      description: (
        <>
          In the newly created app, click on{" "}
          <span className="font-medium">API Permissions</span> under the Manage
          section.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Add Permissions</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Select <span className="font-medium">Microsoft Graph</span>.
        </>
      ),
    },
    {
      description: (
        <>
          <p>Under the Application Permissions tab, add</p>
          <div className="mt-0.25 max-w-[240px] w-full">
            <CopyField text="User.Read.All" />
          </div>
          <div className="mt-0.25 max-w-[240px] w-full">
            <CopyField text="Mail.ReadWrite" />
          </div>
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Add Permissions</span> to confirm.
        </>
      ),
    },
  ];

  const stepsGrant = [
    {
      description: (
        <>
          After adding the permissions, click{" "}
          <span className="font-medium">Grant Admin Consent</span> to apply the
          permissions to the app.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">
          Add required API Permissions
        </h4>
      </div>
      <div className="mt-1">
        <List
          data={stepsAdd}
          keyName="description"
          textSize="onboarding-step"
        />
      </div>
      <div className="mt-2">
        <h4 className="font-medium onboarding-step">Grant Admin Consent</h4>
      </div>
      <div className="mt-1">
        <List
          data={stepsGrant}
          keyName="description"
          textSize="onboarding-step"
        />
      </div>
    </div>
  );
};

export default StepThree;
