import React from "react";

import { FileUpload, List } from "../../../../../components";

const StepOne = ({ selectedFiles, setSelectedFiles }) => {
  const steps = [
    {
      description: (
        <>
          Over the next <span className="font-medium">3 weeks</span>, each user
          in your organization will receive{" "}
          <span className="font-medium">5 simulated phishing emails</span>.
        </>
      ),
    },
    {
      description:
        "Emails mimic real-world attacks to test your team's awareness and response. ",
    },
    {
      description:
        "These tests help us determine the base risk score for your users.",
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">What to expect?</h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
      <div className="mt-2">
        <div>
          <h4 className="font-medium onboarding-step">
            Upload your Historical Phishing Tests
          </h4>
        </div>
        <div className="mt-0.25">
          <p className="onboarding-step text-gray-surface-contrast">
            If your organization has conducted phishing tests in the past, you
            can upload some of them here. We use this to personalize test to
            your company.
          </p>
        </div>
        <div className="mt-1">
          <FileUpload
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            multiple
            fileType="HTML, PNG, JPEG"
            accept={{
              "text/html": [".html", ".htm"],
              "image/png": [".png"],
              "image/jpeg": [".jpg", ".jpeg"],
              "image/svg+xml": [".svg"],
            }}
            isSmall
          />
        </div>
      </div>
    </div>
  );
};

export default StepOne;
