import { getResponseGet } from "../utils";

const formatUsersByStatus = (users, status, riskScoreMap) => {
  const formattedUsers = [];

  for (let i = 0; i < users.length; i++) {
    const email = users[i];

    formattedUsers.push({
      email,
      status,
      riskScore: riskScoreMap[email] ? Math.round(riskScoreMap[email]) : 0,
    });
  }

  return formattedUsers;
};

const getMFAProtectionStatuses = async () => {
  let getMFAProtectionStatusesResponse = {
    result: {
      mfaProtectionStatuses: [],
      users: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/mfa-protection-statuses/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      const protectedStatus = data.protected ? data.protected : 0;
      const unprotectedStatus = data.unprotected ? data.unprotected : 0;
      const total = data.total ? data.total : 1;

      const mfaProtectionStatuses = {
        Protected: protectedStatus / total,
        Unprotected: unprotectedStatus / total,
      };

      const protectedUsers = data.protectedEmails ? data.protectedEmails : [];
      const unprotectedUsers = data.unprotectedEmails
        ? data.unprotectedEmails
        : [];
      const riskScoreMap = data.riskScoreMap ? data.riskScoreMap : [];

      const users = [
        ...formatUsersByStatus(protectedUsers, "Protected", riskScoreMap),
        ...formatUsersByStatus(unprotectedUsers, "Unprotected", riskScoreMap),
      ];

      getMFAProtectionStatusesResponse.result.mfaProtectionStatuses =
        mfaProtectionStatuses;
      getMFAProtectionStatusesResponse.result.users = users;
    }
  } catch (error) {
    getMFAProtectionStatusesResponse.error = error;
  }

  return getMFAProtectionStatusesResponse;
};

export default getMFAProtectionStatuses;
