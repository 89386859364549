import { getResponseGet } from "../utils";

const getOnboardingStatus = async () => {
  let getOnboardingStatusResponse = {
    result: {
      isSSOCompleted: false,
      isSCIMCompleted: false,
      isDMICompleted: false,
      isWatchtowerCompleted: false,
      isInitialTestingCompleted: false,
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/onboarding-status/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && data.onboarding_status) {
      getOnboardingStatusResponse.result.isSSOCompleted =
        data.onboarding_status.is_sso_completed &&
        data.onboarding_status.is_sso_completed;
      getOnboardingStatusResponse.result.isSCIMCompleted =
        data.onboarding_status.is_scim_completed &&
        data.onboarding_status.is_scim_completed;
      getOnboardingStatusResponse.result.isDMICompleted =
        data.onboarding_status.is_dmi_completed &&
        data.onboarding_status.is_dmi_completed;
      getOnboardingStatusResponse.result.isWatchtowerCompleted =
        data.onboarding_status.is_watchtower_completed &&
        data.onboarding_status.is_watchtower_completed;
      getOnboardingStatusResponse.result.isInitialTestingCompleted =
        data.onboarding_status.is_initial_testing_completed &&
        data.onboarding_status.is_initial_testing_completed;
    }
  } catch (error) {
    getOnboardingStatusResponse.error = error;
  }

  return getOnboardingStatusResponse;
};

export default getOnboardingStatus;
