import React from "react";

import {
  // ButtonOutlinedGray,
  // ButtonOutlinedGreen,
  CardHeader,
  CardWithLoadingState,
  DataTable,
} from "../../../..";

// import {
//   CrowdstrikeBrandMarkIcon,
//   FunnelIcon,
//   MicrosoftEntraIDBrandMarkIcon,
//   MicrosoftPurviewBrandMarkIcon,
// } from "../../../../../../utils";

const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "activity",
    label: "Activity",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "activityTime",
    label: "Activity Time",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "riskScore",
    label: "User Risk Score",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
  {
    id: "dataFrom",
    label: "Data From",
    isSortable: true,
    isSearchable: true,
    align: "left",
    width: "252px",
  },
];

const CardAnomalousActivityTracker = ({ anomalousActivityStatuses }) => {
  return (
    <CardWithLoadingState loadingVariable={anomalousActivityStatuses}>
      <CardHeader header="Anomalous Activity Ticker" />
      <div>
        <p className="max-w-40 description text-gray-surface-contrast">
          Monitor suspicious activities, such as logins from unauthorized
          locations or devices, using real-time anomaly detection. Stay ahead of
          potential threats.
        </p>
      </div>
      {/* <div className="mt-1.5 p-1 flex items-center space-x-0.5">
        <div>
          <FunnelIcon />
        </div>
        <ButtonOutlinedGreen isRounded isSmall>
          <div className="flex items-center justify-center gap-0.5 px-0.25">
            <div className="w-1.5">
              <MicrosoftEntraIDBrandMarkIcon />
            </div>
            <span className="text-white body">Microsoft Entra ID</span>
          </div>
        </ButtonOutlinedGreen>
        <ButtonOutlinedGreen isRounded isSmall>
          <div className="flex items-center justify-center gap-0.5 px-0.25">
            <div className="w-1.5">
              <CrowdstrikeBrandMarkIcon />
            </div>
            <span className="text-white body">Crowdstrike</span>
          </div>
        </ButtonOutlinedGreen>
        <ButtonOutlinedGray isRounded isSmall>
          <div className="flex items-center justify-center gap-0.5 px-0.25">
            <div className="w-1.5">
              <MicrosoftPurviewBrandMarkIcon />
            </div>
            <span className="text-white body">Microsoft Purview</span>
          </div>
        </ButtonOutlinedGray>
      </div> */}
      <div className="mt-1.25">
        <DataTable
          data={anomalousActivityStatuses}
          headers={headers}
          defaultOrderBy="activityTime"
          rowsPerPage={5}
          disableSearchBar
        />
      </div>
      {/* <div className="flex justify-end items-start lg:items-end xl:items-start px-0.625 py-0.875 w-full">
        <div className="flex flex-row">
          <ButtonOutlinedGreen isSmall>View all</ButtonOutlinedGreen>
        </div>
      </div> */}
    </CardWithLoadingState>
  );
};

export default CardAnomalousActivityTracker;
