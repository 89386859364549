import { getResponseGet } from "../utils";

const getQuickInsights = async () => {
  let getQuickInsightsResponse = {
    result: {
      weekNumber: 0,
      totalSimulationsSent: 0,
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/quick-insights/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      getQuickInsightsResponse.result.weekNumber = data.week_number
        ? data.week_number
        : 0;
      getQuickInsightsResponse.result.totalSimulationsSent =
        data.total_simulations_sent ? data.total_simulations_sent : 0;
    }
  } catch (error) {
    getQuickInsightsResponse.error = error;
  }

  return getQuickInsightsResponse;
};

export default getQuickInsights;
