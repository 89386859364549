import { getResponsePost } from "../utils";

export const postRequestNewCustomTraining = async (requestBody) => {
  let postRequestNewCustomTrainingResponse = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePost(
      "/request-custom-training/",
      requestBody,
      "multipart/form-data",
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && data.result) {
      postRequestNewCustomTrainingResponse.result = data.result;
    }
  } catch (error) {
    postRequestNewCustomTrainingResponse.error = error;
  }

  return postRequestNewCustomTrainingResponse;
};

export default postRequestNewCustomTraining;
