import React from "react";

import { List } from "../../../../../components";

const StepThree = () => {
  const steps = [
    {
      description: (
        <>
          On the <span className="font-medium">Admin Console</span>, Navigate to{" "}
          <span className="font-medium">Security</span> {">"}{" "}
          <span className="font-medium">Access and data control</span> {">"}{" "}
          <span className="font-medium">API Controls</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Click{" "}
          <span className="font-medium">Manage domain wide delegation</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">
          Setup Domain-Wide Delegation
        </h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepThree;
