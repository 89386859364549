import { getResponseGet } from "../utils";

const getInactiveUsers = async () => {
  let getInactiveUsersResponse = {
    result: {
      inactiveUsers: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/users/inactive/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      const inactiveUsers = [];

      for (let i = 0; i < data.length; i++) {
        const userData = data[i];

        inactiveUsers.push({
          firstName: userData.firstName,
          lastName: userData.lastName,
          initials: userData.initials,
          logins: userData.logins,
          riskScore: userData.riskScore ? Math.round(userData.riskScore) : 0,
        });
      }

      getInactiveUsersResponse.result.inactiveUsers = inactiveUsers;
    }
  } catch (error) {
    getInactiveUsersResponse.error = error;
  }

  return getInactiveUsersResponse;
};

export default getInactiveUsers;
