import React, { useEffect, useRef, useState } from "react";

import {
  getMFAProtectionStatuses,
  getUsersByMFAProtectionStatus,
} from "../../../api";

import { CardWithLoadingState, DonutChartWithTable } from "../../../components";

const MFAProtectionStatus = ({ isInitialLoading, email, setBreadcrumbs }) => {
  // const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [mfaProtectionStatuses, setMFAProtectionStatuses] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({ name: "Protected" });
  const [users, setUsers] = useState(null);

  const usersCacheRef = useRef({});

  useEffect(() => {
    const loadMFAProtectionStatuses = async () => {
      const getMFAProtectionStatusesResponse = await getMFAProtectionStatuses();

      if (Object.keys(getMFAProtectionStatusesResponse.error).length > 0) {
        console.error(getMFAProtectionStatusesResponse.error.message);
      } else {
        const { mfaProtectionStatuses } =
          getMFAProtectionStatusesResponse.result;

        setMFAProtectionStatuses(mfaProtectionStatuses);
      }
    };

    if (!isInitialLoading && email) {
      loadMFAProtectionStatuses();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    const loadUsersByMFAProtectionStatus = async () => {
      if (selectedStatus.name && usersCacheRef.current[selectedStatus.name]) {
        setUsers(usersCacheRef.current[selectedStatus.name].users);

        return;
      }

      const getUsersByMFAProtectionStatusResponse =
        await getUsersByMFAProtectionStatus(selectedStatus.name);

      if (Object.keys(getUsersByMFAProtectionStatusResponse.error).length > 0) {
        console.error(getUsersByMFAProtectionStatusResponse.error.message);
      } else {
        const { users } = getUsersByMFAProtectionStatusResponse.result;

        setUsers(users);

        // Store the users data in the cache.
        usersCacheRef.current = {
          ...usersCacheRef.current,
          [selectedStatus.name]: {
            users,
          },
        };
      }
    };

    if (!isInitialLoading && email) {
      loadUsersByMFAProtectionStatus();
    }
  }, [isInitialLoading, email, selectedStatus]);

  useEffect(() => {
    // Update breadcrumbs.
    const timeout = setTimeout(() => {
      setBreadcrumbs([
        {
          name: "Organization",
          path: "/insights/organization/",
        },
        {
          name: "MFA Protection Status",
        },
      ]);
    }, 0);

    return () => clearTimeout(timeout);
  }, [setBreadcrumbs]);

  return (
    <div>
      <CardWithLoadingState loadingVariable={mfaProtectionStatuses}>
        <DonutChartWithTable
          donutChartData={mfaProtectionStatuses}
          tableData={users}
          selectedTab={selectedStatus}
          setSelectedTab={setSelectedStatus}
          title="Total Users"
          filterField="status"
          defaultOrderBy="email"
          headers={[
            {
              id: "email",
              label: "Email",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
            {
              id: "riskScore",
              label: "Risk score",
              isSortable: true,
              isSearchable: false,
              align: "left",
            },
            {
              id: "riskLevel",
              label: "Risk level",
              isSortable: false,
              isSearchable: false,
              align: "left",
            },
          ]}
        />
      </CardWithLoadingState>
    </div>
  );
};

export default MFAProtectionStatus;
