import { getResponseGet } from "../utils";

const getDevicesByProtectionStatus = async (deviceProtectionStatus) => {
  let getDevicesByProtectionStatusesResponse = {
    result: {
      users: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/host-user-map/?status=${deviceProtectionStatus.toLowerCase().replace(" ", "_")}`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      const users = data.map(({ risk_score, OS, ...fields }) => ({
        riskScore: risk_score ? Math.round(risk_score) : 0,
        status: deviceProtectionStatus,
        OS: OS ? OS : "RHEL 6",
        ...fields,
      }));

      getDevicesByProtectionStatusesResponse.result.users = users;
    }
  } catch (error) {
    getDevicesByProtectionStatusesResponse.error = error;
  }

  return getDevicesByProtectionStatusesResponse;
};

export default getDevicesByProtectionStatus;
