import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { StepOne, StepTwo } from "./utils";

import { postOnboardingStatus, postRequestNewCustomAsset } from "../../../api";

import {
  ButtonContainedGreen,
  ButtonOutlinedGreen,
  OnboardingTemplate,
} from "../../../components";

const LaunchInitialTesting = () => {
  const navigate = useNavigate();

  const totalSteps = 2;

  // Details
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Steps
  const [stepNumber, setStepNumber] = useState(1); // Default value is 1.
  const [stepPercentage, setStepPercentage] = useState(1 / totalSteps);

  // Message
  const [errorMessage, setErrorMessage] = useState("");

  const sendCustomAssets = async () => {
    const form = new FormData();

    form.append("department", "All Departments"); // Needed for the endpoint

    selectedFiles.forEach((file) => {
      form.append("file", file);
    });

    const postRequestNewCustomAssetResponse =
      await postRequestNewCustomAsset(form);

    if (Object.keys(postRequestNewCustomAssetResponse.error).length > 0) {
      console.error(postRequestNewCustomAssetResponse.error.message);
      setErrorMessage(postRequestNewCustomAssetResponse.error.message);

      return true;
    }

    return false;
  };

  const sendOnboardingStatus = async (stepName) => {
    const requestBody = {
      stepName,
    };

    const postOnboardingStatusResponse =
      await postOnboardingStatus(requestBody);

    if (Object.keys(postOnboardingStatusResponse.error).length > 0) {
      console.error(postOnboardingStatusResponse.error.message);
      setErrorMessage(postOnboardingStatusResponse.error.message);
    }
  };

  const handleClickBack = () => {
    setErrorMessage("");

    if (stepNumber === 1) {
      navigate("/onboarding/");
    } else {
      const newStep = Math.max(1, stepNumber - 1);

      setStepNumber(newStep);
      setStepPercentage(newStep / totalSteps);
    }
  };

  const handleClickNext = async () => {
    setErrorMessage("");

    // Data validation
    if (stepNumber === 1) {
      if (selectedFiles.length > 0) {
        const isError = await sendCustomAssets();

        if (isError) {
          setErrorMessage("An error has occurred. Please try again.");
          return;
        }

        setSelectedFiles([]);
      }
    }

    const newStep = Math.min(totalSteps, stepNumber + 1);

    setStepNumber(newStep);
    setStepPercentage(newStep / totalSteps);
  };

  const handleClickRequestLaunch = async () => {
    // Last step

    // Update onboarding status.
    await sendOnboardingStatus("is_initial_testing_completed");

    navigate("/insights/initial-testing/"); // Go to the initial testing insights page after completion.
  };

  return (
    <OnboardingTemplate
      title="Launch Initial Testing"
      mainContent={
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="flex justify-between">
              <h3 className="h4">
                Kickstart identifying high risk users and their vulnerabilities
              </h3>
            </div>
            <div className="py-2">
              {stepNumber === 1 && (
                <StepOne
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              )}
              {stepNumber === 2 && (
                <StepTwo onClickRequestLaunch={handleClickRequestLaunch} />
              )}
            </div>
          </div>
          <div>
            <div
              className={`border-t-2 border-green`}
              style={{ width: `calc(${stepPercentage} * 100%)` }}
            ></div>
            <div
              className="flex items-center gap-2 pt-1.5 px-2"
              style={{ borderTop: "1px solid rgba(0, 248, 127, 0.30)" }}
            >
              <ButtonOutlinedGreen onClick={handleClickBack}>
                Back
              </ButtonOutlinedGreen>
              {stepNumber !== 2 && (
                <ButtonContainedGreen onClick={handleClickNext}>
                  Next
                </ButtonContainedGreen>
              )}
              {errorMessage && (
                <div>
                  <p className="description text-red">{errorMessage}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default LaunchInitialTesting;
