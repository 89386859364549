import React from "react";

const MicrosoftExchangeBrandMarkIconLarge = () => {
  return (
    <svg
      width="34"
      height="31"
      viewBox="0 0 34 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Microsoft_Exchange_(2019-present) 1"
        clip-path="url(#clip0_2347_68022)"
      >
        <path
          id="Vector"
          d="M31.8393 0.5H24.2932C24.0099 0.499968 23.7293 0.555774 23.4675 0.664228C23.2058 0.772681 22.968 0.931656 22.7677 1.13207L4.95628 18.9435C4.75588 19.1437 4.5969 19.3816 4.48845 19.6433C4.38 19.9051 4.32419 20.1856 4.32422 20.469V28.0151C4.3242 28.2985 4.38 28.579 4.48842 28.8408C4.59684 29.1026 4.75577 29.3404 4.95612 29.5408C5.15648 29.7412 5.39434 29.9001 5.65612 30.0085C5.9179 30.1169 6.19847 30.1727 6.48182 30.1727H14.028C14.3113 30.1728 14.5919 30.117 14.8536 30.0085C15.1154 29.9 15.3532 29.7411 15.5535 29.5407L33.3652 11.7293C33.7697 11.3247 33.997 10.7759 33.9969 10.2038V2.65762C33.997 2.37428 33.9412 2.0937 33.8327 1.83192C33.7243 1.57014 33.5654 1.33228 33.365 1.13193C33.1647 0.931569 32.9268 0.77264 32.665 0.664213C32.4033 0.555787 32.1227 0.499987 31.8393 0.5Z"
          fill="#E5E5E5"
        />
        <path
          id="Vector_2"
          d="M31.843 30.1723H24.2967C23.7245 30.1723 23.1757 29.945 22.7711 29.5404L19.1641 25.9333V19.1934C19.1641 18.1703 19.5705 17.1892 20.2939 16.4658C21.0173 15.7423 21.9985 15.3359 23.0215 15.3359H29.7615L33.3685 18.943C33.7731 19.3476 34.0004 19.8964 34.0004 20.4685V28.0148C34.0004 28.587 33.7731 29.1358 33.3685 29.5404C32.9639 29.945 32.4152 30.1723 31.843 30.1723Z"
          fill="#A6A6A6"
        />
        <path
          id="Vector_3"
          d="M6.48167 0.5H14.028C14.6002 0.500001 15.1489 0.727304 15.5535 1.13191L19.1606 4.73896V11.4789C19.1606 12.502 18.7542 13.4831 18.0308 14.2065C17.3073 14.93 16.3262 15.3364 15.3031 15.3364H8.56318L4.95612 11.7293C4.55152 11.3247 4.32422 10.7759 4.32422 10.2038V2.65745C4.32422 2.08526 4.55152 1.53651 4.95612 1.1319C5.36072 0.727303 5.90948 0.5 6.48167 0.5Z"
          fill="white"
        />
        <path
          id="Vector_4"
          opacity="0.2"
          d="M17.9242 8.12206V24.4049C17.9256 24.488 17.9173 24.5709 17.8995 24.6521C17.8433 24.9865 17.6701 25.2901 17.4108 25.5086C17.1514 25.7272 16.8229 25.8465 16.4838 25.8453H4.32422V6.68164H16.4838C16.6731 6.68131 16.8605 6.71834 17.0354 6.79061C17.2103 6.86288 17.3693 6.96897 17.5031 7.10279C17.6369 7.23661 17.743 7.39553 17.8153 7.57044C17.8875 7.74535 17.9246 7.9328 17.9242 8.12206Z"
          fill="black"
        />
        <path
          id="Vector_5"
          opacity="0.1"
          d="M18.5424 8.12305V23.1695C18.5373 23.7139 18.3188 24.2345 17.9338 24.6195C17.5489 25.0045 17.0282 25.223 16.4838 25.2281H4.32422V6.06445H16.4838C17.0292 6.06636 17.5517 6.28386 17.9373 6.66951C18.323 7.05516 18.5405 7.57766 18.5424 8.12305Z"
          fill="black"
        />
        <path
          id="Vector_6"
          opacity="0.2"
          d="M17.9242 8.12206V23.1685C17.9246 23.3578 17.8875 23.5452 17.8153 23.7201C17.743 23.895 17.6369 24.0539 17.5031 24.1878C17.3693 24.3216 17.2103 24.4277 17.0354 24.4999C16.8605 24.5722 16.6731 24.6092 16.4838 24.6089H4.32422V6.68164H16.4838C16.6731 6.68131 16.8605 6.71834 17.0354 6.79061C17.2103 6.86288 17.3693 6.96897 17.5031 7.10279C17.6369 7.23661 17.743 7.39553 17.8153 7.57044C17.8875 7.74535 17.9246 7.93281 17.9242 8.12206Z"
          fill="black"
        />
        <path
          id="Vector_7"
          opacity="0.1"
          d="M17.306 8.12206V23.1685C17.3064 23.3578 17.2693 23.5452 17.1971 23.7201C17.1248 23.895 17.0187 24.0539 16.8849 24.1878C16.7511 24.3216 16.5921 24.4277 16.4172 24.4999C16.2423 24.5722 16.0549 24.6092 15.8656 24.6089H4.32422V6.68164H15.8656C16.0549 6.68131 16.2423 6.71834 16.4172 6.79061C16.5921 6.86288 16.7511 6.96897 16.8849 7.10279C17.0187 7.23661 17.1248 7.39553 17.1971 7.57044C17.2693 7.74535 17.3064 7.93281 17.306 8.12206Z"
          fill="black"
        />
        <path
          id="Vector_8"
          d="M15.8667 6.68164H1.44242C0.645795 6.68164 0 7.32744 0 8.12406V22.5483C0 23.3449 0.645795 23.9907 1.44242 23.9907H15.8667C16.6633 23.9907 17.3091 23.3449 17.3091 22.5483V8.12406C17.3091 7.32744 16.6633 6.68164 15.8667 6.68164Z"
          fill="#A6A6A6"
        />
        <path
          id="Vector_9"
          d="M11.4861 12.1721H7.43874V14.5388H11.2377V16.0557H7.43874V18.5139H11.7018V20.0244H5.60156V10.6484H11.4861L11.4861 12.1721Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2347_68022">
          <rect
            width="34"
            height="29.6727"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicrosoftExchangeBrandMarkIconLarge;
