import React from "react";

const TrainingVideoTimeEstimate = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <mask
        id="mask0_3496_2230"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="13"
      >
        <rect y="0.5" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3496_2230)">
        <path
          d="M7 8C7.14167 8 7.26458 7.94792 7.36875 7.84375C7.47292 7.73958 7.525 7.61667 7.525 7.475C7.525 7.33333 7.47292 7.21042 7.36875 7.10625C7.26458 7.00208 7.14167 6.95 7 6.95C6.85833 6.95 6.73542 7.00208 6.63125 7.10625C6.52708 7.21042 6.475 7.33333 6.475 7.475C6.475 7.61667 6.52708 7.73958 6.63125 7.84375C6.73542 7.94792 6.85833 8 7 8ZM6.625 6.4H7.375C7.375 6.15833 7.4 5.98125 7.45 5.86875C7.5 5.75625 7.61667 5.60833 7.8 5.425C8.05 5.175 8.21667 4.97292 8.3 4.81875C8.38333 4.66458 8.425 4.48333 8.425 4.275C8.425 3.9 8.29375 3.59375 8.03125 3.35625C7.76875 3.11875 7.425 3 7 3C6.65833 3 6.36042 3.09583 6.10625 3.2875C5.85208 3.47917 5.675 3.73333 5.575 4.05L6.25 4.325C6.325 4.11667 6.42708 3.96042 6.55625 3.85625C6.68542 3.75208 6.83333 3.7 7 3.7C7.2 3.7 7.3625 3.75625 7.4875 3.86875C7.6125 3.98125 7.675 4.13333 7.675 4.325C7.675 4.44167 7.64167 4.55208 7.575 4.65625C7.50833 4.76042 7.39167 4.89167 7.225 5.05C6.95 5.29167 6.78125 5.48125 6.71875 5.61875C6.65625 5.75625 6.625 6.01667 6.625 6.4ZM4 9.5C3.725 9.5 3.48958 9.40208 3.29375 9.20625C3.09792 9.01042 3 8.775 3 8.5V2.5C3 2.225 3.09792 1.98958 3.29375 1.79375C3.48958 1.59792 3.725 1.5 4 1.5H10C10.275 1.5 10.5104 1.59792 10.7063 1.79375C10.9021 1.98958 11 2.225 11 2.5V8.5C11 8.775 10.9021 9.01042 10.7063 9.20625C10.5104 9.40208 10.275 9.5 10 9.5H4ZM4 8.5H10V2.5H4V8.5ZM2 11.5C1.725 11.5 1.48958 11.4021 1.29375 11.2063C1.09792 11.0104 1 10.775 1 10.5V3.5H2V10.5H9V11.5H2Z"
          fill="#848483"
        />
      </g>
    </svg>
  );
};

export default TrainingVideoTimeEstimate;
