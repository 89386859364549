import React, { useEffect, useRef, useState } from "react";

import {
  getDeviceProtectionStatuses,
  getDevicesByProtectionStatus,
} from "../../../api";

import { CardWithLoadingState, DonutChartWithTable } from "../../../components";

const DeviceProtectionStatus = ({
  isInitialLoading,
  email,
  setBreadcrumbs,
}) => {
  const [deviceProtectionStatuses, setDeviceProtectionStatuses] =
    useState(null);
  const [selectedStatus, setSelectedStatus] = useState({ name: "Compliant" });
  const [users, setUsers] = useState(null);

  const devicesCacheRef = useRef({});

  useEffect(() => {
    const loadDeviceProtectionStatuses = async () => {
      const getDeviceProtectionStatusesResponse =
        await getDeviceProtectionStatuses();

      if (Object.keys(getDeviceProtectionStatusesResponse.error).length > 0) {
        console.error(getDeviceProtectionStatusesResponse.error.message);
      } else {
        const { deviceProtectionStatuses } =
          getDeviceProtectionStatusesResponse.result;

        setDeviceProtectionStatuses(deviceProtectionStatuses);
      }
    };

    if (!isInitialLoading && email) {
      loadDeviceProtectionStatuses();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    const loadDevicesByProtectionStatus = async () => {
      if (selectedStatus.name && devicesCacheRef.current[selectedStatus.name]) {
        setUsers(devicesCacheRef.current[selectedStatus.name].users);

        return;
      }

      const getUsersByMFAProtectionStatusResponse =
        await getDevicesByProtectionStatus(selectedStatus.name);

      if (Object.keys(getUsersByMFAProtectionStatusResponse.error).length > 0) {
        console.error(getUsersByMFAProtectionStatusResponse.error.message);
      } else {
        const { users } = getUsersByMFAProtectionStatusResponse.result;

        setUsers(users);

        // Store the users data in the cache.
        devicesCacheRef.current = {
          ...devicesCacheRef.current,
          [selectedStatus.name]: {
            users,
          },
        };
      }
    };

    if (!isInitialLoading && email) {
      loadDevicesByProtectionStatus();
    }
  }, [isInitialLoading, email, selectedStatus]);

  useEffect(() => {
    // Update breadcrumbs.
    const timeout = setTimeout(() => {
      setBreadcrumbs([
        {
          name: "Organization",
          path: "/insights/organization/",
        },
        {
          name: "Device Protection Status",
        },
      ]);
    }, 0);

    return () => clearTimeout(timeout);
  }, [setBreadcrumbs]);

  return (
    <div>
      <CardWithLoadingState loadingVariable={deviceProtectionStatuses}>
        <DonutChartWithTable
          donutChartData={deviceProtectionStatuses}
          tableData={users}
          selectedTab={selectedStatus}
          setSelectedTab={setSelectedStatus}
          title="Total Devices"
          filterField="status"
          defaultOrderBy="hostID"
          noDataMessage="No devices found"
          headers={[
            {
              id: "hostID",
              label: "Host ID",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
            {
              id: "email",
              label: "Email",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
            {
              id: "OS",
              label: "Product",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
            {
              id: "Chasis",
              label: "Chasis",
              isSortable: true,
              isSearchable: true,
              align: "left",
            },
          ]}
        />
      </CardWithLoadingState>
    </div>
  );
};

export default DeviceProtectionStatus;
