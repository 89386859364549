import { getResponseGet } from "../utils";

const getDeviceProtectionStatuses = async () => {
  let getDeviceProtectionStatusesResponse = {
    result: {
      deviceProtectionStatuses: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/device-protection-statuses/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      let provisionedPercentage = 0;
      let unprovisionedPercentage = 0;
      let deprovisionedPercentage = 0;

      const filteredProvisionedStatus = data.filter(
        (element) => element.status === "Provisioned",
      );

      const filteredNotProvisionedStatus = data.filter(
        (element) => element.status === "NotProvisioned",
      );

      // Empty status means deprovisioned.
      const filteredDeprovisionedStatus = data.filter(
        (element) => element.status === "",
      );

      if (
        filteredProvisionedStatus.length > 0 &&
        filteredProvisionedStatus[0].percentage
      ) {
        provisionedPercentage = filteredProvisionedStatus[0].percentage / 100;
      }

      if (
        filteredNotProvisionedStatus.length > 0 &&
        filteredNotProvisionedStatus[0].percentage
      ) {
        unprovisionedPercentage =
          filteredNotProvisionedStatus[0].percentage / 100;
      }

      if (
        filteredDeprovisionedStatus.length > 0 &&
        filteredDeprovisionedStatus[0].percentage
      ) {
        deprovisionedPercentage =
          filteredDeprovisionedStatus[0].percentage / 100;
      }

      const deviceProtectionStatuses = {
        Compliant: provisionedPercentage,
        "Not Compliant": unprovisionedPercentage,
        Unknown: deprovisionedPercentage,
      };

      getDeviceProtectionStatusesResponse.result.deviceProtectionStatuses =
        deviceProtectionStatuses;
    }
  } catch (error) {
    getDeviceProtectionStatusesResponse.error = error;
  }

  return getDeviceProtectionStatusesResponse;
};

export default getDeviceProtectionStatuses;
