import React from "react";

import { CardHeader, CardWithLoadingState, DataTable } from "../../../..";

const headers = [
  {
    id: "device",
    label: "Device",
    isSortable: true,
    align: "center",
    width: "200px",
  },
  {
    id: "lastActivityTime",
    label: "Last Activity Time",
    isSortable: true,
    align: "center",
    width: "200px",
  },
  {
    id: "deviceStatus",
    label: "Device Status",
    isSortable: true,
    align: "left",
    width: "200px",
  },
];

const CardDeviceList = ({ deviceList }) => {
  return (
    <CardWithLoadingState loadingVariable={deviceList}>
      <CardHeader header="Device List" />
      <DataTable
        data={deviceList}
        headers={headers}
        rowsPerPage={2}
        defaultOrder="asc"
        defaultOrderBy="lastActivityTime"
        disablePagination
        disableSearchBar
      />
      {/* <div className="flex justify-end items-start lg:items-end xl:items-start px-0.625 py-0.875 w-full">
        <div className="flex flex-row">
          <ButtonOutlinedGreen isSmall>
            View all
          </ButtonOutlinedGreen>
        </div>
      </div> */}
    </CardWithLoadingState>
  );
};

export default CardDeviceList;
