import React from "react";

import { CardHeader, CardWithLoadingState } from "../../../..";

import { VisMap } from "../../../../../platform/maps-visualization";

const CardUserActivityHeatMap = ({ userActivityHeatmap }) => {
  return (
    <CardWithLoadingState
      loadingVariable={userActivityHeatmap}
      minHeight="517px"
    >
      <CardHeader header="User Activity Heatmap" />
      <div>
        <p className="description text-gray-surface-contrast">
          Analyze login activity patterns across your organization to detect
          unusual access attempts, especially during off-hours.
        </p>
      </div>
      <div className="flex h-full mt-2.125">
        <VisMap scrollwheel={false} data={userActivityHeatmap} />
      </div>
      {/* <div className="flex justify-end items-start lg:items-end xl:items-start px-0.625 py-0.875 w-full">
        <div className="flex flex-row lg:flex-col xl:flex-row gap-0.625">
          <ButtonOutlinedGreen isSmall>Details</ButtonOutlinedGreen>
        </div>
      </div> */}
    </CardWithLoadingState>
  );
};

export default CardUserActivityHeatMap;
