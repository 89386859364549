import React from "react";

import { CustomLinkGreen, List } from "../../../../../components";

const StepOne = () => {
  const steps = [
    {
      description: (
        <>
          Go to the{" "}
          <CustomLinkGreen
            href="https://workspace.google.com/marketplace/app/dune_watchtower/721760549350"
            title="Go to the Dune Watchtower app page."
            target="_blank"
          >
            Dune Watchtower app
          </CustomLinkGreen>{" "}
          page on the Google Workspace Marketplace.
        </>
      ),
    },
    {
      description: (
        <>
          Click the <span className="font-medium">Admin Install</span> button.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Continue</span>.
        </>
      ),
    },
    {
      description:
        "You can choose to install the app for your entire organization or for specific people",
    },
    {
      description: (
        <>
          After selecting the desired groups, click{" "}
          <span className="font-medium">Install</span> to deploy Dune
          Watchtower.
        </>
      ),
    },
  ];

  return (
    <div>
      <List data={steps} keyName="description" textSize="onboarding-step" />
    </div>
  );
};

export default StepOne;
