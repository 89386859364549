import React, { useState, useEffect, useRef } from "react";
import { DropDownFilledIcon } from "../icons";
import { websiteLanguages } from "../constants/common/languages";

const LanguageSelector = ({
  onChange = () => {},
  initialLanguage = "en",
  languages = websiteLanguages,
  showLanguageLabel = true,
  showSearchBar = true,
  dropdownText = "Choose your language",
  showLanguageSelectionText = false,
  languageSelectionText = "",
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!selectedLanguage) {
      const detectedLanguage = navigator.language || navigator.userLanguage;

      const matchingLanguage = languages.find(
        (lang) =>
          lang.value === detectedLanguage ||
          lang.value.startsWith(detectedLanguage),
      );

      if (matchingLanguage) {
        setSelectedLanguage(matchingLanguage.value);
        onChange(matchingLanguage.value);
      }
    }
  }, [languages, onChange, selectedLanguage]);

  useEffect(() => {
    setSelectedLanguage(initialLanguage);
  }, [initialLanguage]);

  const handleChange = (value) => {
    setSelectedLanguage(value);
    setIsDropdownOpen(false);
    onChange(value);
  };

  const filteredLanguages = languages.filter((lang) =>
    lang.label.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex justify-center items-center">
      {showLanguageSelectionText && (
        <div className="text-h5 text-white w-full mr-2.5">
          {languageSelectionText}
        </div>
      )}
      <div
        style={{ position: "relative", display: "inline-block" }}
        ref={dropdownRef}
      >
        <div
          className="flex text-3xl"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          style={{
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "50px",
            cursor: "pointer",
          }}
        >
          <div className="flex items-center justify-center">
            {showLanguageLabel && (
              <div className="text-h5 mr-0.5">
                {
                  languages.find((lang) => lang.value === selectedLanguage)
                    ?.label
                }{" "}
              </div>
            )}
            {languages.find((lang) => lang.value === selectedLanguage)?.flag}{" "}
          </div>
          <div tabIndex="0" role="button" className="px-0.25 text-white">
            <DropDownFilledIcon />
          </div>
        </div>

        {isDropdownOpen && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "black",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              zIndex: 1000,
              width: "300px",
              maxHeight: "360px",
              overflowY: "auto",
              right: 0,
            }}
          >
            <div className="py-0.5">{dropdownText}</div>
            {showSearchBar && (
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Type in a country"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "5px",
                  border: "1px solid gray",
                  borderRadius: "3px",
                  marginBottom: "10px",
                  width: "100%",
                }}
              />
            )}

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
              }}
            >
              {filteredLanguages.map((lang) => (
                <div
                  className="px-0.5"
                  key={lang.value}
                  onClick={() => handleChange(lang.value)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span style={{ marginRight: "10px" }}>{lang.flag}</span>
                  <span>{lang.label}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSelector;
