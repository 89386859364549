import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { StepOne } from "./utils";

import { postOnboardingStatus } from "../../../api";

import {
  ButtonContainedGreen,
  ButtonOutlinedGreen,
  StepTemplate,
} from "../../../components";

const SetupWatchtowerMicrosoft = () => {
  const navigate = useNavigate();

  // Steps
  const stepNumber = 1;
  const stepPercentage = 1;

  // Message
  const [errorMessage, setErrorMessage] = useState("");

  const sendOnboardingStatus = async (stepName) => {
    const requestBody = {
      stepName,
    };

    const postOnboardingStatusResponse =
      await postOnboardingStatus(requestBody);

    if (Object.keys(postOnboardingStatusResponse.error).length > 0) {
      console.error(postOnboardingStatusResponse.error.message);
      setErrorMessage(postOnboardingStatusResponse.error.message);
    }
  };

  const handleClickBack = () => {
    setErrorMessage("");

    navigate("/onboarding/");
  };

  const handleClickNext = async () => {
    setErrorMessage("");

    // Last step

    // Update onboarding status.
    await sendOnboardingStatus("is_watchtower_completed");

    navigate("/onboarding/"); // Go back to the welcome screen after completion.
  };

  return (
    <StepTemplate
      title="Deploy Watchtower in Microsoft"
      mainContent={
        <div className="flex flex-col justify-between h-full">
          <div className="px-2">
            <div className="flex justify-between">
              <h3 className="h2">Steps</h3>
            </div>
            <div className="mt-2 pt-4.75 pb-2">
              {stepNumber === 1 && <StepOne />}
            </div>
          </div>
          <div>
            <div
              className={`border-t-2 border-green`}
              style={{ width: `calc(${stepPercentage} * 100%)` }}
            ></div>
            <div
              className="flex items-center gap-2 pt-1.5 px-2"
              style={{ borderTop: "1px solid rgba(0, 248, 127, 0.30)" }}
            >
              <ButtonOutlinedGreen onClick={handleClickBack}>
                Back
              </ButtonOutlinedGreen>
              <ButtonContainedGreen onClick={handleClickNext}>
                Next
              </ButtonContainedGreen>
              {errorMessage && (
                <div>
                  <p className="description text-red">{errorMessage}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      screenshotVideo={
        stepNumber === 1
          ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/watchtower-microsoft-step-1.mp4"
          : ""
      }
      screenshotImage=""
    />
  );
};

export default SetupWatchtowerMicrosoft;
