import React from "react";

import { OnboardingDashboard } from "../../../utils";

const OnboardingTemplate = ({
  title,
  organization,
  mainContent,
}) => {
  return (
    <div className="flex flex-col lg:flex-row">
      <div
        className="flex flex-col justify-between w-full h-full min-h-[calc(100vh-69px)] pt-2 pl-4"
        style={{
          background:
            "radial-gradient(114.47% 141.42% at 100% 100%, #006031 0%, #006031 18.55%, #0C0C0B 100%)",
        }}
      >
        <div className="pr-2">
          <h2 className="h2">{title}</h2>
        </div>
        <div className="flex items-center justify-end mt-4">
          <OnboardingDashboard organization={organization} />
        </div>
      </div>
      <div className="w-full p-2 border-l border-border-color">
        {mainContent}
      </div>
    </div>
  );
};

export default OnboardingTemplate;
