const formatRecentTestingActivity = (pastAttacks) => {
  if (!pastAttacks || pastAttacks.length === 0) {
    return [];
  }

  let recentTestingActivity = [];

  const sortedPastAttacks = [...pastAttacks];
  sortedPastAttacks.sort((a, b) => {
    const timestampA = a.timestamp ? a.timestamp : a.date;
    const timestampB = b.timestamp ? b.timestamp : b.date;

    const dateA = new Date(timestampA);
    const dateB = new Date(timestampB);

    return dateA - dateB;
  });

  for (
    let i = sortedPastAttacks.length < 10 ? 0 : sortedPastAttacks.length - 10;
    i < sortedPastAttacks.length;
    i++
  ) {
    const element = sortedPastAttacks[i];

    // Date
    const date = element.timestamp
      ? new Date(element.timestamp)
      : new Date(element.date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}/${month}/${day}`;

    // Method of attack
    let methodOfAttack = "N/A";

    if (
      element.threats &&
      Array.isArray(element.threats) &&
      element.threats.length > 0
    ) {
      if (element.threats.includes("internal processes")) {
        methodOfAttack = "Impersonation of internal processes";
      } else if (element.threats.includes("c suite impersonation")) {
        methodOfAttack = "C-suite impersonation";
      } else if (element.threats.includes("display name deception")) {
        methodOfAttack = "Display name deception";
      } else if (element.threats.includes("request for action")) {
        methodOfAttack = "Request for action";
      } else if (element.threats.includes("financial transactions")) {
        methodOfAttack = "Unexpected financial transactions";
      } else if (element.threats.includes("tech notifs")) {
        methodOfAttack = "Fake technical notifications";
      } else if (element.threats.includes("pretexting")) {
        methodOfAttack = "Pretexting";
      } else if (element.threats.includes("link manipulation")) {
        methodOfAttack = "Link manipulation";
      }
    }

    let hasReplied = false;

    if (element.interactions && element.interactions.smtp_reply) {
      hasReplied = element.interactions.smtp_reply;
    }

    let hasReported = false;

    if (element.watchtower_interaction && element.watchtower_interaction.email_reported) {
      hasReported = element.watchtower_interaction.email_reported;
    }

    recentTestingActivity.push({
      id: element.uuid,
      date: formattedDate,
      passed: !element.link_clicked && !hasReplied, // Pass if link not clicked and did not reply.
      impersonator: element.impersonator,
      source: {
        isURLClicked:
          element.source && element.source.url_clicked
            ? element.source.url_clicked
            : null,
        isQRScanned:
          element.source && element.source.qr_scanned
            ? element.source.qr_scanned
            : null,
      },
      imposterPortal: {
        subdomain:
          element.imposter_portal && element.imposter_portal.subdomain
            ? element.imposter_portal.subdomain
            : "",
        isVisited:
          element.imposter_portal && element.imposter_portal.visited
            ? element.imposter_portal.visited
            : false,
        isKeyDown:
          element.imposter_portal && element.imposter_portal.keydown
            ? element.imposter_portal.keydown
            : false,
        isDataEntered:
          element.imposter_portal && element.imposter_portal.data_entered
            ? element.imposter_portal.data_entered
            : false,
        isMFAEntered:
          element.imposter_portal && element.imposter_portal.mfa_entered
            ? element.imposter_portal.mfa_entered
            : false,
      },
      hasReplied,
      hasReported,
      internalOrExternal:
        element.scope && element.scope === "i" ? "Internal" : "External",
      difficulty: element.fidelity
        ? element.fidelity.charAt(0).toUpperCase() + element.fidelity.slice(1)
        : "N/A",
      attackVector: element.attack_vector
        ? element.attack_vector === "phishing"
          ? "Email phishing"
          : element.attack_vector === "smishing"
            ? "SMS smishing"
            : "N/A"
        : "N/A",
      methodOfAttack,
      domain: element.domain ? element.domain : "N/A",
    });
  }

  return recentTestingActivity;
};

export default formatRecentTestingActivity;
