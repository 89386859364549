import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonOutlinedGreen,
  CardHeader,
  CardWithLoadingState,
  DonutChart,
  Legend,
} from "../../../..";

function CardTrainingCompletion({ trainingCompletion }) {
  const navigate = useNavigate();

  const goToMoreDetailsPage = () => {
    navigate("/insights/organization/training-status/");
  };

  const handleClickCell = () => {
    goToMoreDetailsPage();
  };

  const handleClickDetails = () => {
    goToMoreDetailsPage();
  };

  return (
    <CardWithLoadingState loadingVariable={trainingCompletion}>
      <CardHeader header="Training Status" />
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex justify-center py-0.5">
            <div className="max-w-[400px] w-full">
              <div className="flex items-center gap-1 py-0.5">
                <div className="flex max-w-[224px] w-full">
                  <DonutChart
                    data={trainingCompletion}
                    title="Total Users"
                    minHeight={224}
                    onClickCell={handleClickCell}
                  />
                </div>
                <div className="w-[164px]">
                  <Legend data={trainingCompletion} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-start lg:items-end xl:items-start px-0.625 py-0.875 w-full">
          <div className="flex flex-row lg:flex-col xl:flex-row gap-0.625">
            <ButtonOutlinedGreen
              isSmall
              title="Go see more details on training statuses."
              onClick={handleClickDetails}
            >
              Details
            </ButtonOutlinedGreen>
          </div>
        </div>
      </div>
    </CardWithLoadingState>
  );
}

export default CardTrainingCompletion;
