import React from "react";

import { VideoTag } from "../../../../../components";
import { GreenRightArrowIcon } from "../../../../../utils";
import { formatDateTwo } from "../../../../../utils/helper-functions/formatDate";

const TrainingCard = ({
  padding,
  borderColor = "rgba(194, 191, 184, 0.35)",
  minHeight,
  imgSrc,
  title,
  trainingType,
  handleClickModule,
  duration,
  dueDate,
}) => {
  return (
    <div
      className={`flex flex-col h-full border w-full ${
        padding ? padding : "p-1"
      } bg-gray-card rounded-medium transition-all duration-1000`}
      style={{
        border: `1px solid ${borderColor}`,
        minHeight: minHeight ? minHeight : 0,
      }}
    >
      <div className="flex flex-col">
        <div>
          <img className="rounded-t-medium" alt={title} src={imgSrc} />
        </div>
        <div className="flex items-center p-0.5">
          <h3 className="description-emphasized text-h5 min-h-2.5 h-full">
            {title}
          </h3>
        </div>
        <div className="px-0.5">
          <VideoTag trainingType={trainingType} />
        </div>
        {dueDate && (
          <div className="px-0.5 py-0.5">
            <span className="text-gray">Due Date: </span>
            <span className="text-white">{formatDateTwo(dueDate)}</span>
          </div>
        )}
        {duration && (
          <div className="px-0.5 py-0.5">
            <span className="text-gray">Duration: </span>
            <span className="text-white">{duration}</span>
          </div>
        )}
        <div className="text-green flex justify-end items-center my-0.5 mr-0.75 font-medium">
          <span
            className="cursor-pointer hover:text-green-hover"
            onClick={handleClickModule}
          >
            Start Module
          </span>
          <span>
            <GreenRightArrowIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

export default TrainingCard;
