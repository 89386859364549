import React from "react";

import { List } from "../../../../../components";

const StepFive = () => {
  const steps = [
    {
      description: (
        <>
          Navigate to <span className="font-medium">Google Cloud Console</span>{" "}
          and click on <span className="font-medium">APIs & Services</span>.
        </>
      ),
    },
    {
      description: (
        <>
          In the APIs & Services dashboard, find the search bar at the top and
          type <span className="font-medium">Gmail API</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Open the Gmail API page, click the{" "}
          <span className="font-medium">Enable</span> button.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">Enable Gmail API</h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepFive;
