import React from "react";

const TrainingVideoLocked = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7.33333"
        fill="#A6A6A5"
        stroke="#A6A6A5"
        stroke-width="1.33333"
      />
      <mask
        id="mask0_3496_2221"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="12"
        height="12"
      >
        <rect
          x="2.66602"
          y="2.6665"
          width="10.6667"
          height="10.6667"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_3496_2221)">
        <path
          d="M5.33225 12.4442C5.0878 12.4442 4.87854 12.3571 4.70447 12.1831C4.5304 12.009 4.44336 11.7997 4.44336 11.5553V7.11084C4.44336 6.8664 4.5304 6.65714 4.70447 6.48306C4.87854 6.30899 5.0878 6.22195 5.33225 6.22195H5.77669V5.33306C5.77669 4.71825 5.99336 4.19417 6.42669 3.76084C6.86003 3.32751 7.3841 3.11084 7.99891 3.11084C8.61373 3.11084 9.1378 3.32751 9.57114 3.76084C10.0045 4.19417 10.2211 4.71825 10.2211 5.33306V6.22195H10.6656C10.91 6.22195 11.1193 6.30899 11.2934 6.48306C11.4674 6.65714 11.5545 6.8664 11.5545 7.11084V11.5553C11.5545 11.7997 11.4674 12.009 11.2934 12.1831C11.1193 12.3571 10.91 12.4442 10.6656 12.4442H5.33225ZM7.99891 10.222C8.24336 10.222 8.45262 10.1349 8.62669 9.96084C8.80077 9.78677 8.8878 9.57751 8.8878 9.33306C8.8878 9.08862 8.80077 8.87936 8.62669 8.70528C8.45262 8.53121 8.24336 8.44417 7.99891 8.44417C7.75447 8.44417 7.54521 8.53121 7.37114 8.70528C7.19706 8.87936 7.11003 9.08862 7.11003 9.33306C7.11003 9.57751 7.19706 9.78677 7.37114 9.96084C7.54521 10.1349 7.75447 10.222 7.99891 10.222ZM6.66558 6.22195H9.33225V5.33306C9.33225 4.96269 9.20262 4.64788 8.94336 4.38862C8.6841 4.12936 8.36929 3.99973 7.99891 3.99973C7.62854 3.99973 7.31373 4.12936 7.05447 4.38862C6.79521 4.64788 6.66558 4.96269 6.66558 5.33306V6.22195Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};

export default TrainingVideoLocked;
