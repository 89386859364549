import React from "react";

import { List } from "../../../../../components";

const StepOne = () => {
  const steps = [
    {
      description: "Navigate to Applications > Applications.",
    },
    {
      description: (
        <>
          Click <span className="font-medium">Add Application</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Select <span className="font-medium">Create New App</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Choose <span className="font-medium">Web</span> as the platform and{" "}
          <span className="font-medium">SAML 2.0</span> as the sign-on method.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Create</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">In Okta Admin Console</h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepOne;
