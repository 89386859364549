import React from "react";
import { useNavigate } from "react-router-dom";

import { OnboardingTemplate } from "../../../components";

import {
  GoogleLogoIconLarge,
  MicrosoftEntraIDBrandMarkIconLarge,
  MicrosoftEntraIDLogoIconLarge,
  OktaLogoIconLarge,
} from "../../../utils";

const SetupSSOAndSCIM = ({ organization, pictureFileName }) => {
  const navigate = useNavigate();

  const iamProviders = [
    {
      name: "Microsoft Entra ID",
      logo: (
        <div className="flex items-center gap-0.25">
          <MicrosoftEntraIDBrandMarkIconLarge />
          <MicrosoftEntraIDLogoIconLarge />
        </div>
      ),
      path: "/onboarding/setup-sso-and-scim/entra-id/",
    },
    {
      name: "Okta",
      logo: <OktaLogoIconLarge />,
      path: "/onboarding/setup-sso-and-scim/okta/",
    },
    {
      name: "Google Workspace",
      logo: <GoogleLogoIconLarge />,
      path: "/onboarding/setup-sso-and-scim/google-workspace/",
    },
  ];

  const handleClickIAMProvider = (provider) => {
    if (provider && provider.path) {
      navigate(provider.path);
    }
  };

  return (
    <OnboardingTemplate
      title="Setup SSO and SCIM"
      organization={organization}
      pictureFileName={pictureFileName}
      mainContent={
        <div>
          <div>
            <h3 className="h4">
              Set Up Your Identity and Access Management (IAM) Integration
            </h3>
          </div>
          <div className="mt-1">
            <p className="body text-gray-surface-secondary">
              Ensure Seamless and Secure access for your users by configuring
              Single Sign On
            </p>
          </div>
          <div className="mt-8">
            <p className="body-emphasized">Select your IAM provider</p>
            <div className="flex flex-col justify-between gap-2 mt-2 md:flex-row">
              {iamProviders.map((provider, index) => (
                <div
                  className="flex flex-col justify-between min-h-[144px] w-full px-1 py-1.5 border rounded-medium bg-gray-card border-border-color"
                  role="button"
                  onClick={() => handleClickIAMProvider(provider)}
                  key={`iam-provider-${index}`}
                >
                  <div>{provider.logo}</div>
                  <div className="pt-2.875">
                    <p className="body-emphasized">{provider.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default SetupSSOAndSCIM;
