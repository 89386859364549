import React from "react";
import { useNavigate } from "react-router-dom";

import { CheckmarkWithCircleOutlinedIcon } from "../../../../../utils";

const StepButton = ({ stepNumber, description, selectedStepNumber, path }) => {
  const navigate = useNavigate();

  const handleClickStepButton = () => {
    if (path) {
      navigate(path);
    }
  };

  const isDisabled = selectedStepNumber < stepNumber;

  return (
    <div
      className={`flex items-center w-full h-[112px] pl-2.625 pr-2.625 rounded-medium
        ${isDisabled ? "bg-[#131313]" : "bg-gray-card"}
        ${!isDisabled ? "cursor-pointer" : "cursor-auto"}`}
      role="button"
      onClick={!isDisabled ? handleClickStepButton : null}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex">
          <div
            className={`flex justify-center w-[20px] py-0.25 ${selectedStepNumber === stepNumber && "border-b-green border-b-[2px]"}`}
          >
            <span
              className={`font-medium description ${isDisabled && "text-[#4b4b4a]"}`}
            >
              {stepNumber}
            </span>
          </div>
          <div className="ml-0.5">
            <p className={`h4 ${isDisabled && "text-[#4b4b4a]"}`}>
              {description}
            </p>
          </div>
        </div>
        {selectedStepNumber > stepNumber && (
          <div>
            <CheckmarkWithCircleOutlinedIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default StepButton;
