import React, { useState, useEffect } from "react";
import {
  ButtonOutlinedGreen,
  DropdownIcon,
  Tooltip,
} from "../../../components";
import { DeleteDumpsterIcon } from "../../../utils";
import { Link } from "react-router-dom";
import {
  getTrainingDashboardData,
  postTrainingDashboardData,
} from "../../../api";
import { capitalizeFirstWords } from "../../../utils/helper-functions/capitalize";
import "./configure-training-frequency.css";

const ConfigureTrainingsFrequency = ({ setBreadcrumbs }) => {
  const [minimumTrainingsPerQuarter, setMinimumTrainingsPerQuarter] =
    useState("");
  const [maximumTrainingsPerQuarter, setMaximumTrainingsPerQuarter] =
    useState("");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [riskGroupCount, setRiskGroupCount] = useState([]);
  const [selectUserGroupDropdown, setSelectUserGroupDropdown] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupMinTest, setSelectedGroupMinTest] = useState("");
  const [selectedGroupMaxTest, setSelectedGroupMaxTest] = useState("");
  const [error, setError] = useState("");
  const [hasChanges, setHasChanges] = useState(false);

  const groupOrder = ["high", "medium", "low"];

  const sortedRiskGroupCount = [...riskGroupCount].sort((a, b) => {
    const groupA = Object.keys(a)[0];
    const groupB = Object.keys(b)[0];
    return groupOrder.indexOf(groupA) - groupOrder.indexOf(groupB);
  });

  const validateInputs = (minValue, maxValue) => {
    const trimmedMin = String(minValue)?.trim();
    const trimmedMax = String(maxValue)?.trim();

    if (trimmedMin === "" || trimmedMax === "") {
      return "All inputs must be filled.";
    }

    if (isNaN(trimmedMin) || isNaN(trimmedMax)) {
      return "All inputs must be valid numbers.";
    }

    if (Number(trimmedMin) < 0 || Number(trimmedMax) < 0) {
      return "Values must be non-negative.";
    }

    if (
      !Number.isInteger(Number(trimmedMin)) ||
      !Number.isInteger(Number(trimmedMax))
    ) {
      return "Values must be whole numbers (no decimals).";
    }

    if (Number(trimmedMin) > Number(trimmedMax)) {
      return "Minimum value must be less than or equal to the maximum value.";
    }

    if (Number(trimmedMin) > 70 || Number(trimmedMax) > 70) {
      return "Values must be less than or equal to 70.";
    }

    return null;
  };

  useEffect(() => {
    if (window.location.pathname === "/configure/trainings/frequency/") {
      setEditMode(true);
      setIsAccordionOpen(true);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await getTrainingDashboardData();
      if (response.data.config) {
        if (response.data.config) {
          if (
            response.data.config.risk_group_count &&
            response.data.config.risk_group_count.group_data
          ) {
            setRiskGroupCount(response.data.config.risk_group_count.group_data);
          }

          if (response.data.config.training_count_quarter) {
            const [minTrainings, maxTrainings] =
              response.data.config.training_count_quarter;
            setMinimumTrainingsPerQuarter(minTrainings);
            setMaximumTrainingsPerQuarter(maxTrainings);
          }
        }
      }
    }

    fetchData();
  }, []);

  const handleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

  const handleCancel = () => {
    setEditMode(false);
    setIsAccordionOpen(false);
    setHasChanges(false);
  };

  const handleSave = async () => {
    const parsedRiskGroupCount = riskGroupCount.map((group) => {
      const riskLevel = Object.keys(group)[0];
      const [minValue, maxValue] = group[riskLevel];

      const errorMsg = validateInputs(minValue, maxValue);
      if (errorMsg) {
        setError(errorMsg);
        return null;
      }

      return { [riskLevel]: [Number(minValue), Number(maxValue)] };
    });

    if (parsedRiskGroupCount.includes(null)) return;

    const errorMsg = validateInputs(
      minimumTrainingsPerQuarter,
      maximumTrainingsPerQuarter,
    );
    if (errorMsg) {
      setError(errorMsg);
      return;
    }

    const response = await postTrainingDashboardData({
      training_count_quarter: [
        Number(minimumTrainingsPerQuarter),
        Number(maximumTrainingsPerQuarter),
      ],
      risk_group_count: { group_data: parsedRiskGroupCount },
    });

    if (response.error?.length > 0) {
      console.error("Error saving data", response.error);
    } else {
      setEditMode(false);
      setHasChanges(false);
      setError("");
    }
  };

  const handleEditMode = () => {
    setIsAccordionOpen(true);
    setEditMode(true);
  };

  const handleInputChange = (riskLevel, index, value, isMin) => {
    if (isNaN(value) && value !== "") {
      setError("Please enter a valid number.");
      return;
    }

    setRiskGroupCount((prev) =>
      prev.map((group, i) => {
        if (i === index) {
          const updatedValues = isMin
            ? [value, group[riskLevel][1]]
            : [group[riskLevel][0], value];

          setError("");
          setHasChanges(true);
          return { ...group, [riskLevel]: updatedValues };
        }
        return group;
      }),
    );
  };
  const handleDeleteRiskGroup = (index) => {
    setHasChanges(true);
    setRiskGroupCount((prev) => prev.filter((_, i) => i !== index));
  };

  const toggleSelectUserGroupDropdown = () => {
    setSelectUserGroupDropdown(!selectUserGroupDropdown);
  };

  const handleSelectUserGroup = (group) => {
    const minTest = selectedGroupMinTest || 0;
    const maxTest = selectedGroupMaxTest || 0;

    if (minTest > maxTest) {
      setError("Minimum tests must be less than maximum tests.");
      return;
    } else {
      setError("");
    }

    if (minTest > 70 || maxTest > 70) {
      setError("Tests must be less than 70.");
    } else {
      setError("");
    }

    setRiskGroupCount((prev) => [
      ...prev,
      { [group]: [minTest || "", maxTest || ""] },
    ]);

    setSelectedGroup(null);
    setSelectedGroupMinTest("");
    setSelectedGroupMaxTest("");
    setSelectUserGroupDropdown(false);
    setHasChanges(true);
  };

  const availableUserGroups = ["high", "medium", "low"].filter(
    (group) =>
      !riskGroupCount.some(
        (existingGroup) => Object.keys(existingGroup)[0] === group,
      ),
  );

  const handleWheelDisable = (e) => e.preventDefault();

  return (
    <div className="text-white">
      <div className="flex justify-end">
        {window.location.pathname === "/configure/trainings/frequency/" ? (
          editMode ? null : (
            <ButtonOutlinedGreen onClick={handleEditMode}>
              Modify/Add
            </ButtonOutlinedGreen>
          )
        ) : (
          <Link to="/configure/trainings/frequency/">
            <ButtonOutlinedGreen>Edit / Add Rules</ButtonOutlinedGreen>
          </Link>
        )}
      </div>

      <div className="border border-gray border-opacity-35 bg-gray-background rounded-medium p-1 mt-0.75">
        <div className="flex items-center font-medium text-h4">
          Configure Training Frequency
          <span className="ml-0.125">
            {" "}
            <Tooltip text="Schedule recurring training occurrences for existing users." />
          </span>
        </div>

        <div className="flex items-center font-medium text-h4 mt-1.75">
          Organization Rules
        </div>

        <div className="flex-col lg:flex lg:flex-row items-center mt-2.5">
          <div className="flex items-center justify-between mb-2 lg:w-1/2 lg:mb-0">
            <div className="w-12">Minimum trainings per quarter</div>
            {editMode ? (
              <input
                aria-label="Minimum trainings per quarter"
                className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent lg:mr-3 mr-5"
                value={minimumTrainingsPerQuarter}
                onFocus={(e) =>
                  e.target.addEventListener("wheel", handleWheelDisable)
                }
                onBlur={(e) =>
                  e.target.removeEventListener("wheel", handleWheelDisable)
                }
                onChange={(e) => {
                  setMinimumTrainingsPerQuarter(e.target.value);

                  setHasChanges(true);
                }}
                required
              />
            ) : (
              <div className="ml-8 mr-8 text-h2 lg:ml-0">
                {minimumTrainingsPerQuarter}
              </div>
            )}
          </div>
          <div className="flex items-center justify-between lg:w-1/2">
            <div className="w-12">Maximum trainings per quarter</div>
            {editMode ? (
              <input
                aria-label="Maximum trainings per quarter"
                className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-5"
                value={maximumTrainingsPerQuarter}
                onChange={(e) => {
                  setMaximumTrainingsPerQuarter(e.target.value);
                  setHasChanges(true);
                }}
                onFocus={(e) =>
                  e.target.addEventListener("wheel", handleWheelDisable)
                }
                onBlur={(e) =>
                  e.target.removeEventListener("wheel", handleWheelDisable)
                }
                required
              />
            ) : (
              <div className="mr-8 text-h2">{maximumTrainingsPerQuarter}</div>
            )}
          </div>
        </div>

        <div
          onClick={editMode ? null : handleAccordion}
          className={` flex justify-between items-center ${!editMode ? "border-y border-gray border-opacity-35 p-1 cursor-pointer" : "border-none p-0"} mt-3`}
        >
          <div className="font-medium text-h5">
            Custom rules for User Groups
          </div>
          <div
            className={`duration-300 ${isAccordionOpen ? "rotate-180" : ""}`}
          >
            {editMode ? null : <DropdownIcon />}
          </div>
        </div>

        {isAccordionOpen && (
          <div>
            {riskGroupCount.length > 0 ? (
              sortedRiskGroupCount.map((group, index) => {
                const [riskLevel] = Object.keys(group);
                const [minValue, maxValue] = group[riskLevel];
                return (
                  <div key={index}>
                    <div className="lg:flex lg:flex-row flex-col items-center mt-2.5 mb-2">
                      <div className="flex items-center justify-between mb-2 lg:w-1/2 lg:mb-0">
                        <div>
                          <div className="capitalize text-h5">
                            {riskLevel} Risk Users
                          </div>
                          <div className="text-h6">
                            Minimum trainings per quarter
                          </div>
                        </div>
                        {editMode ? (
                          <input
                            aria-label="Minimum trainings per quarter"
                            value={minValue}
                            onChange={(e) =>
                              handleInputChange(
                                riskLevel,
                                index,
                                e.target.value,
                                true,
                              )
                            }
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                handleWheelDisable,
                              )
                            }
                            onBlur={(e) =>
                              e.target.removeEventListener(
                                "wheel",
                                handleWheelDisable,
                              )
                            }
                            className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent lg:mr-3 mr-5"
                          />
                        ) : (
                          <div className="mr-8 text-h2">{minValue}</div>
                        )}
                      </div>

                      <div className="flex items-center justify-between lg:w-1/2">
                        <div>
                          <div className="capitalize text-h5">
                            {riskLevel} Risk Users
                          </div>
                          <div className="text-h6">
                            Maximum trainings per quarter
                          </div>
                        </div>
                        {editMode ? (
                          <div className="flex items-center">
                            <input
                              aria-label="Maximum trainings per quarter"
                              value={maxValue}
                              onChange={(e) =>
                                handleInputChange(
                                  riskLevel,
                                  index,
                                  e.target.value,
                                  false,
                                )
                              }
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  handleWheelDisable,
                                )
                              }
                              onBlur={(e) =>
                                e.target.removeEventListener(
                                  "wheel",
                                  handleWheelDisable,
                                )
                              }
                              className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-3"
                            />
                            <div
                              className="cursor-pointer"
                              onClick={() => handleDeleteRiskGroup(index)}
                            >
                              <DeleteDumpsterIcon />
                            </div>
                          </div>
                        ) : (
                          <div className="mr-8 text-h2">{maxValue}</div>
                        )}
                      </div>
                    </div>
                    <div className="text-gray text-opacity-35">
                      <hr />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="py-1 mt-2 text-white">
                No user groups set. Add a user group to configure custom rules.
              </div>
            )}

            {editMode && availableUserGroups.length > 0 && (
              <div>
                <div className="relative flex-col items-center justify-center mt-2 lg:flex lg:flex-row lg:mt-0">
                  <div className="flex items-center justify-between lg:w-1/2">
                    <button
                      onClick={toggleSelectUserGroupDropdown}
                      className="flex items-center justify-between border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent mr-3 text-gray text-opacity-75"
                    >
                      <div className="mr-1">
                        {selectedGroup !== null ? (
                          <div>
                            {capitalizeFirstWords(selectedGroup)} Risk Users
                          </div>
                        ) : (
                          <div>Select User Group</div>
                        )}
                      </div>
                      <div tabIndex="0" role="button" className="mr-0.5">
                        <DropdownIcon />
                      </div>
                    </button>

                    {selectUserGroupDropdown && (
                      <div
                        className={`absolute z-10 px-1 bg-black border border-gray border-opacity-35 rounded-medium min-h-2 w-12 ${availableUserGroups.length === 3 ? "mt-11" : availableUserGroups.length === 2 ? "mt-8.25" : "mt-5.5"}`}
                      >
                        {availableUserGroups.map((group) => (
                          <div
                            key={group}
                            className="py-0.5 border-y border-gray border-opacity-35 cursor-pointer"
                            onClick={() => handleSelectUserGroup(group)}
                          >
                            {capitalizeFirstWords(group)} Risk Users
                          </div>
                        ))}
                      </div>
                    )}

                    <input
                      aria-label="Minimum tests"
                      disabled
                      placeholder="Minimum tests"
                      className="number-input border border-gray border-opacity-35 rounded-medium pl-1 py-0.75 bg-transparent lg:mr-3 mr-5"
                      value={selectedGroupMinTest}
                      onChange={(e) => setSelectedGroupMinTest(e.target.value)}
                      onFocus={(e) =>
                        e.target.addEventListener("wheel", handleWheelDisable)
                      }
                      onBlur={(e) =>
                        e.target.removeEventListener(
                          "wheel",
                          handleWheelDisable,
                        )
                      }
                    />
                  </div>

                  <div className="lg:w-1/2">
                    <div className="flex items-center justify-between pt-3 pb-3 pr-5">
                      <div>
                        <div className="text-h5 text-[#848483]">
                          {selectedGroup !== null ? (
                            <div className="text-white">
                              {capitalizeFirstWords(selectedGroup)} Risk Users
                            </div>
                          ) : (
                            <div>Selected User Group</div>
                          )}
                        </div>
                        <div className="text-h6">Maximum tests per Quarter</div>
                      </div>
                      <input
                        aria-label="Maximum tests"
                        disabled
                        className="number-input bg-transparent border border-gray border-opacity-35 rounded-medium pl-1 py-0.75"
                        value={selectedGroupMaxTest}
                        placeholder="Maximum tests"
                        onChange={(e) =>
                          setSelectedGroupMaxTest(e.target.value)
                        }
                        onFocus={(e) =>
                          e.target.addEventListener("wheel", handleWheelDisable)
                        }
                        onBlur={(e) =>
                          e.target.removeEventListener(
                            "wheel",
                            handleWheelDisable,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {editMode && (
          <div className="flex justify-end mt-2 space-x-1">
            <Link to="/configure/trainings/">
              <ButtonOutlinedGreen onClick={handleCancel}>
                Cancel
              </ButtonOutlinedGreen>
            </Link>
            {hasChanges && (
              <ButtonOutlinedGreen onClick={handleSave}>
                Save
              </ButtonOutlinedGreen>
            )}
          </div>
        )}
      </div>
      {error && <div className="text-red mt-0.5">{error}</div>}
    </div>
  );
};

export default ConfigureTrainingsFrequency;
