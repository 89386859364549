import { formatTrainings, getResponseGet } from "../utils";

/**
 * Retrieves training modules assigned to an end user.
 * This function fetches both learning and compliance training modules that have been assigned to the user,
 * facilitating personalized training plans and compliance tracking. The function aims to support personalized
 * learning experiences and ensure compliance with regulatory requirements.
 *
 * @returns {Object} An object containing the fetched training modules in the 'result' field, or an error object in the 'error' field.
 *   - The 'result' object includes:
 *     - learningModules: An array of objects, each representing a learning module assigned to the user. Each module object may include
 *       the module's ID, title, completion status, and due date.
 *     - complianceModules: An array of objects, each representing a compliance training module assigned to the user. Similar to learning modules,
 *       these objects may contain the module's ID, title, regulatory body, completion status, and due date.
 *   - The 'error' field contains an error object if the request fails, providing details about the issue encountered.
 */
const getTrainings = async () => {
  let trainings = {
    result: {
      learningModules: [],
      complianceModules: [],
      completedModules: [],
      assigned_training: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/personal_user_risk_data/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    const rawAssignedTraining = data?.assigned_training || [];

    const { learningModules, complianceModules, completedModules } =
      formatTrainings(data?.assigned_training);

    trainings.result = {
      learningModules,
      complianceModules,
      completedModules,
      assigned_training: rawAssignedTraining,
      complianceModulesStatus: data.compliance,
    };
  } catch (error) {
    trainings.error = error;
  }

  return trainings;
};

export default getTrainings;
