import React from "react";

import { CopyField, List } from "../../../../../components";

const StepFour = () => {
  const steps = [
    {
      description: (
        <>
          Click <span className="font-medium">Add new</span> under API clients.
        </>
      ),
    },
    {
      description: (
        <>
          Enter the <span className="font-medium">Unique ID</span> from the
          Service account details page.
        </>
      ),
    },
    {
      description: (
        <>
          <p>For OAuth scopes, copy and paste the following</p>
          <div className="mt-0.25 w-full">
            <CopyField text="https://mail.google.com/, https://www.googleapis.com/auth/gmail.modify, https://www.googleapis.com/auth/gmail.readonly, https://www.googleapis.com/auth/gmail.labels" />
          </div>
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Authorize</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">Add new API Client</h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepFour;
