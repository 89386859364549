import React from "react";

import { CopyField, List } from "../../../../../components";

const StepThree = ({ acsURL, entityIDDune }) => {
  const steps = [
    {
      description: (
        <>
          <p>Entity ID:</p>
          <div className="mt-0.25 w-full">
            <CopyField text={entityIDDune} />
          </div>
        </>
      ),
    },
    {
      description: (
        <>
          <p>ACS URL (Assertion Consumer Service URL):</p>
          <div className="mt-0.25 w-full">
            <CopyField text={acsURL} />
          </div>
        </>
      ),
    },
    {
      description: (
        <>
          <p>Start URL:</p>
          <div className="mt-0.25 w-full">
            <CopyField text="https://dune.security/login/" />
          </div>
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Continue</span>, then{" "}
          <span className="font-medium">Submit</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">
          Configure Single Sign-On (SSO)
        </h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepThree;
