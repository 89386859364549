import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ButtonTextGreen } from "../buttons";
import { CopyIcon } from "../../../utils";

const CopyField = ({ text }) => {
  return (
    <div className="flex items-center gap-0.25">
      <div className="flex items-center border border-border-color bg-gray-surface-minimal rounded-small w-full px-0.25 break-all">
        {text}
      </div>
      <div>
        <CopyToClipboard text={text}>
          <ButtonTextGreen isSmall>
            <CopyIcon />
          </ButtonTextGreen>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default CopyField;
