import React from "react";

const ConfigureLayout = ({ filters, data }) => {
  return (
    <div className="max-w-full border border-gray border-opacity-35 rounded-small p-0.625 mt-0.5 overflow-hidden">
      <div className="flex items-stretch justify-between h-full md:space-x-1">
        <div className="md:block hidden p-0.5 w-14">{filters}</div>
        <div className="md:block hidden w-px bg-gray bg-opacity-35 self-stretch" />
        <div className="pl-0 md:pl-1 w-full">{data}</div>
      </div>
    </div>
  );
};

export default ConfigureLayout;
