import React from "react";

import { CardHeader, CardWithLoadingState, DataTable } from "../../../..";

const headers = [
  {
    id: "activity",
    label: "Activity",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "activityTime",
    label: "Activity Time",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "dataFrom",
    label: "Data From",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
];

const CardUserActivityTicker = ({ userActivities }) => {
  return (
    <CardWithLoadingState loadingVariable={userActivities}>
      <CardHeader header="User Activity Ticker" />
      <div>
        <p className="max-w-40 description text-gray-surface-contrast">
          Monitor suspicious activities, such as logins from unauthorized
          locations or devices, using real-time anomaly detection. Stay ahead of
          potential threats.
        </p>
      </div>
      <div className="mt-1.25">
        <DataTable
          data={userActivities}
          headers={headers}
          rowsPerPage={5}
          defaultOrder="asc"
          defaultOrderBy="activityTime"
          disableSearchBar
          noDataMessage="No activities found"
        />
      </div>
    </CardWithLoadingState>
  );
};

export default CardUserActivityTicker;
