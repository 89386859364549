import { getResponsePost } from "../utils";

export const postOnboardingStatus = async (requestBody) => {
  let postOnboardingStatusResponse = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePost("/onboarding-status/", requestBody);

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && data.result) {
      postOnboardingStatusResponse.result = data.result;
    }
  } catch (error) {
    postOnboardingStatusResponse.error = error;
  }

  return postOnboardingStatusResponse;
};

export default postOnboardingStatus;
