import React, { useCallback, useEffect, useState } from "react";

import {
  getBraTestingSchedule,
  getInteractionsOrganization,
  getQuickInsights,
  getTestAssetsByDate,
  getTestDates,
  getUsersByInteraction,
} from "../../../api";

import { Dashboard } from "../../../components";

import { pagesPlatformInitialTesting } from "../../../constants";

function InsightsInitialTesting({ isInitialLoading, email }) {
  // This is for the test tracker card.
  const getTodayFormattedDate = () => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const year = today.getFullYear();
    return `${month}-${day}-${year}`;
  };

  // Insights
  const [weekNumber, setWeekNumber] = useState(null);
  const [totalWeeks, setTotalWeeks] = useState(null); // This will come from loadInitialTestingSchedule().
  const [totalSimulationsSent, setTotalSimulationsSent] = useState(null);
  const [initialTestWeeks, setInitialTestWeeks] = useState(null);
  const [initialTestsPerWeek, setInitialTestsPerWeek] = useState(null);
  const [interactions, setInteractions] = useState(null);
  const [usersInteraction, setUsersInteraction] = useState(null);
  const [testDates, setTestDates] = useState(null);
  const [selectedDateForTestTracker, setSelectedDateForTestTracker] = useState(
    getTodayFormattedDate(),
  );
  const [testAssets, setTestAssets] = useState(null);

  // Cache
  const [interactionsCache, setInteractionsCache] = useState({});
  const [usersCache, setUsersCache] = useState({});

  const handleChangeSelectedDateForTestTracker = async (date) => {
    setSelectedDateForTestTracker(date);
  };

  const handleClickInteraction = async (interaction, days) => {
    // For interactions card
    if (email !== "david@dune.demo") {
      setUsersInteraction(null);

      if (usersCache[interaction] && usersCache[interaction][days]) {
        const { users } = usersCache[interaction][days];

        setUsersInteraction(users);
      } else {
        let usersData = {};

        if (days === "All Time") {
          usersData = await getUsersByInteraction(interaction);
        } else {
          usersData = await getUsersByInteraction(interaction, days);
        }

        if (Object.keys(usersData.error).length > 0) {
          console.error(usersData.error.message);
        } else {
          const { users } = usersData.result;

          setUsersInteraction(users);

          // Store the users data in the cache.
          const updatedUsersCache = { ...usersCache };

          let updatedUsersCacheInteraction = {};

          if (updatedUsersCache[interaction]) {
            updatedUsersCacheInteraction = {
              ...updatedUsersCache[interaction],
            };
          }

          updatedUsersCacheInteraction[days] = {
            users,
          };

          updatedUsersCache[interaction] = updatedUsersCacheInteraction;

          setUsersCache(updatedUsersCache);
        }
      }
    }
  };

  const loadInteractions = async (days) => {
    if (interactionsCache[days]) {
      // Check the cache first.
      setInteractions(interactionsCache[days]);
    } else {
      const interactionsOrganization = await getInteractionsOrganization(
        days && days !== "All Time" ? days : null,
      );

      if (Object.keys(interactionsOrganization.error).length > 0) {
        console.error(interactionsOrganization.error.message);
      } else {
        const {
          totalInteractions,
          totalInteractionsResponded,
          totalInteractionsClicked,
          totalInteractionsKeyDown,
          totalInteractionsDataEntered,
          totalInteractionsNoEngagement,
          totalInteractionsReported,
          totalInteractionsMFAEntered,
        } = interactionsOrganization.result;

        const interactions = {
          totalInteractions,
          totalInteractionsResponded,
          totalInteractionsClicked,
          totalInteractionsKeyDown,
          totalInteractionsDataEntered,
          totalInteractionsNoEngagement,
          totalInteractionsReported,
          totalInteractionsMFAEntered,
        };

        setInteractions(interactions);

        // Store the interactions data in the cache.
        const updatedInteractionsCache = { ...interactionsCache };

        if (days === "All Time") {
          updatedInteractionsCache["All Time"] = {
            interactions,
          };
        } else {
          updatedInteractionsCache[days] = {
            interactions,
          };
        }

        setInteractionsCache(updatedInteractionsCache);
      }
    }
  };

  const loadTestAssets = useCallback(async () => {
    if (selectedDateForTestTracker && selectedDateForTestTracker.date) {
      const dateObj = new Date(
        selectedDateForTestTracker.date.replace("-", "/"),
      );

      const selectedDate = `${dateObj.getMonth() + 1}-${dateObj.getDate()}-${dateObj.getFullYear()}`;

      const testAssetsByDateResponse = await getTestAssetsByDate(selectedDate);

      if (Object.keys(testAssetsByDateResponse.error).length > 0) {
        console.error(testAssetsByDateResponse.error.message);
      } else {
        const { testAssets } = testAssetsByDateResponse.result;

        setTestAssets(testAssets);
      }
    }
  }, [selectedDateForTestTracker]);

  const loadTestDates = async () => {
    const testDatesResponse = await getTestDates();

    if (Object.keys(testDatesResponse.error).length > 0) {
      console.error(testDatesResponse.error.message);
    } else {
      const { testDates } = testDatesResponse.result;

      setTestDates(testDates);

      // Get the closest date that is not after today.
      const today = new Date();
      const closestDate = testDates
        .filter((item) => new Date(item.date) <= today) // Filter dates not after today.
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0]; // Sort descending and pick the first.

      setSelectedDateForTestTracker(closestDate);
    }
  };

  useEffect(() => {
    const loadInitialTestingSchedule = async () => {
      const getInitialTestingScheduleResponse = await getBraTestingSchedule();

      if (Object.keys(getInitialTestingScheduleResponse.error).length > 0) {
        console.error(getInitialTestingScheduleResponse.error.message);
      } else {
        const { num_weeks, tests_per_week } =
          getInitialTestingScheduleResponse.result;

        setInitialTestWeeks(num_weeks);
        setInitialTestsPerWeek(tests_per_week);

        // This is for the Quick Insights card.
        setTotalWeeks(num_weeks);
      }
    };

    const loadQuickInsights = async () => {
      const getQuickInsightsResponse = await getQuickInsights();

      if (Object.keys(getQuickInsightsResponse.error).length > 0) {
        console.error(getQuickInsightsResponse.error.message);
      } else {
        const { weekNumber, totalSimulationsSent } =
          getQuickInsightsResponse.result;

        setWeekNumber(weekNumber);
        setTotalSimulationsSent(totalSimulationsSent);
      }
    };

    if (!isInitialLoading && email) {
      loadQuickInsights();
      loadInitialTestingSchedule();
      loadInteractions(90); // Default day value is 90.
      loadTestDates();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoading, email]);

  useEffect(() => {
    const updateComponent = async () => {
      await loadTestAssets();
    };

    if (!isInitialLoading && email) {
      updateComponent();
    }
  }, [selectedDateForTestTracker, isInitialLoading, email, loadTestAssets]);

  return (
    <Dashboard
      pageTitle={pagesPlatformInitialTesting.INSIGHTS.subpages.ORGANIZATION}
      weekNumber={weekNumber}
      totalWeeks={totalWeeks}
      totalSimulationsSent={totalSimulationsSent}
      initialTestWeeks={initialTestWeeks}
      initialTestsPerWeek={initialTestsPerWeek}
      interactions={interactions}
      usersInteraction={usersInteraction}
      testDates={testDates}
      testAssets={testAssets}
      selectedDateForTestTracker={selectedDateForTestTracker}
      onChangeSelectedDateForTestTracker={
        handleChangeSelectedDateForTestTracker
      }
      onClickInteraction={handleClickInteraction}
    />
  );
}

export default InsightsInitialTesting;
