import React from "react";

import { List } from "../../../../../components";

const StepTwo = () => {
  const steps = [
    {
      description: (
        <>
          In the side navigation panel, under the Manage section, select{" "}
          <span className="font-medium">App Registrations</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">New Registration</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Enter application name:{" "}
          <span className="font-medium">
            Dune Security Direct Mail Injection
          </span>
          .
        </>
      ),
    },
    {
      description: (
        <>
          For Account Types, select:{" "}
          <span className="font-medium">
            Accounts in any organizational directory (Any Microsoft Entra ID
            tenant - Multi-Tenant)
          </span>
          .
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Register</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div>
          <h4 className="font-medium onboarding-step">
            Create a New App Registration
          </h4>
        </div>
        <div className="mt-1">
          <List data={steps} keyName="description" textSize="onboarding-step" />
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
