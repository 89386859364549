import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  CustomIntegrationForm,
  CustomUploadForm,
  ImportantNotes,
} from "./utils";

import { postIntegrationDetails } from "../../../api";

import { Divider, FormActionSection, LoadingState } from "../../../components";

import {
  CrowdstrikeBrandMarkIcon,
  GoogleBrandMarkIcon,
  MicrosoftDefenderBrandMarkIcon,
  MicrosoftEntraIDBrandMarkIcon,
  OktaBrandMarkIcon,
  ProofpointBrandMarkIcon,
} from "../../../utils";

const servicesMap = {
  "microsoft-defender": {
    name: "Microsoft Defender",
    icon: <MicrosoftDefenderBrandMarkIcon />,
  },
  "microsoft-entra-id": {
    name: "Microsoft Entra ID",
    icon: <MicrosoftEntraIDBrandMarkIcon />,
  },
  crowdstrike: { name: "Crowdstrike", icon: <CrowdstrikeBrandMarkIcon /> },
  google: { name: "Google", icon: <GoogleBrandMarkIcon /> },
  okta: { name: "Okta", icon: <OktaBrandMarkIcon /> },
  proofpoint: { name: "Proofpoint", icon: <ProofpointBrandMarkIcon /> },
};

const ConfigureIntegrationsService = ({
  isInitialLoading,
  email,
  setNewTitle,
  setBreadcrumbs,
}) => {
  const { serviceID } = useParams();

  // Inputs
  const [inputs, setInputs] = useState([]);
  const [formValues, setFormValues] = useState({
    applicationID: "",
    tenantID: "",
    clientSecret: "",
  });
  const [selectedJSONKeyFile, setSelectedJSONKeyFile] = useState(null); // Custom for Google

  // Loading
  const [isLoading, setIsLoading] = useState(true); // Default is true.

  // Messages
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChangeFormValues = (event, id) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: event.target.value,
    }));
  };

  const handleClickUpdate = async () => {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (
      (serviceID === "crowdstrike" ||
        serviceID === "microsoft-defender" ||
        serviceID === "microsoft-entra-id" ||
        serviceID === "okta") &&
      !formValues.applicationID
    ) {
      setErrorMessage("Enter the Application ID.");
    } else if (
      (serviceID === "microsoft-defender" ||
        serviceID === "microsoft-entra-id" ||
        serviceID === "okta") &&
      !formValues.tenantID
    ) {
      setErrorMessage("Enter the Tenant ID.");
    } else if (
      (serviceID === "crowdstrike" ||
        serviceID === "microsoft-defender" ||
        serviceID === "microsoft-entra-id" ||
        serviceID === "okta") &&
      !formValues.clientSecret
    ) {
      setErrorMessage("Enter the Client Secret.");
    } else if (serviceID === "google" && !selectedJSONKeyFile) {
      setErrorMessage("Upload your JSON file.");
    } else if (serviceID === "google") {
      // Custom submission for Google
      await sendIntegrationDetails(selectedJSONKeyFile, serviceID);
    } else {
      const data = {};

      if (
        serviceID === "microsoft-defender" ||
        serviceID === "microsoft-entra-id" ||
        serviceID === "okta"
      ) {
        data.applicationID = formValues.applicationID;
        data.tenantID = formValues.tenantID;
        data.clientSecret = formValues.clientSecret;
      } else if (serviceID === "crowdstrike") {
        data.applicationID = formValues.applicationID;
        data.clientSecret = formValues.clientSecret;
      }

      // Convert the JSON data to a Blob.
      const jsonBlob = new Blob([JSON.stringify(data)], {
        type: "application/json",
      });

      await sendIntegrationDetails(jsonBlob, serviceID);
    }

    setIsLoading(false);
  };

  const sendIntegrationDetails = async (file, id) => {
    const postIntegrationDetailsResponse = await postIntegrationDetails(
      file,
      id,
    );

    if (Object.keys(postIntegrationDetailsResponse.error).length > 0) {
      console.error(postIntegrationDetailsResponse.error.message);
      setErrorMessage("An error has occurred. Please try again.");
    } else {
      console.log(postIntegrationDetailsResponse.result);
      setSuccessMessage(
        "Your integration details have been updated successfully.",
      );
    }
  };

  useEffect(() => {
    const serviceName = servicesMap[serviceID].name;
    const serviceIcon = servicesMap[serviceID].icon;

    const updateComponent = async () => {
      setIsLoading(true);

      const inputs = [];

      if (
        serviceID === "crowdstrike" ||
        serviceID === "microsoft-defender" ||
        serviceID === "microsoft-entra-id" ||
        serviceID === "okta"
      ) {
        // Application ID
        inputs.push({
          id: "applicationID",
          name: "Application ID",
        });
      }

      if (
        serviceID === "microsoft-defender" ||
        serviceID === "microsoft-entra-id" ||
        serviceID === "okta"
      ) {
        // Tenant ID
        inputs.push({
          id: "tenantID",
          name: "Tenant ID",
        });
      }

      if (
        serviceID === "crowdstrike" ||
        serviceID === "microsoft-defender" ||
        serviceID === "microsoft-entra-id" ||
        serviceID === "okta"
      ) {
        // Client Secret
        inputs.push({
          id: "clientSecret",
          name: "Client Secret",
        });
      }

      setInputs(inputs);

      setIsLoading(false);
    };

    // Update breadcrumbs.
    const timeout = setTimeout(() => {
      // Update breadcrumbs last.
      setNewTitle(
        <div className="flex items-center">
          <div className="w-[36px] mr-0.5">{serviceIcon}</div>
          <h2 className="h2">{serviceName}</h2>
        </div>,
      );

      setBreadcrumbs([
        {
          name: "Integrations",
          path: "/configure/integrations/",
        },
        {
          name: serviceName,
        },
      ]);
    }, 0);

    if (!isInitialLoading && email) {
      updateComponent();
    }

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceID, isInitialLoading, email, setBreadcrumbs, setNewTitle]);

  if (isLoading) {
    return <LoadingState message="Loading" />;
  }

  return (
    <div>
      {serviceID !== "google" ? (
        <CustomIntegrationForm
          inputs={inputs}
          onChangeValue={handleChangeFormValues}
        />
      ) : (
        // Custom upload form for Google
        <CustomUploadForm
          selectedFile={selectedJSONKeyFile}
          setSelectedFile={setSelectedJSONKeyFile}
        />
      )}
      <div className="my-4">
        <FormActionSection
          errorMessage={errorMessage}
          successMessage={successMessage}
          onSubmit={handleClickUpdate}
          buttonText="Update"
        />
      </div>
      <Divider />
      <ImportantNotes />
    </div>
  );
};

export default ConfigureIntegrationsService;
