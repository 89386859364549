import React, { Fragment, useState } from "react";

import {
  ButtonOutlinedGreen,
  Divider,
  Select,
  TextField,
} from "../../../../../components";

import { preferredLanguages } from "../../../../../utils";

const Profile = ({
  name,
  profileEmail,
  title,
  department,
  language,
  onChangeLanguage,
  onClickSaveProfile,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const inputFields = [
    {
      id: "name",
      name: "Name",
      description:
        "Details from your company's IDP. Request your company to change name if needed.",
      value: name,
      isDisabled: true,
      inputType: "textField",
    },
    {
      id: "email",
      name: "Email",
      description:
        "Details from your company's IDP. Request your company to change email if needed",
      value: profileEmail,
      isDisabled: true,
      inputType: "textField",
    },
    {
      id: "title",
      name: "Title",
      description:
        "Details from your company's IDP. Request your company to change title if needed.",
      value: title,
      isDisabled: true,
      inputType: "textField",
    },
    {
      id: "department",
      name: "Department",
      description:
        "Details from your company's IDP. Request your company to change department if needed.",
      value: department,
      isDisabled: true,
      inputType: "textField",
    },
    {
      id: "language",
      name: "Language",
      description: "Preferred Language",
      value: language,
      onChange: onChangeLanguage,
      isDisabled: false,
      inputType: "select",
    },
  ];

  return (
    <div className="mt-1.5">
      {inputFields.map((input, index) => (
        <Fragment key={`input-field-${index}`}>
          <div className="flex flex-col gap-1 py-2 md:gap-4 md:flex-row">
            <div className="flex flex-col gap-0.25 md:max-w-[300px] w-full">
              <div>
                <label className="body" for={`profile-${input.id}`}>
                  {input.name}
                </label>
              </div>
              <div>
                <p className="description">{input.description}</p>
              </div>
            </div>
            <div className="md:max-w-[320px] w-full">
              {input.inputType === "select" && (
                <Select
                  value={input.value}
                  options={[
                    { name: "", languageCode: "" },
                    ...preferredLanguages.map((language) => ({
                      name: `${language.name} ${language.flag}`,
                      languageCode: language.languageCode,
                    })),
                  ]}
                  onChange={input.onChange}
                />
              )}
              {input.inputType === "textField" && (
                <TextField value={input.value} isDisabled={input.isDisabled} />
              )}
            </div>
          </div>
          <Divider />
        </Fragment>
      ))}
      <div className="flex sm:justify-end sm:px-0.625 py-0.875 w-full">
        <div className="flex flex-col-reverse items-start gap-1 sm:gap-2 sm:items-center sm:flex-row">
          {errorMessage && (
            <p className="description text-red">{errorMessage}</p>
          )}
          {successMessage && !errorMessage && (
            <p className="description text-green">{successMessage}</p>
          )}
          <ButtonOutlinedGreen
            title="Save your profile settings."
            onClick={() =>
              onClickSaveProfile(setErrorMessage, setSuccessMessage)
            }
          >
            Save
          </ButtonOutlinedGreen>
        </div>
      </div>
    </div>
  );
};

export default Profile;
