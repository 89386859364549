import React from "react";

import {
  ButtonOutlinedGreen,
  CopyField,
  List,
} from "../../../../../components";

const StepSix = ({ secretToken, onLoadSecretToken }) => {
  const handleClickGenerateToken = () => {
    if (onLoadSecretToken) {
      onLoadSecretToken();
    }
  };

  const steps = [
    {
      description: "Go to the Provisioning tab.",
    },
    {
      description: "Edit the SCIM Connection.",
    },
    {
      description: (
        <>
          <p>Copy this into the SCIM connector base URL:</p>
          <div className="mt-0.25 w-full">
            <CopyField text="https://auth.dune.security/scim/v2" />
          </div>
        </>
      ),
    },
    {
      description: (
        <>
          Enter <span className="font-medium">email</span> as the unique
          identifier field for users.
        </>
      ),
    },
    {
      description: (
        <>
          Enable all{" "}
          <span className="font-medium">supported provisioning actions</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Select Authentication mode as{" "}
          <span className="font-medium">HTTP Header</span>.
        </>
      ),
    },
    {
      description: "Generate a token and paste it in Authorization.",
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">
          Configure API Integration
        </h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
      <div className="mt-1">
        {!secretToken ? (
          <ButtonOutlinedGreen
            title="Generate bearer token."
            onClick={handleClickGenerateToken}
          >
            Generate Token
          </ButtonOutlinedGreen>
        ) : (
          <>
            <p className="onboarding-step">Auth Bearer Token</p>
            <div className="mt-0.25 w-full onboarding-step">
              <CopyField text={secretToken} />
            </div>
          </>
        )}
      </div>
      <div className="mt-1">
        <p className="onboarding-step">
          Click <span className="font-medium">Test API Credentials</span> to
          verify the connection and save the configuration.
        </p>
      </div>
    </div>
  );
};

export default StepSix;
