import React from "react";

import { List } from "../../../../../components";

const StepFive = () => {
  const steps = [
    {
      description: (
        <>
          Go to <span className="font-medium">IAM & Admin</span> {">"}{" "}
          <span className="font-medium">Service Accounts</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Create Service Account</span> and
          provide name and description.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Create</span> and{" "}
          <span className="font-medium">Continue</span>, then assign Service
          Account Admin and Service Account User roles.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Done</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">
          Create a Service Account in Google Cloud
        </h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepFive;
