import React, { useCallback, useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

import { CertificateGenerator } from "../";
import TrainingVideo from "./utils/training-video/training-video";
import { formatDateFive } from "../../../utils/helper-functions/formatDate";

import {
  getTrainings,
  postTrainingCompleted,
  getUser,
  postKnowledgeCheck,
} from "../../../api";

import {
  ButtonContainedGreen,
  ButtonOutlinedGreen,
  LoadingSpinner,
  TitleWithBadge,
} from "../../../components";

import {
  CheckmarkWithCircleIcon,
  PlayIcon,
  TrainingVideoActive,
  TrainingVideoInactive,
  TrainingVideoLocked,
  TrainingVideoTimeEstimate,
} from "../../../utils/icons";

import { TrainingVideoCard } from "../trainings/utils";
import { getThumbnailFileNameOld } from "../../../utils/helper-functions/training-thumbnail-files";
import {
  complianceFrameworks,
  SCREENS,
} from "../../../utils/constants/platform/trainings";

function TrainingsVideoPlayer({ isInitialLoading, email, setNewTitle }) {
  const { pathname, state } = useLocation();
  const isComplianceVideo = pathname.includes("compliance");
  const assignedTraining = state?.assignedTraining || [];

  // Training Video Data
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [modules, setModules] = useState([]);
  const [category, setCategory] = useState(null);

  // Training Video State
  const [isClickedTakeQuiz, setIsClickedTakeQuiz] = useState(false);
  const [isComplianceModulesExisting, setIsComplianceModulesExisting] =
    useState(false);
  const [isCompletedModulesExisting, setIsCompletedModulesExisting] =
    useState(false);
  const [isQuizLocked, setIsQuizLocked] = useState(true);

  // User Data
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");

  // Training Flow & Quiz State
  const [viewedInfographic, setViewedInfographic] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("videoPlayer");
  const [answerLength, setAnswerLength] = useState(0);
  const [response, setResponse] = useState("");
  const [tips, setTips] = useState([]);
  const [count, setCount] = useState(10);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipIndex, setShowTooltipIndex] = useState(null);

  const demoResponse =
    "I learned that domain spoofing can trick me into thinking fake emails are from trusted sources. I will watch out for unusual email domains, misspelled domain names, unexpected attachments or links, and emails urging urgent action. I'll also always verify the sender's email address.";

  const isCertificateLocked = modules.every((module) =>
    module.status === "completed" ? true : false,
  );

  const handleClickModule = (module) => {
    setSelectedVideo(module);
    setIsClickedTakeQuiz(false);
    setIsQuizLocked(module.status !== "in progress");
    navigateTo(SCREENS.VIDEO_PLAYER);
  };

  const handleClickTakeQuiz = () => {
    if (!isClickedTakeQuiz) {
      setIsClickedTakeQuiz(true);
    }
    if (!isQuizLocked) {
      navigateTo(SCREENS.QUIZ);
    }
  };

  const handleEndedVideo = async () => {
    if (selectedVideo.status !== "completed") {
      if (
        complianceFrameworks.some(
          (complianceFramework) =>
            complianceFramework.name === selectedVideo.category,
        )
      ) {
        const requestBody = {
          training_id: selectedVideo.id,
        };

        const videoTrainingCompletion =
          await postTrainingCompleted(requestBody);

        if (Object.keys(videoTrainingCompletion.error).length > 0) {
          console.error(videoTrainingCompletion.error.message);
        } else {
          const updatedSelectedVideo = { ...selectedVideo };
          updatedSelectedVideo.status = "completed";
          setSelectedVideo(updatedSelectedVideo);

          !isCertificateLocked
            ? navigateTo(SCREENS.NEXT_COMPLIANCE_VIDEO)
            : navigateTo(SCREENS.COMPLIANCE_COMPLETION);
          setViewedInfographic(true);

          const updatedModules = [...modules];
          const selectedVideoIndex = updatedModules.findIndex(
            (module) => module.id === selectedVideo.id,
          );

          if (selectedVideoIndex !== -1) {
            updatedModules[selectedVideoIndex].status = "completed";
          }

          setModules(updatedModules);
          !isCertificateLocked
            ? navigateTo(SCREENS.NEXT_COMPLIANCE_VIDEO)
            : navigateTo(SCREENS.COMPLIANCE_COMPLETION);
        }
      } else {
        if (isComplianceVideo) {
          setViewedInfographic(true);
        } else {
          setIsQuizLocked(false);
        }
        setViewedInfographic(true);
        setCurrentScreen(SCREENS.TRAINING_COMPLETION);
      }
    } else {
      if (isComplianceVideo) {
        setViewedInfographic(true);
      } else {
        setIsQuizLocked(false);
      }
      setViewedInfographic(true);
      isCertificateLocked
        ? navigateTo(SCREENS.COMPLIANCE_COMPLETION)
        : navigateTo(SCREENS.NEXT_COMPLIANCE_VIDEO);
    }
  };

  const loadTrainings = useCallback(
    async (isFirstTime = false) => {
      const trainings = await getTrainings();
      if (!trainings.error) {
        const { learningModules, complianceModules, completedModules } =
          trainings.result;
        if (isComplianceVideo) {
          if (!complianceModules?.length) {
            setIsComplianceModulesExisting(true);
          } else {
            setModules(complianceModules[0].videos);
            if (isFirstTime) {
              setSelectedVideo(complianceModules[0].videos[0]);
              setCategory(`${complianceModules[0].name} compliance`);
              setIsQuizLocked(
                complianceModules[0].videos[0].status !== "in progress",
              );
            }
          }
        } else if (pathname.includes("completed")) {
          if (!completedModules?.length) {
            setIsCompletedModulesExisting(true);
          } else {
            setModules(completedModules);
            if (isFirstTime) {
              setSelectedVideo(completedModules[0]);
              setCategory("Completed modules");
              setIsQuizLocked(completedModules[0].status !== "in progress");
            }
          }
        } else {
          setModules(learningModules);
          if (isFirstTime) {
            setSelectedVideo(learningModules[0]);
            setCategory("Learning modules");
            setIsQuizLocked(learningModules[0].status !== "in progress");
          }
        }
      }
    },
    [isComplianceVideo, pathname],
  );

  const handleClickViewInfographic = () => {
    setIsQuizLocked(false);
    setCurrentScreen("infographic");
    setViewedInfographic(true);
  };

  const handleDisablePaste = (e) => {
    e.preventDefault();
  };

  const navigateTo = (screen) => {
    setCurrentScreen(screen);
  };

  const handleInputChange = (e) => {
    setAnswerLength(e.target.value.length);
    setResponse(e.target.value);
  };

  const handleClickSubmitResponse = async (event) => {
    event.preventDefault();

    if (email === "michael.waite@dune.demo") {
      // Demo account
      navigateTo(SCREENS.CHECKING_RESPONSE);

      setTimeout(() => {
        navigateTo(SCREENS.COMPLETION);
      }, 2000);

      if (response === demoResponse) {
        navigateTo(SCREENS.COMPLETION);
      } else {
        setTips([
          "Review the concept of spear phishing and how it differs from regular phishing.",
          "Understand the importance of verifying unexpected or suspicious emails through alternative means.",
          "Remember the key strategies to counter spear phishing attacks.",
          "Remember the significance of reporting any suspected phishing attempts to the IT team promptly.",
        ]);
      }
    } else {
      if (response) {
        navigateTo(SCREENS.CHECKING_RESPONSE);

        const requestBody = {
          user_answer: response,
          assigned_training_id: selectedVideo.id,
        };

        const knowledgeCheck = await postKnowledgeCheck(requestBody);

        if (
          knowledgeCheck &&
          knowledgeCheck.result &&
          knowledgeCheck.result.understanding_score !== null
        ) {
          if (knowledgeCheck.result.understanding_score >= 6) {
            setResponse("");
            navigateTo(SCREENS.COMPLETION);
          } else {
            setResponse("");
            setTips(knowledgeCheck.result.tips);
            navigateTo(SCREENS.QUIZ_FAILURE);
          }
        }
      } else {
        return;
      }
    }
  };

  const handleClickRetakeQuiz = () => {
    setResponse("");
    navigateTo(SCREENS.QUIZ);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getUser();

      setUserFirstName(userInfo.result.firstName);
      setUserLastName(userInfo.result.lastName);
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (!isInitialLoading && email) {
      if (state) {
        setSelectedVideo(state.selectedVideo);
        setModules(state.modules);
        setCategory(state.category);
        setIsQuizLocked(state.selectedVideo.status !== "in progress");
        if (email === "michael.waite@dune.demo") {
          // Demo account
          setIsQuizLocked(false);
        } else {
          setIsQuizLocked(state.selectedVideo.status !== "in progress");
        }
      } else {
        if (email === "michael.waite@dune.demo") {
          const modules = [
            {
              id: "0",
              status: "Not started",
              title: "Domain Spoofing",
              fileName: "domain-spoofing.mp4",
              startDate: "2024-06-18T00:00:00",
            },
            {
              id: "1",
              status: "Not started",
              title: "Malicious Attachments",
              fileName: "malicious-attachments.mp4",
              startDate: "2024-06-18T00:00:00",
            },
            {
              id: "2",
              status: "Not started",
              title: "Business Email Compromise",
              fileName: "business-email-compromise.mp4",
              startDate: "2024-06-18T00:00:00",
            },
          ];

          setSelectedVideo(modules[0]);
          setModules(modules);
          setCategory("Learning modules");
          setIsQuizLocked(false);
        } else {
          loadTrainings(true);
        }
      }
    }
  }, [isInitialLoading, email, loadTrainings, state]);

  useEffect(() => {
    if (selectedVideo.title) {
      setNewTitle(<TitleWithBadge title={selectedVideo.title} />);
    }
  }, [selectedVideo, setNewTitle]);

  useEffect(() => {
    if (currentScreen === SCREENS.NEXT_COMPLIANCE_VIDEO && count > 0) {
      setTimeout(() => setCount(count - 1), 1000);
    }

    if (count === 0) {
      setCount(10);
      handleClickModule(
        modules.find((module) => module.status === "not started"),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, currentScreen, modules]);

  if (isComplianceModulesExisting || isCompletedModulesExisting) {
    return <Navigate to="/error/" />;
  }

  const renderScreen = () => {
    switch (currentScreen) {
      case SCREENS.VIDEO_PLAYER:
        return (
          <TrainingVideo
            assignedTraining={assignedTraining}
            selectedVideo={selectedVideo}
            pathname={pathname}
            onEndedVideo={handleEndedVideo}
          />
        );

      case SCREENS.TRAINING_COMPLETION:
        return (
          <div className="relative text-white flex justify-center items-center h-full w-full">
            <div
              className="absolute inset-0 bg-[url('https://d3oo9a669kwmx7.cloudfront.net/training-video-flow-bg.png')] bg-cover bg-center"
              style={{ opacity: 0.25 }}
            ></div>
            <div className="relative z-10 text-center">
              <div className="mb-6.25">
                <div className="text-h6 mb-0.5">Congratulations!</div>
                <div className="text-h4">You completed the training!</div>
              </div>

              <div className="flex justify-center items-center space-x-2">
                <ButtonOutlinedGreen onClick={handleClickViewInfographic}>
                  View Infographic
                </ButtonOutlinedGreen>
                <ButtonContainedGreen onClick={() => navigateTo(SCREENS.QUIZ)}>
                  Take Quiz
                </ButtonContainedGreen>
              </div>
            </div>
          </div>
        );

      case SCREENS.COMPLIANCE_COMPLETION:
        return (
          <div className="relative text-white flex justify-center items-center h-full w-full">
            <div
              className="absolute inset-0 bg-[url('https://d3oo9a669kwmx7.cloudfront.net/training-video-flow-bg.png')] bg-cover bg-center"
              style={{ opacity: 0.25 }}
            ></div>
            <div className="relative z-10 text-center">
              <div className="mb-6.25">
                <div className="text-h6 mb-0.5">Congratulations!</div>
                <div className="text-h4">
                  You completed the training module!
                </div>
              </div>

              <div className="flex justify-center items-center space-x-2">
                <ButtonContainedGreen
                  onClick={() => navigateTo(SCREENS.CERTIFICATE)}
                >
                  View Certificate
                </ButtonContainedGreen>
              </div>
            </div>
          </div>
        );

      case SCREENS.CERTIFICATE:
        return (
          <>
            <div>
              <CertificateGenerator
                firstName={userFirstName}
                lastName={userLastName}
                trainingName={selectedVideo.category}
                date={formatDateFive(selectedVideo.completionDate)}
              />
            </div>
          </>
        );

      case SCREENS.INFOGRAPHIC:
        return (
          <div className="text-white h-full w-full bg-gray rounded-medium">
            <div className="flex flex-col justify-between items-center h-full w-full">
              <div className="flex justify-center items-center h-full w-full text-black">
                <img
                  src={`https://d1s4qst3dpk22c.cloudfront.net/${selectedVideo.infographic}`}
                  alt={selectedVideo.title}
                />
              </div>
              {!isComplianceVideo && (
                <div className="flex justify-end items-center py-0.625 px-1.125 bg-gray-dark bg-opacity-25 w-full">
                  <ButtonContainedGreen onClick={handleClickTakeQuiz}>
                    Take Quiz
                  </ButtonContainedGreen>
                </div>
              )}
            </div>
          </div>
        );

      case SCREENS.QUIZ:
        return (
          <form className="text-white w-[80%]">
            <div className="flex justify-between items-center">
              <div className="text-h6">
                Complete the quiz to finish your training.
              </div>
              <div className="flex justify-center items-center space-x-2.25">
                <ButtonOutlinedGreen
                  onClick={() => {
                    setCurrentScreen("videoPlayer");
                    setResponse("");
                  }}
                >
                  Back to Video
                </ButtonOutlinedGreen>
                <ButtonOutlinedGreen
                  onClick={() => setCurrentScreen("infographic")}
                >
                  View Infographic
                </ButtonOutlinedGreen>
              </div>
            </div>
            <div className="mt-1.75 text-h6">
              Give 2-3 points about what you learned in the training.
            </div>
            <div className="mt-2">
              <textarea
                className="bg-transparent border border-gray border-opacity-35 rounded-medium w-full resize-none overflow-auto p-1 focus:outline-none focus:border-white focus:border-opacity-35"
                rows={8}
                maxLength={400}
                onChange={handleInputChange}
                onPaste={handleDisablePaste}
                name="response"
                value={response}
              ></textarea>
            </div>
            <div>
              <span>{answerLength}</span>/400 characters
            </div>
            <div className="flex justify-end mt-0.5">
              <ButtonContainedGreen
                disabled={answerLength === 0}
                onClick={handleClickSubmitResponse}
              >
                Submit
              </ButtonContainedGreen>
            </div>
          </form>
        );

      case SCREENS.CHECKING_RESPONSE:
        return (
          <div className="flex justify-center items-center w-full h-full text-white text-center">
            <div>
              <div className="flex justify-center">
                <LoadingSpinner stroke="#00FF82" />
              </div>
              <div>Checking your response</div>
            </div>
          </div>
        );

      case SCREENS.QUIZ_FAILURE:
        return (
          <div className="text-white flex justify-center items-center h-full w-full text-center">
            <div>
              <div className="text-h2">Almost there!</div>

              <div className="text-left mt-2">
                <ol className="list-decimal list-inside">
                  {tips
                    ? tips.map((tip, index) => (
                        <>
                          <div className="text-h5 mt-1">
                            Here's a few reminders
                          </div>
                          <li key={`tip-${index}`}>{tip}</li>
                        </>
                      ))
                    : null}
                </ol>
              </div>
              <div className="flex justify-center space-x-2 mt-2">
                <ButtonOutlinedGreen
                  onClick={() => setCurrentScreen("videoPlayer")}
                >
                  Back to Video
                </ButtonOutlinedGreen>
                <ButtonContainedGreen onClick={handleClickRetakeQuiz}>
                  Retake Quiz
                </ButtonContainedGreen>
              </div>
            </div>
          </div>
        );

      case SCREENS.COMPLETION:
        return (
          <div className="text-white flex justify-center items-center w-full h-full text-center">
            <div>
              <div className="text-h3">Congratulations!</div>
              <div className="text-h5 mt-1">
                You&apos;ve successfully completed the quiz
              </div>

              <div className="text-h5 mt-4">
                Complete more trainings to reduce your risk score further
              </div>

              <div className="mt-1">
                <ButtonContainedGreen>
                  Next Training Module
                </ButtonContainedGreen>
              </div>
            </div>
          </div>
        );

      case SCREENS.NEXT_COMPLIANCE_VIDEO:
        const nextComplianceVideo = modules.find(
          (module) => module.status === "not started",
        );

        const thumbnailUrl = nextComplianceVideo
          ? getThumbnailFileNameOld(nextComplianceVideo, isComplianceVideo)
          : null;

        return (
          <div className="relative flex justify-center items-center h-full rounded-medium">
            <div
              className="absolute inset-0 -z-10 rounded-medium"
              style={{
                backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: 0.2,
              }}
            ></div>
            <div className="text-white text-center z-10">
              <div className="text-h6 mb-0.5">Up next</div>
              <div className="text-h4">
                {nextComplianceVideo && nextComplianceVideo.title}
              </div>

              <button
                className="p-0.625 rounded-full training-video-icon-button-play mt-1.5 mb-0.5"
                aria-label={`Play the video: ${nextComplianceVideo.title}`}
                title={`Play the video: ${nextComplianceVideo.title}`}
                onClick={() => {
                  handleClickModule(nextComplianceVideo);
                }}
              >
                <PlayIcon />
              </button>
              <div className="text-h4">
                Playing in <span>{count}</span>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className="flex justify-center items-center w-full h-full text-white text-center">
            <div>
              <div className="text-h4 mb-2">Something went wrong!</div>
              <Link to="/dashboard/trainings/">
                <ButtonContainedGreen>Go to trainings</ButtonContainedGreen>
              </Link>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="mt-0.625">
      <div className="flex flex-col">
        {selectedVideo && (
          <div>
            <div className="flex justify-between items-stretch space-x-4">
              <div className="min-h-[600px] min-w-4/5 w-full">
                {renderScreen()}
              </div>
              <div className="border border-gray border-opacity-35 rounded-medium min-w-15 w-1/5">
                <div className="flex flex-col justify-between h-full">
                  <div>
                    <div className="uppercase text-gray px-1 pt-0.75 pb-0.5 tracking-[1.6px] font-medium border-b border-gray border-opacity-35">
                      Get Started
                    </div>
                    {isComplianceVideo ? (
                      <div className="flex items-center space-x-0.5 px-1">
                        <div className="text-h6 text-white">
                          {modules && modules.length > 0 && category && (
                            <div className="mt-0.625">
                              {modules.map((module, index) => (
                                <div
                                  className={`flex items-center cursor-pointer mt-1 ${
                                    index === 0 ||
                                    modules[index - 1]?.status === "completed"
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed"
                                  }`}
                                  key={`modules-list-item-${index}`}
                                  onClick={
                                    index === 0 ||
                                    modules[index - 1]?.status === "completed"
                                      ? () => handleClickModule(module)
                                      : null
                                  }
                                >
                                  {module.status === "completed" ? (
                                    <div className="w-[24px] h-[24px]">
                                      <CheckmarkWithCircleIcon />
                                    </div>
                                  ) : index === 0 ||
                                    modules[index - 1]?.status ===
                                      "completed" ? (
                                    <div className="w-[24px] h-[24px]">
                                      <TrainingVideoActive />
                                    </div>
                                  ) : (
                                    <div className="w-[24px] h-[24px]">
                                      <TrainingVideoLocked />
                                    </div>
                                  )}
                                  <div className="w-full ml-0.625 relative">
                                    <p
                                      className="description"
                                      onMouseEnter={() => {
                                        index > 0 && setShowTooltipIndex(index);
                                      }}
                                      onMouseLeave={() => {
                                        setShowTooltipIndex(null);
                                      }}
                                    >
                                      {module.title}
                                    </p>
                                    {module.status === "not started" &&
                                      showTooltipIndex === index && (
                                        <div
                                          className="absolute top-full left-1/2 mt-[10px] transform -translate-x-1/2 border border-border-color description py-[4px] px-[8px] z-50 bg-gray-tooltip max-w-[220px] w-max rounded-small"
                                          style={{
                                            boxShadow:
                                              "0px 2px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                                          }}
                                        >
                                          Please watch the previous module to
                                          unlock.
                                        </div>
                                      )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-0.5 px-1">
                        <div className="text-h6 text-white">
                          {selectedVideo && (
                            <div className="mt-1">
                              <div
                                className="flex items-center cursor-pointer"
                                onClick={() => navigateTo(SCREENS.VIDEO_PLAYER)}
                              >
                                {selectedVideo.status === "in progress" ? (
                                  <div className="w-[24px] h-[24px]">
                                    <TrainingVideoInactive />
                                  </div>
                                ) : selectedVideo.status === "completed" ? (
                                  <div className="w-[24px] h-[24px]">
                                    <CheckmarkWithCircleIcon />
                                  </div>
                                ) : (
                                  <div className="w-[24px] h-[24px] mt-0.313">
                                    <TrainingVideoActive />
                                  </div>
                                )}
                                <div className="w-full ml-1 text-h6">
                                  <p className="description">
                                    {selectedVideo.title}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {!isComplianceVideo && (
                      <div
                        className="flex items-center space-x-1 px-1 mt-1"
                        onClick={() => navigateTo(SCREENS.INFOGRAPHIC)}
                      >
                        <div className="mt-0.125">
                          {viewedInfographic ? (
                            <TrainingVideoActive />
                          ) : (
                            <TrainingVideoInactive />
                          )}
                        </div>
                        <div className="text-h6 text-white cursor-pointer">
                          <div>Infographic</div>
                        </div>
                      </div>
                    )}
                    {isComplianceVideo ? (
                      <div
                        className={`flex items-center space-x-1 px-1 mt-1 ${!isCertificateLocked ? "cursor-default" : "cursor-pointer"}`}
                      >
                        <div>
                          {!isCertificateLocked ? (
                            <TrainingVideoLocked />
                          ) : (
                            <div className="w-[20px] h-[20px]">
                              <CheckmarkWithCircleIcon />
                            </div>
                          )}
                        </div>
                        <div className="relative flex items-center text-white">
                          <div
                            className="text-h6"
                            onMouseEnter={() => {
                              !isCertificateLocked && setShowTooltip(true);
                            }}
                            onMouseLeave={() => setShowTooltip(false)}
                            onClick={() => {
                              isCertificateLocked &&
                                navigateTo(SCREENS.CERTIFICATE);
                            }}
                          >
                            Certificate of Completion
                          </div>
                          {showTooltip && (
                            <div
                              className="absolute top-full left-1/2 mt-[10px] transform -translate-x-1/2 border border-border-color description py-[4px] px-[8px] z-50 bg-gray-tooltip max-w-[300px] w-max rounded-small"
                              style={{
                                boxShadow:
                                  "0px 2px 12px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
                              }}
                            >
                              Watch all videos to unlock your certificate
                            </div>
                          )}
                        </div>
                        <div className="text-h6 text-white"></div>
                      </div>
                    ) : (
                      <div
                        className={`flex items-center space-x-1 px-1 mt-1 ${isQuizLocked ? "cursor-default" : "cursor-pointer"}`}
                      >
                        <div className="mt-0.125">
                          {isQuizLocked ? (
                            <TrainingVideoLocked />
                          ) : (
                            <TrainingVideoActive />
                          )}
                        </div>
                        <div
                          className={`text-h6 text-white `}
                          onClick={handleClickTakeQuiz}
                        >
                          <div>Quiz</div>
                          <div className="text-gray text-h6.5 flex items-center space-x-0.313">
                            <div>
                              <TrainingVideoTimeEstimate />
                            </div>
                            <div>~3 mins</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="text-white text-left px-1 pb-0.5">
                    {isComplianceVideo ? (
                      <div>
                        Please watch all videos to unlock your certificate.
                      </div>
                    ) : (
                      <div>Finish training to take quiz</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {modules && modules.length > 0 && !category.includes("Compliance") && (
        <div className="text-white mt-5">
          <div className="text-h3 mb-1">Your Assigned Training Modules</div>
          <div>
            <div>
              <div className="mt-0.5 grid grid-cols-4 gap-2">
                {modules.map((module, index) => (
                  <div className="">
                    <TrainingVideoCard
                      padding="p-0"
                      imgSrc={getThumbnailFileNameOld(
                        module,
                        isComplianceVideo,
                      )}
                      title={module.title}
                      trainingType={
                        isComplianceVideo
                          ? "Compliance Modules"
                          : "Functional Training"
                      }
                      handleClickModule={() => handleClickModule(module)}
                      completionTime={"3"}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TrainingsVideoPlayer;
