const getRiskScoreOverTimeDecreasingWithScoreChange = (riskScore) => {
  let riskScoreOverTime = [];

  const currentDate = new Date(); // Start with today's date.
  currentDate.setDate(currentDate.getDate() - 90);

  let currentRiskScore = 90;
  const targetScore = riskScore;
  const totalDays = 90;

  const options = {
    month: "short",
    day: "numeric",
  };

  for (let i = 0; i < totalDays; i++) {
    const formattedDate = currentDate.toLocaleDateString("en-US", options);

    let stepChange = (currentRiskScore - targetScore) / (totalDays - i);

    let variability = (Math.random() - 0.5) * stepChange;

    let fluctuation = Math.sin(((2 * Math.PI) / totalDays) * i) * stepChange;

    currentRiskScore -= stepChange + variability + fluctuation;

    if (i === totalDays - 1 && currentRiskScore > targetScore) {
      currentRiskScore = targetScore;
    } else if (currentRiskScore < targetScore) {
      currentRiskScore = targetScore + Math.random();
    }

    riskScoreOverTime.push({
      date: formattedDate,
      riskScore: Math.round(currentRiskScore),
    });

    currentDate.setDate(currentDate.getDate() + 1);
  }

  let scoreChange = 0;

  if (riskScoreOverTime[0]) {
    scoreChange = riskScore - riskScoreOverTime[0].riskScore;
  }

  return {
    riskScoreOverTime,
    scoreChange,
  };
};

const getRiskScoreDataSandbox = () => {
  const riskScore = 39;
  const { riskScoreOverTime, scoreChange } =
    getRiskScoreOverTimeDecreasingWithScoreChange(riskScore);

  return {
    riskScore,
    riskScoreOverTime,
    scoreChange,
  };
};

export default getRiskScoreDataSandbox;
