import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonContainedGreen,
  ButtonOutlinedGreen,
  CardWithLoadingState,
  DataTable,
  List,
} from "../../../components";
import { getUsers } from "../../../api";

const headers = [
  {
    id: "name",
    label: "Name",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
  {
    id: "department",
    label: "Department",
    isSortable: true,
    isSearchable: true,
    align: "left",
  },
];

const PostSetupVerification = ({ isInitialLoading, email }) => {
  const navigate = useNavigate();

  const [users, setUsers] = useState(null);

  const handleClickBackToChecklist = () => {
    navigate("/onboarding/");
  };

  const handleClickSetupDMI = () => {
    navigate("/onboarding/setup-dmi/");
  };

  const stepsTest = [
    {
      description:
        "Ensure users can log in to Dune Security using Google Workspace SSO by navigating to the Dune Security login page.",
    },
  ];

  const stepsVerify = [
    {
      description:
        "Ensure users and groups from Microsoft Entra are correctly provisioned.",
    },
  ];

  useEffect(() => {
    const loadUsers = async () => {
      const usersData = await getUsers();

      if (Object.keys(usersData.error).length > 0) {
        console.error(usersData.error.message);
      } else {
        const users = usersData.result;

        setUsers(users);
      }
    };

    if (!isInitialLoading && email) {
      loadUsers();
    }
  }, [isInitialLoading, email]);

  return (
    <div className="flex flex-col lg:flex-row-reverse">
      <div className="lg:max-w-[440px] w-full pt-2 pb-1.5 border-l border-border-color">
        <div className="flex flex-col justify-between h-full">
          <div className="pl-2 pr-2">
            <div className="flex justify-between">
              <h2 className="h2">Post Setup Verification</h2>
            </div>
            <div className="mt-2 pt-4.75 pb-2">
              <div>
                <h4 className="font-medium onboarding-step">Test SSO Login</h4>
              </div>
              <div className="mt-1">
                <List
                  data={stepsTest}
                  keyName="description"
                  textSize="onboarding-step"
                />
              </div>
              <div className="mt-2">
                <h4 className="font-medium onboarding-step">
                  Verify User Provisioning
                </h4>
              </div>
              <div className="mt-1">
                <List
                  data={stepsVerify}
                  keyName="description"
                  textSize="onboarding-step"
                />
              </div>
            </div>
          </div>
          <div>
            <div className={`border-t-2 border-green`}></div>
            <div
              className="flex items-center gap-2 pt-1.5 px-2"
              style={{ borderTop: "1px solid rgba(0, 248, 127, 0.30)" }}
            >
              <ButtonOutlinedGreen onClick={handleClickBackToChecklist}>
                Back to checklist
              </ButtonOutlinedGreen>
              <ButtonContainedGreen onClick={handleClickSetupDMI}>
                Setup DMI
              </ButtonContainedGreen>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full h-full min-h-[calc(100vh-69px)] pt-2 pl-4 pr-2">
        <div>
          <h3 className="h3">Provisioned Users</h3>
        </div>
        <div className="flex mt-2">
          <CardWithLoadingState loadingVariable={users}>
            <div className="flex justify-between w-full">
              <div>
                <p className="h2">
                  {users ? users.length.toLocaleString("en-US") : 0}
                </p>
                <p className="body mt-0.75">
                  User
                  {users === null ||
                    (users !== null && users.length !== 1 && "s")}{" "}
                  Provisioned
                </p>
              </div>
            </div>
            <div className="mt-2">
              <DataTable
                data={users}
                headers={headers}
                rowsPerPage={5}
                defaultOrderBy="name"
                disableFilters
              />
            </div>
          </CardWithLoadingState>
        </div>
      </div>
    </div>
  );
};

export default PostSetupVerification;
