import React from "react";

import { ButtonOutlinedGray } from "../buttons";

import { SearchIcon } from "../../../utils/icons";

const TextField = ({
  value,
  onChangeValue,
  placeholder = "",
  maxLength,
  multiline = false,
  rows = 3, // This is used only for multiline
  isSearch,
  isDisabled = false,
}) => {
  const handleChangeValue = (event) => {
    if (onChangeValue) {
      onChangeValue(event);
    }
  };

  const commonClassNames = `py-[10px] px-[8px] w-full description bg-gray-select
    ${isDisabled ? "text-gray-surface-secondary" : ""}
    placeholder-white placeholder-opacity-50 border-border-color
    ${isSearch ? "border-b border-t border-l rounded-tl-small rounded-bl-small" : "border rounded-small"}
    focus:ring-0 focus:outline-none focus:border-green focus:border focus:rounded-small`;

  return (
    <div className="flex w-full">
      {multiline ? (
        <textarea
          className={`${commonClassNames} resize-none`}
          rows={rows}
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={handleChangeValue}
        />
      ) : (
        <input
          type="text"
          className={commonClassNames}
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          autoComplete="off"
          onChange={handleChangeValue}
          disabled={isDisabled}
        />
      )}
      {isSearch && (
        <ButtonOutlinedGray padding="p-0.5" isEndButton>
          <SearchIcon />
        </ButtonOutlinedGray>
      )}
    </div>
  );
};

export default TextField;
