import React, { useState } from "react";

const Autocomplete = ({ values = [], setValues, placeholder = "" }) => {
  const [inputValue, setInputValue] = useState("");

  const handleBlurValue = (event) => {
    const value = event.target.value;

    if (value.trim() && !values.includes(value.trim()) && setValues) {
      setValues([...values, value.trim()]);
      setInputValue("");
    }
  };

  const handleChangeValue = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDownValue = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      // Avoid duplicates.
      if (!values.includes(inputValue.trim()) && setValues) {
        setValues([...values, inputValue.trim()]);
        setInputValue("");
      }
    }
  };

  const handleRemoveValue = (indexToRemove) => {
    if (setValues) {
      setValues(values.filter((_, index) => index !== indexToRemove));
    }
  };

  return (
    <div className="w-full">
      <div className="relative">
        <div className="flex flex-wrap min-h-[40px] gap-x-0.5 gap-y-0.25 py-0.25 px-0.5 border rounded-small border-border-color">
          {values.map((domain, index) => (
            <span
              key={index}
              className="py-0.375 px-0.5 flex items-center bg-gray-card rounded-full cursor-pointer description"
              onClick={() => handleRemoveValue(index)}
            >
              {domain}
              <button
                type="button"
                className="ml-1 text-red"
                onClick={() => handleRemoveValue(index)}
              >
                &times;
              </button>
            </span>
          ))}
          <input
            type="text"
            value={inputValue}
            placeholder={placeholder}
            className="bg-transparent outline-none description"
            onBlur={handleBlurValue}
            onChange={handleChangeValue}
            onKeyDown={handleKeyDownValue}
          />
        </div>
      </div>
    </div>
  );
};

export default Autocomplete;
