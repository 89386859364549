import React from "react";

const PlayArrowFilledIcon = () => {
  return (
    <svg
      width="92"
      height="92"
      viewBox="0 0 92 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.09129"
        y="2.09129"
        width="87.8174"
        height="87.8174"
        rx="43.9087"
        stroke="#47FFA5"
        stroke-width="4.18257"
      />
      <path
        d="M67.2883 43.9055C70.0639 45.5081 70.0639 49.5143 67.2883 51.1169L32.9398 70.948C30.1641 72.5506 26.6946 70.5475 26.6946 67.3425L26.6946 27.6802C26.6946 24.4751 30.1641 22.472 32.9398 24.0745L67.2883 43.9055Z"
        fill="#47FFA5"
      />
    </svg>
  );
};

export default PlayArrowFilledIcon;
