export const getThumbnailFileNameOld = (module, isCompliance = false) => {
  if (!(module && module.fileName)) {
    return "";
  }

  let extension = ".jpg"; // Default is .jpg.

  if (module.startDate) {
    const dateUpdate = new Date("2024-06-04T00:00:00");
    const dateModule = new Date(module.startDate);

    if (dateModule < dateUpdate) {
      extension = ".png";
    }
  }

  return `https://d36cattz2ccgpt.cloudfront.net/${
    !isCompliance ? "general/" : ""
  }${module.fileName.slice(0, -4)}${extension}`;
};

export const getThumbnailFileName = (module) => {
  if (!(module && module.fileNameThumbnail)) {
    return "";
  }

  return `https://d36cattz2ccgpt.cloudfront.net/general/${module.fileNameThumbnail}`;
};
