import { ADMIN_TRAINING_STATUSES } from "./admin-training-statuses";

const getAdminUsersByTrainingStatus = (givenUsers) => {
  if (!givenUsers || givenUsers.length === 0) {
    return [];
  }

  const trainingStatuses = ADMIN_TRAINING_STATUSES;

  // Remove double occurrence of Michael Waite demo account.
  const usersDemoEndUser = givenUsers.filter(
    (user) => user.email === "michael.waite@dune.demo",
  );
  const usersDemoManager = givenUsers.filter(
    (user) => user.email === "aaron.chavez@dune.demo",
  );
  let updatedUsers = givenUsers.filter(
    (user) =>
      user.email !== "michael.waite@dune.demo" &&
      user.email !== "aaron.chavez@dune.demo",
  );

  if (usersDemoEndUser.length > 0) {
    usersDemoEndUser[0].riskScore = 97;
    usersDemoEndUser[0].compliant = false;

    updatedUsers = [...updatedUsers, usersDemoEndUser[0]];
  }

  if (usersDemoManager.length > 0) {
    usersDemoManager[0].riskScore = 14;
    usersDemoManager[0].compliant = true;
    usersDemoManager[0].role = "Chief Financial Officer";

    updatedUsers = [...updatedUsers, usersDemoManager[0]];
  } else {
    updatedUsers = [
      {
        firstName: "Aaron",
        lastName: "Chavez",
        initials: "AC",
        email: "aaron.chavez@dune.demo",
        department: "Finance",
        role: "Chief Financial Officer",
        compliant: true,
        complianceFrameworks: [],
        riskScore: 14,
      },
      ...updatedUsers,
    ];
  }

  // Add more servere users.
  updatedUsers = [
    {
      firstName: "Alex",
      lastName: "Rivera",
      initials: "AR",
      email: "alex.rivera@dune.demo",
      department: "Program",
      role: "Program Analyst",
      compliant: false,
      complianceFrameworks: [],
      riskScore: 76,
    },
    {
      firstName: "Jack",
      lastName: "Turner",
      initials: "JT",
      email: "jack.turner@dune.demo",
      department: "Technology",
      role: "Senior Software Engineer",
      compliant: false,
      complianceFrameworks: [],
      riskScore: 78,
    },
    {
      firstName: "Logan",
      lastName: "Murphy",
      initials: "LM",
      email: "logan.murphy@dune.demo",
      department: "Program",
      role: "Program Analyst",
      compliant: false,
      complianceFrameworks: [],
      riskScore: 79,
    },
    {
      firstName: "Casey",
      lastName: "Lee",
      initials: "CL",
      email: "casey.lee@dune.demo",
      department: "Investment",
      role: "Intern",
      compliant: false,
      complianceFrameworks: [],
      riskScore: 80,
    },
    {
      firstName: "Sam",
      lastName: "Jordan",
      initials: "SJ",
      email: "sam.jordan@dune.demo",
      department: "Program",
      role: "Program Analyst",
      compliant: false,
      complianceFrameworks: [],
      riskScore: 81,
    },
    {
      firstName: "Mason",
      lastName: "Wright",
      initials: "MW",
      email: "mason.wright@dune.demo",
      department: "Investment",
      role: "Program & Community Analyst",
      compliant: false,
      complianceFrameworks: [],
      riskScore: 83,
    },
    {
      firstName: "Avery",
      lastName: "Brooks",
      initials: "AB",
      email: "avery.brooks@dune.demo",
      department: "Investment",
      role: "Program & Community Analyst",
      compliant: false,
      complianceFrameworks: [],
      riskScore: 90,
    },
    ...updatedUsers,
  ];

  // Identify the specific user
  const specificUserEmail = "michael.waite@dune.demo";
  const specificUser = updatedUsers.find(
    (user) => user.email === specificUserEmail,
  );
  const otherUsers = updatedUsers.filter(
    (user) => user.email !== specificUserEmail,
  );

  // Calculate the number of users for each status
  const numUsers = updatedUsers.length;
  const numCompleted = Math.round(trainingStatuses.Completed * (numUsers - 1)); // Exclude specific user, Michael Waite
  const numInProgress = Math.round(
    trainingStatuses["In Progress"] * (numUsers - 1),
  );

  // Assign statuses to remaining users
  const usersWithStatus = otherUsers.map((user, index) => {
    if (index < numCompleted) {
      return { ...user, trainingStatus: "Completed" };
    } else if (index < numCompleted + numInProgress) {
      return { ...user, trainingStatus: "In Progress" };
    } else {
      return { ...user, trainingStatus: "Not Started" };
    }
  });

  // Add the specific user with the "Not Started" status
  const finalUsersWithStatus = [
    ...usersWithStatus,
    { ...specificUser, trainingStatus: "Not Started" },
  ];

  return finalUsersWithStatus;
};

export default getAdminUsersByTrainingStatus;
