import React, { useState } from "react";

import { AssetViewer } from "./utils";

import {
  CalendarOrganization,
  CardHeader,
  CardWithLoadingState,
  DataTable,
} from "../../../..";

function CardTestTracker({
  testDates,
  selectedDate,
  onChangeSelectedDate,
  testAssets,
}) {
  // Recipients
  const [
    selectedAssetNameForRecipients,
    setSelectedAssetNameForRecipientsForRecipients,
  ] = useState("");
  const [selectedRecipients, setSelectedRecipients] = useState(null);

  // Modal
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [title, setTitle] = useState("");

  const handleClickRecipients = (recipients, assetName) => {
    if (recipients) {
      setSelectedRecipients(recipients);
    }

    if (assetName) {
      setSelectedAssetNameForRecipientsForRecipients(assetName);
    }
  };

  const handleClickViewTestAsset = async (assetName, title) => {
    if (assetName) {
      setSelectedFileName(assetName);
      handleOpenModal(title);
    }
  };

  const handleCloseModal = () => {
    setTitle("");
    setIsOpenModal(false);
  };

  const handleOpenModal = (title) => {
    setTitle(title);
    setIsOpenModal(true);
  };

  const headersTestAssets = [
    {
      id: "viewAsset",
      label: "View",
      isSortable: false,
      isSearchable: false,
      align: "center",
      width: "56px",
      onClick: handleClickViewTestAsset,
    },
    {
      id: "assetName",
      label: "Scheduled Asset",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "400px",
    },
    {
      id: "sender",
      label: "Sender",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "164px",
    },
    {
      id: "recipients",
      label: "Recipients",
      isSortable: true,
      isSearchable: false,
      align: "left",
      width: "164px",
      onClick: handleClickRecipients,
    },
    {
      id: "difficulty",
      label: "Difficulty",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "100px",
    },
    {
      id: "motive",
      label: "Motive",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "100px",
    },
    {
      id: "method",
      label: "Method",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "200px",
    },
  ];

  const headersUsers = [
    {
      id: "name",
      label: "Name",
      isSortable: true,
      isSearchable: true,
      align: "left",
      width: "500px",
    },
    {
      id: "email",
      label: "Email",
      isSortable: true,
      isSearchable: true,
      align: "left",
    },
    {
      id: "riskScore",
      label: "Risk score",
      isSortable: true,
      isSearchable: false,
      align: "left",
      width: "164px",
    },
    {
      id: "riskLevel",
      label: "Risk level",
      isSortable: false,
      isSearchable: false,
      align: "left",
      width: "164px",
    },
  ];

  return (
    <>
      <CardWithLoadingState
        loadingVariable={testDates}
        // minHeight={
        //   !testAssets || testAssets.length > 0 ? "393px" : 0
        // }
      >
        <CardHeader
          header="Test Tracker"
          tooltip="View and manage your organization's upcoming security simulations. Access key details, including assets planned for the day, asset titles, assigned personnel, asset types, and the domains from which simulations will be sent."
        />
        <div className="flex flex-col md:flex-row">
          <div className="flex justify-center md:justify-start">
            <div className="max-w-[272px] w-full">
              <CalendarOrganization
                dates={testDates}
                selectedDate={selectedDate}
                onChangeSelectedDate={onChangeSelectedDate}
              />
            </div>
          </div>
          <div className="flex flex-col ml-0 overflow-x-auto md:ml-1">
            {testAssets !== null &&
              Array.isArray(testAssets) &&
              testAssets.length > 0 && (
                <div className="flex">
                  <DataTable
                    data={testAssets}
                    headers={headersTestAssets}
                    rowsPerPage={4}
                    searchPlaceholder="Type in a test's name"
                    disableFilters
                  />
                </div>
              )}
            {testDates &&
              Array.isArray(testDates) &&
              testDates.length === 0 && (
                <div className="w-full">
                  <p className="text-center body">No scheduled tests</p>
                </div>
              )}
            {selectedRecipients && (
              <div className="mt-1.25">
                <DataTable
                  data={selectedRecipients}
                  headers={headersUsers}
                  defaultOrderBy="name"
                  rowsPerPage={5}
                  customHeader={
                    <div className="flex flex-col">
                      <div>
                        <h4 className="card-header">Scheduled Asset</h4>
                      </div>
                      <div className="border-b-2 border-green w-fit">
                        <p role="heading" aria-level="4" className="h4">
                          {selectedAssetNameForRecipients}
                        </p>
                      </div>
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </CardWithLoadingState>
      <AssetViewer
        isOpen={isOpenModal}
        onCloseModal={handleCloseModal}
        title={title}
        fileName={selectedFileName}
      />
    </>
  );
}

export default CardTestTracker;
