import React from "react";

const FailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.3612 11.9306L9.6882 3.03198C9.54643 2.81397 9.34405 2.6332 9.10111 2.5076C8.85817 2.382 8.58311 2.31593 8.30319 2.31593C8.02326 2.31593 7.7482 2.382 7.50526 2.5076C7.26232 2.6332 7.05994 2.81397 6.91818 3.03198L1.24515 11.9306C1.10875 12.1415 1.03687 12.3813 1.03687 12.6255C1.03687 12.8698 1.10875 13.1096 1.24515 13.3205C1.3851 13.5398 1.58713 13.7215 1.83053 13.8471C2.07392 13.9726 2.3499 14.0373 2.63016 14.0347H13.9762C14.2562 14.0371 14.532 13.9723 14.7751 13.8468C15.0183 13.7213 15.2201 13.5396 15.3599 13.3205C15.4965 13.1097 15.5686 12.8699 15.5689 12.6257C15.5691 12.3815 15.4974 12.1416 15.3612 11.9306ZM7.78421 7.00346C7.78421 6.87914 7.83889 6.75992 7.93622 6.67201C8.03354 6.5841 8.16555 6.53471 8.30319 6.53471C8.44083 6.53471 8.57283 6.5841 8.67016 6.67201C8.76748 6.75992 8.82216 6.87914 8.82216 7.00346V9.34721C8.82216 9.47153 8.76748 9.59076 8.67016 9.67867C8.57283 9.76658 8.44083 9.81596 8.30319 9.81596C8.16555 9.81596 8.03354 9.76658 7.93622 9.67867C7.83889 9.59076 7.78421 9.47153 7.78421 9.34721V7.00346ZM8.30319 12.1597C8.14922 12.1597 7.99871 12.1185 7.8707 12.0412C7.74268 11.964 7.6429 11.8541 7.58398 11.7257C7.52506 11.5972 7.50965 11.4558 7.53968 11.3194C7.56972 11.183 7.64386 11.0577 7.75273 10.9594C7.8616 10.8611 8.00031 10.7941 8.15132 10.767C8.30232 10.7398 8.45884 10.7538 8.60109 10.807C8.74333 10.8602 8.86491 10.9503 8.95045 11.066C9.03599 11.1816 9.08165 11.3175 9.08165 11.4566C9.08165 11.6431 8.99963 11.8219 8.85364 11.9538C8.70765 12.0856 8.50965 12.1597 8.30319 12.1597Z"
        fill="#EF3C1F"
      />
    </svg>
  );
};

export default FailIcon;
