import React from "react";

import { CopyField, List } from "../../../../../components";

const StepSeven = () => {
  const stepsSettings = [
    {
      description: (
        <>
          Go to the <span className="font-medium">Details</span> Tab and click
          on the Advanced Settings section.
        </>
      ),
    },
    {
      description: (
        <>
          Copy the <span className="font-medium">Client ID</span> and click on
          the{" "}
          <span className="font-medium">
            View Google Workspace Admin Console
          </span>{" "}
          button.
        </>
      ),
    },
    {
      description: (
        <>
          Navigate to <span className="font-medium">Security</span> {">"}{" "}
          <span className="font-medium">API Controls</span> {">"}{" "}
          <span className="font-medium">Manage Domain-Wide Delegation</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Add New</span> and paste your
          service account's Client ID.
        </>
      ),
    },
    {
      description: (
        <>
          <p>Add the following OAuth Scopes</p>
          <div className="mt-0.25 w-full">
            <CopyField text="https://www.googleapis.com/auth/admin.directory.group, https://www.googleapis.com/auth/admin.directory.user" />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div>
          <h4 className="font-medium onboarding-step">Authorize API Scopes</h4>
        </div>
        <div className="mt-1">
          <List
            data={stepsSettings}
            keyName="description"
            textSize="onboarding-step"
          />
        </div>
      </div>
    </div>
  );
};

export default StepSeven;
