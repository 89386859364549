import React from "react";
import { useNavigate } from "react-router-dom";

import { OnboardingTemplate } from "../../../components";

import {
  GoogleLogoIconLarge,
  MicrosoftExchangeBrandMarkIconLarge,
  MicrosoftExchangeLogoIconLarge,
} from "../../../utils";

const SetupDMI = () => {
  const navigate = useNavigate();

  const emailProviders = [
    {
      name: "Microsoft Exchange",
      logo: (
        <div className="flex items-center gap-0.25">
          <MicrosoftExchangeBrandMarkIconLarge />
          <MicrosoftExchangeLogoIconLarge />
        </div>
      ),
      path: "/onboarding/setup-dmi/exchange/",
    },
    {
      name: "Google Workspace",
      logo: <GoogleLogoIconLarge />,
      path: "/onboarding/setup-dmi/google-workspace/",
    },
  ];

  const handleClickIAMProvider = (provider) => {
    if (provider && provider.path) {
      navigate(provider.path);
    }
  };

  return (
    <OnboardingTemplate
      title="Setup Direct Mail Injection"
      mainContent={
        <div>
          <div>
            <h3 className="h4">Set Up Your Direct Mail Injection</h3>
          </div>
          <div className="mt-1">
            <p className="body text-gray-surface-secondary">
              Improve deliverability and eliminate the need to configure
              Phishing Simulation and Advanced Delivery settings.
            </p>
          </div>
          <div className="mt-8">
            <p className="body-emphasized">Select your email provider</p>
            <div className="grid grid-cols-1 gap-2 mt-2 sm:grid-cols-2 lg:grid-cols-3">
              {emailProviders.map((provider, index) => (
                <div
                  className="flex flex-col justify-between min-h-[144px] w-full px-1 py-1.5 border rounded-medium bg-gray-card border-border-color"
                  role="button"
                  onClick={() => handleClickIAMProvider(provider)}
                  key={`iam-provider-${index}`}
                >
                  <div>{provider.logo}</div>
                  <div className="pt-2.875">
                    <p className="body-emphasized">{provider.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    />
  );
};

export default SetupDMI;
