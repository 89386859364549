import React from "react";

import { List } from "../../../../../components";

const StepFive = () => {
  const steps = [
    {
      description: "Navigate to the General tab.",
    },
    {
      description: (
        <>
          In the App settings section, enable{" "}
          <span className="font-medium">SCIM provisioning</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">
          Enable SCIM Provisioning in Okta
        </h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepFive;
