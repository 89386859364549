export const complianceFrameworks = [
  { name: "GDPR" },
  { name: "GLBA" },
  { name: "HIPAA" },
  { name: "ISO 27001" },
  { name: "NIST" },
  { name: "SOC 2 Type 2" },
  { name: "PCI DSS" },
];

export const SCREENS = {
  CERTIFICATE: "certificate",
  CHECKING_RESPONSE: "checkingResponse",
  COMPLETION: "completion",
  COMPLIANCE_COMPLETION: "complianceCompletion",
  INFOGRAPHIC: "infographic",
  NEXT_COMPLIANCE_VIDEO: "nextComplianceVideo",
  QUIZ: "quiz",
  QUIZ_FAILURE: "quizFailure",
  TRAINING_COMPLETION: "trainingCompletion",
  VIDEO_PLAYER: "videoPlayer",
};
