import React from "react";

import { List } from "../../../../../components";

const StepTwo = () => {
  const stepsCreateApp = [
    {
      description: (
        <>
          In the side navigation panel, under the Manage section, select{" "}
          <span className="font-medium">Enterprise applications</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">New Application</span> and then{" "}
          <span className="font-medium">Create your own application</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Enter <span className="font-medium">Dune Security</span> as the
          application name and click Create.
        </>
      ),
    },
  ];

  const stepsAssign = [
    {
      description: (
        <>
          Once the application is created, go to the{" "}
          <span className="font-medium">Getting Started</span> section.
        </>
      ),
    },
    {
      description: (
        <>
          Select <span className="font-medium">Assign users and groups</span>{" "}
          and add the users/groups who need access.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div>
          <h4 className="font-medium onboarding-step">
            Create a New Enterprise Application
          </h4>
        </div>
        <div className="mt-1">
          <List
            data={stepsCreateApp}
            keyName="description"
            textSize="onboarding-step"
          />
        </div>
      </div>
      <div className="mt-2">
        <div>
          <h4 className="font-medium onboarding-step">
            Assign Users and Groups
          </h4>
        </div>
        <div className="mt-1">
          <List
            data={stepsAssign}
            keyName="description"
            textSize="onboarding-step"
          />
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
