import React from "react";
import { useLocation } from "react-router-dom";
import CertificateGenerator from "../certificate-generator/certificate-generator";

function Certificate() {
  const location = useLocation();
  const { firstName, lastName, trainingName, date } = location.state || {};

  console.log(firstName, lastName, trainingName, date);

  if (!firstName || !lastName || !trainingName || !date) {
    return <div>Error: Certificate details are missing.</div>;
  }

  return (
    <div>
      <CertificateGenerator
        firstName={firstName}
        lastName={lastName}
        trainingName={trainingName}
        date={date}
      />
    </div>
  );
}

export default Certificate;
