import React, { useEffect, useState } from "react";

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const CustomTooltip = ({ active, payload, label, color }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="bg-green py-[2px] px-[8px] rounded-small"
        style={{ background: color }}
      >
        <p className="font-semibold text-black body-emphasized">
          {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};

const CustomYAxisTick = ({ x, y, payload }) => {
  if (x !== null && y !== null && payload.value !== null) {
    return (
      <g transform={`translate(0, ${y + 4})`}>
        <text
          x={0}
          y={0}
          fill="#C2BFB8"
          fontSize="11px"
          fontWeight="500"
          letterSpacing="1.32px"
        >
          {payload.value}
        </text>
      </g>
    );
  }
  return null;
};

function RiskScoreLineChart({ riskScoreOverTime, color }) {
  const [lineChartData, setLineChartData] = useState([]);

  const getXAxisTicks = () => {
    if (lineChartData.length === 0 || !lineChartData[0]) {
      return [];
    }

    let ticks = [lineChartData[0].date];

    for (let i = 1; i < riskScoreOverTime.length; i++) {
      const matches = riskScoreOverTime[i].date.match(/\d+/);
      const date = matches ? parseInt(matches[0], 10) : null;

      if (date && date === 1) {
        ticks.push(riskScoreOverTime[i].date);
      }
    }

    return ticks;
  };

  useEffect(() => {
    if (riskScoreOverTime && lineChartData !== riskScoreOverTime) {
      setLineChartData(riskScoreOverTime);
    }
  }, [riskScoreOverTime, lineChartData]);

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight="240px">
      <LineChart data={lineChartData}>
        <CartesianGrid stroke="rgba(194, 191, 184, 0.35)" vertical={false} />
        <XAxis
          dataKey="date"
          axisLine={false}
          stroke="#C2BFB8"
          tick={{
            fontSize: "11px",
            fontWeight: "500",
            letterSpacing: "1.32px",
          }}
          tickLine={false}
          ticks={getXAxisTicks()}
          tickFormatter={(value) => {
            const date = new Date(value + ", 2020");

            return date
              .toLocaleString("en-US", {
                month: "short",
              })
              .toUpperCase();
          }}
        />
        <YAxis
          axisLine={false}
          domain={[0, 100]}
          tick={<CustomYAxisTick />}
          tickLine={false}
          tickCount={6}
        />
        <Tooltip
          content={<CustomTooltip color={color} />}
          cursor={{
            stroke: color,
            strokeDasharray: 8,
            strokeWidth: 3,
          }}
        />
        <Line
          type="monotone"
          dataKey="riskScore"
          stroke={color}
          strokeWidth="3px"
          dot={false}
          activeDot={{
            stroke: color,
            r: 6,
          }}
          animationDuration={1000}
          animationEasing="linear"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default RiskScoreLineChart;
