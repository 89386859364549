import React, { useEffect, useState } from "react";

import { getDepartments, postRequestNewCustomTraining } from "../../../api";

import {
  ButtonOutlinedGreen,
  FileUpload,
  LoadingState,
  MultiSelect,
  TextField,
} from "../../../components";

import { CustomTwoColumn } from "../../../components/platform/configure/utils";

const RequestNewTraining = ({ isInitialLoading, email, setBreadcrumbs }) => {
  // Form input
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [description, setDescription] = useState("");
  const [selectedAttachmentFiles, setSelectedAttachmentFiles] = useState([]);

  // Fetched data
  const [departments, setDepartments] = useState(null);

  // Messages
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChangeDepartment = (selected) => {
    const isOptionSelected = selectedDepartments.some(
      (department) => department.value === selected.value,
    );

    if (isOptionSelected) {
      setSelectedDepartments(
        selectedDepartments.filter(
          (department) => department.value !== selected.value,
        ),
      );
    } else {
      setSelectedDepartments([...selectedDepartments, selected]);
    }
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleClickSubmitRequest = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    if (!description.trim() && selectedAttachmentFiles.length === 0) {
      setErrorMessage("Enter a description or upload a file.");
      return;
    }

    if (selectedDepartments.length === 0) {
      setErrorMessage("Select at least one department.");
      return;
    }

    const form = new FormData();

    form.append(
      "department",
      selectedDepartments.map((department) => department.value).join(","),
    );

    if (description.trim()) {
      form.append("text", description);
    }

    selectedAttachmentFiles.forEach((file) => {
      form.append("file", file);
    });

    try {
      const response = await postRequestNewCustomTraining(form);

      console.log(response);

      setSuccessMessage("Your request has been sent successfully.");
    } catch (error) {
      console.error(error);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const loadDepartments = async () => {
      const departmentsData = await getDepartments();

      if (Object.keys(departmentsData.error).length > 0) {
        console.error(departmentsData.error.message);
      } else {
        const { departments } = departmentsData.result;

        setDepartments(departments);
      }
    };

    if (!isInitialLoading && email) {
      loadDepartments();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {
    // Update breadcrumbs.
    const timeout = setTimeout(() => {
      // Update breadcrumbs last.
      setBreadcrumbs([
        {
          name: "Trainings",
          path: "/configure/trainings/",
        },
        {
          name: "Configure Trainings",
        },
      ]);
    }, 0);

    return () => clearTimeout(timeout);
  }, [setBreadcrumbs]);

  if (departments === null) {
    return <LoadingState message="Loading" />;
  }

  return (
    <div>
      {departments && departments.length > 0 && (
        <CustomTwoColumn
          firstColumn={
            <>
              <p className="body">Select Departments*</p>
              <p className="description mt-0.25">
                Which departments will this training apply to?
                <br />
                (You can select multiple)
              </p>
            </>
          }
          secondColumn={
            <MultiSelect
              value={selectedDepartments}
              options={departments.map((department) => ({
                name: department.departmentName,
                value: department.departmentName,
              }))}
              defaultValue="Select Departments"
              onChange={handleChangeDepartment}
            />
          }
        />
      )}
      <CustomTwoColumn
        firstColumn={
          <>
            <p className="body">Training Description*</p>
            <p className="description mt-0.25">
              Provide a brief description of the training you need. <br />
              (Max 1,500 characters)
            </p>
          </>
        }
        secondColumn={
          <TextField
            value={description}
            onChangeValue={handleChangeDescription}
            multiline
            rows={10}
            maxLength={1500}
            placeholder={`Provide a brief description of the training you need. \nFor example:\n"We are looking to develop a Password hygiene Training. The training should cover how to create strong passwords, and the steps to report password compromise."`}
          />
        }
      />
      <CustomTwoColumn
        firstColumn={
          <>
            <p className="body">Additional Attachments</p>
            <p className="description mt-0.25">
              Have any inspiration or reference materials like previous training
              video, powepoint presentations, or screenshots? Upload them here
              to help us build your training.
              <br />
              (MP4,PPT,PDF, JPEG max file size: 10MB)
            </p>
          </>
        }
        secondColumn={
          <FileUpload
            selectedFiles={selectedAttachmentFiles}
            setSelectedFiles={setSelectedAttachmentFiles}
            multiple
            fileType="MP4, PPT, PDF, JPEG"
            accept={{
              "video/mp4": [".mp4"],
              "application/pdf": [".pdf"],
              "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
              "image/png": [".png"],
              "image/jpeg": [".jpg", ".jpeg"],
            }}
          />
        }
      />
      <div className="flex flex-col-reverse mt-2 md:flex-row md:justify-between">
        <div className="mt-1 md:mt-0">
          {successMessage && (
            <p className="body text-green">{successMessage}</p>
          )}
          {errorMessage && !successMessage && (
            <p className="body text-red">{errorMessage}</p>
          )}
        </div>
        <div>
          <ButtonOutlinedGreen
            title="Submit request."
            onClick={handleClickSubmitRequest}
          >
            Submit Request
          </ButtonOutlinedGreen>
        </div>
      </div>
    </div>
  );
};

export default RequestNewTraining;
