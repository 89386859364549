import React from "react";

import { Divider, List, TextField } from "../../../../../components";

const StepFour = ({ secretValue, setSecretValue }) => {
  const handleChangeSecretValue = (event) => {
    setSecretValue(event.target.value);
  };

  const steps = [
    {
      description: (
        <>
          In the Certificates & Secrets section under Manage, click on{" "}
          <span className="font-medium">New Client</span> Secret.
        </>
      ),
    },
    {
      description:
        'Provide a description (e.g., "Direct Mail Injection Secret").',
    },
    {
      description: "Set the expiration period to 6 months.",
    },
    {
      description: (
        <>
          Click <span className="font-medium">Add</span> to generate the secret.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">Create a Client Secret</h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
      <div className="my-2">
        <div className="flex flex-col py-2 md:flex-row md:items-center md:gap-1">
          <div className="max-w-[172px] w-full">
            <p className="body">Secret Value*</p>
          </div>
          <TextField
            value={secretValue}
            placeholder="Secret Value"
            onChangeValue={handleChangeSecretValue}
          />
        </div>
        <Divider />
        <div className="my-2">
          <p className="font-medium onboarding-step">
            You won't be able to view the secret again, so save it securely.
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
