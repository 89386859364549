import React from "react";

import { Box, Typography } from "@mui/material";

import { CustomLink, FadeInSection } from "../../../components";

import { InlineWidget } from "react-calendly";

import "./demo.css";
import { Translate } from "react-auto-translate";

function Demo() {
  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "60px",
            md: "80px",
          }}
          padding="0 28px"
        >
          <Box maxWidth="796px" width="100%">
            <Typography
              role="heading"
              aria-level="1"
              component="h1"
              className="dune-text-header-uppercase"
            >
              <Translate>Book a demo</Translate>
            </Typography>
            <Typography
              role="heading"
              aria-level="2"
              component="h2"
              className="dune-text-header-main"
              marginTop="20px"
            >
              <Translate>
                {" "}
                See Dune in action with a personalized demo
              </Translate>
            </Typography>
            <Typography component="p" marginTop="20px">
              <Translate>Schedule a 30 minute call or email us at</Translate>{" "}
              <CustomLink
                className="demo-link-email"
                href="mailto:sales@dune.security"
                title="Email sales@dune.security."
              >
                sales@dune.security
              </CustomLink>{" "}
              <Translate>for any questions.</Translate>
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          minHeight="840px"
          height="100%"
          marginTop={{
            xs: "68px",
            sm: "2px",
          }}
          padding="0 28px"
        >
          <Box
            maxWidth="1152px"
            width="100%"
            sx={{
              "& iframe": {
                height: "840px",
              },
            }}
          >
            <InlineWidget
              url="https://calendly.com/kaila-dune/30min"
              pageSettings={{
                backgroundColor: "#000000",
                primaryColor: "#00FF82",
                textColor: "#FFFFFF",
              }}
            />
          </Box>
        </Box>
        <div className="lg:p-0 p-2 text-white max-w-[796px] w-full mx-auto">
          By scheduling a demo, I allow Dune Security to contact me for
          scheduling and marketing purposes in accordance with its{" "}
          <CustomLink href="https://dune.security/privacy-policy">
            Privacy Policy
          </CustomLink>
          .
        </div>
      </FadeInSection>
    </>
  );
}

export default Demo;
