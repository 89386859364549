import React from "react";

import { CopyField, List } from "../../../../../components";

const StepTwo = ({ acsURL, entityIDDune }) => {
  const steps = [
    {
      description: (
        <>
          Under App name: Enter "
          <span className="font-medium">Dune Security SSO</span>" and click{" "}
          <span className="font-medium">Next</span>.
        </>
      ),
    },
    {
      description: (
        <>
          <p>Single Sign-On URL:</p>
          <div className="mt-0.25 w-full">
            <CopyField text={acsURL} />
          </div>
        </>
      ),
    },
    {
      description: (
        <>
          <p>Audience URI (Entity ID):</p>
          <div className="mt-0.25 w-full">
            <CopyField text={entityIDDune} />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">Configure SAML Settings</h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
      <div className="mt-2">
        <p className="onboarding-step">
          Click <span className="font-medium">Next</span>, review the
          configuration and click <span className="font-medium">Finish</span>.
        </p>
      </div>
    </div>
  );
};

export default StepTwo;
