import {
    BuildingFilledIcon,
    BuildingOutlinedIcon,
    PersonFilledIcon,
    PersonOutlinedIcon,
    SettingsFilledIcon,
    SettingsOutlinedIcon,
    SignalCellularFilledIcon,
    SignalCellularOutlinedIcon,
    TestingFilledIcon,
    TestingOutlinedIcon,
    TrainingFilledIcon,
    TrainingOutlinedIcon,
  } from "../utils/icons/";
  
  const pagesPlatformInitialTesting = {
    INSIGHTS: {
      name: "Insights",
      path: "/insights/organization/",
      icon: <SignalCellularOutlinedIcon />,
      activeIcon: <SignalCellularFilledIcon />,
      tooltip: "Go to the insights page.",
      subpages: {
        ORGANIZATION: {
          name: "Organization",
          path: "/insights/initial-testing/",
          icon: <BuildingOutlinedIcon />,
          activeIcon: <BuildingFilledIcon />,
          tooltip: "Go to the initial testing insights page.",
        },
        USERS: {
          name: "Users",
          path: "/insights/users/search/",
          icon: <PersonOutlinedIcon />,
          activeIcon: <PersonFilledIcon />,
          tooltip: "Go to the users insights page.",
        },
      },
    },
    CONFIGURE: {
      name: "Configure",
      path: "/configure/testing/",
      icon: <SettingsOutlinedIcon />,
      activeIcon: <SettingsFilledIcon />,
      tooltip: "Go to the configure page.",
      subpages: {
        TESTING: {
          name: "Testing",
          path: "/configure/testing/",
          icon: <TestingOutlinedIcon />,
          activeIcon: <TestingFilledIcon />,
          tooltip: "Go to the testing configuration page.",
        },
  
        TRAINING: {
          name: "Trainings",
          path: "/configure/trainings/",
          icon: <TrainingOutlinedIcon />,
          activeIcon: <TrainingFilledIcon />,
          tooltip: "Go to the training configuration page.",
        },
      },
    },
  };
  
  export default pagesPlatformInitialTesting;
  