import { getResponseGet } from "../utils";

const getAnomalousLoginBehavior = async () => {
  let getAnomalousLoginBehaviorResponse = {
    result: {
      anomalousLoginBehavior: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/anomalous-login-behavior/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      getAnomalousLoginBehaviorResponse.result.anomalousLoginBehavior = data;
    }
  } catch (error) {
    getAnomalousLoginBehaviorResponse.error = error;
  }

  return getAnomalousLoginBehaviorResponse;
};

export default getAnomalousLoginBehavior;
