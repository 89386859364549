export const AVERAGE_HOURLY_USER_LOGIN = [
  {
    time: "12 a.m.",
    average: 0,
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  },
  {
    time: "1 a.m.",
    average: 0,
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  },
  {
    time: "2 a.m.",
    average: 0,
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  },
  {
    time: "3 a.m.",
    average: 0,
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  },
  {
    time: "4 a.m.",
    average: 3,
    Monday: 8,
    Tuesday: 7,
    Wednesday: 12,
    Thursday: 8,
    Friday: 9,
    Saturday: 4,
    Sunday: 4,
  },
  {
    time: "5 a.m.",
    average: 8,
    Monday: 15,
    Tuesday: 16,
    Wednesday: 24,
    Thursday: 18,
    Friday: 13,
    Saturday: 8,
    Sunday: 7,
  },
  {
    time: "6 a.m.",
    average: 20,
    Monday: 35,
    Tuesday: 37,
    Wednesday: 52,
    Thursday: 40,
    Friday: 28,
    Saturday: 22,
    Sunday: 18,
  },
  {
    time: "7 a.m.",
    average: 45,
    Monday: 60,
    Tuesday: 75,
    Wednesday: 110,
    Thursday: 90,
    Friday: 60,
    Saturday: 45,
    Sunday: 38,
  },
  {
    time: "8 a.m.",
    average: 70,
    Monday: 100,
    Tuesday: 110,
    Wednesday: 140,
    Thursday: 110,
    Friday: 80,
    Saturday: 65,
    Sunday: 55,
  },
  {
    time: "9 a.m.",
    average: 90,
    Monday: 140,
    Tuesday: 120,
    Wednesday: 120,
    Thursday: 90,
    Friday: 75,
    Saturday: 55,
    Sunday: 40,
  },
  {
    time: "10 a.m.",
    average: 80,
    Monday: 90,
    Tuesday: 100,
    Wednesday: 90,
    Thursday: 75,
    Friday: 65,
    Saturday: 45,
    Sunday: 35,
  },
  {
    time: "11 a.m.",
    average: 70,
    Monday: 80,
    Tuesday: 90,
    Wednesday: 80,
    Thursday: 60,
    Friday: 50,
    Saturday: 35,
    Sunday: 28,
  },
  {
    time: "12 p.m.",
    average: 50,
    Monday: 70,
    Tuesday: 80,
    Wednesday: 70,
    Thursday: 50,
    Friday: 40,
    Saturday: 25,
    Sunday: 20,
  },
  {
    time: "1 p.m.",
    average: 40,
    Monday: 60,
    Tuesday: 70,
    Wednesday: 60,
    Thursday: 45,
    Friday: 35,
    Saturday: 18,
    Sunday: 15,
  },
  {
    time: "2 p.m.",
    average: 30,
    Monday: 50,
    Tuesday: 60,
    Wednesday: 50,
    Thursday: 35,
    Friday: 25,
    Saturday: 10,
    Sunday: 8,
  },
  {
    time: "3 p.m.",
    average: 25,
    Monday: 40,
    Tuesday: 50,
    Wednesday: 40,
    Thursday: 30,
    Friday: 20,
    Saturday: 8,
    Sunday: 5,
  },
  {
    time: "4 p.m.",
    average: 18,
    Monday: 30,
    Tuesday: 40,
    Wednesday: 35,
    Thursday: 20,
    Friday: 15,
    Saturday: 5,
    Sunday: 3,
  },
  {
    time: "5 p.m.",
    average: 10,
    Monday: 20,
    Tuesday: 30,
    Wednesday: 25,
    Thursday: 12,
    Friday: 8,
    Saturday: 3,
    Sunday: 2,
  },
  {
    time: "6 p.m.",
    average: 5,
    Monday: 12,
    Tuesday: 18,
    Wednesday: 15,
    Thursday: 8,
    Friday: 3,
    Saturday: 2,
    Sunday: 1,
  },
  {
    time: "7 p.m.",
    average: 2,
    Monday: 5,
    Tuesday: 10,
    Wednesday: 7,
    Thursday: 2,
    Friday: 1,
    Saturday: 0,
    Sunday: 0,
  },
  {
    time: "8 p.m.",
    average: 1,
    Monday: 3,
    Tuesday: 5,
    Wednesday: 4,
    Thursday: 1,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  },
  {
    time: "9 p.m.",
    average: 0,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 1,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  },
  {
    time: "10 p.m.",
    average: 0,
    Monday: 1,
    Tuesday: 1,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  },
  {
    time: "11 p.m.",
    average: 0,
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  },
];
