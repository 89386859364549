import { getResponseGet } from "../utils";

const getUserActivityTicker = async (targetEmail) => {
  let getUserActivityTickerResponse = {
    result: {
      userActivities: [],
    },
    error: {},
  };

  try {
    const response = await getResponseGet(
      `/anomalous-user-login-behavior/?target_email=${targetEmail}`,
    );

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data) {
      getUserActivityTickerResponse.result.userActivities = data;
    }
  } catch (error) {
    getUserActivityTickerResponse.error = error;
  }

  return getUserActivityTickerResponse;
};

export default getUserActivityTicker;
