import { getResponsePost } from "../utils";

export const postPreferredLanguage = async (requestBody) => {
  let postPreferredLanguageResponse = {
    result: {},
    error: {},
  };

  try {
    const response = await getResponsePost("/preferred-language/", requestBody);

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    if (data && data.result) {
      postPreferredLanguageResponse.result = data.result;
    }
  } catch (error) {
    postPreferredLanguageResponse.error = error;
  }

  return postPreferredLanguageResponse;
};

export default postPreferredLanguage;
