export const INACTIVE_USERS = [
  {
    firstName: "Michael",
    lastName: "Waite",
    initials: "MW",
    logins: 0,
    riskScore: 97,
  },
  {
    firstName: "Lorgan",
    lastName: "Murphy",
    initials: "LM",
    logins: 0,
    riskScore: 79,
  },
  {
    firstName: "Alex",
    lastName: "Rivera",
    initials: "AR",
    logins: 0,
    riskScore: 76,
  },
  {
    firstName: "Ashley",
    lastName: "Simpson",
    initials: "AS",
    logins: 0,
    riskScore: 59,
  },
  {
    firstName: "Daniel",
    lastName: "Bray",
    initials: "DB",
    logins: 0,
    riskScore: 55,
  },
];
