import React from "react";

import { List } from "../../../../../components";

const StepThree = () => {
  const steps = [
    {
      description:
        "Go to the Assignments tab in the Dune Security SSO application.",
    },
    {
      description: "Assign the application to users or groups as needed.",
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">
          Assign the Application to Users
        </h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepThree;
