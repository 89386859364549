import React from "react";

import { Divider, List, TextField } from "../../../../../components";

const StepFive = ({ clientID, setClientID, tenantID, setTenantID }) => {
  const handleChangeClientID = (event) => {
    setClientID(event.target.value);
  };

  const handleChangeTenantID = (event) => {
    setTenantID(event.target.value);
  };

  const steps = [
    {
      description:
        "Head back to the Overview section and provide the Client ID and Tenant ID.",
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">Provide tenant details</h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
      <div className="my-2">
        <div className="flex flex-col py-2 md:flex-row md:items-center md:gap-1">
          <div className="max-w-[172px] w-full">
            <p className="body">Client ID*</p>
          </div>
          <TextField
            value={clientID}
            placeholder="Client ID"
            onChangeValue={handleChangeClientID}
          />
        </div>
        <Divider />
        <div className="flex flex-col py-2 md:flex-row md:items-center md:gap-1">
          <div className="max-w-[172px] w-full">
            <p className="body">Tenant ID*</p>
          </div>
          <TextField
            value={tenantID}
            placeholder="Tenant ID"
            onChangeValue={handleChangeTenantID}
          />
        </div>
        <Divider />
      </div>
      <div className="my-2">
        <p className="description text-gray-surface-secondary">
          These credentials will be securely handled to ensure the integrity and
          confidentiality of your data.
        </p>
      </div>
    </div>
  );
};

export default StepFive;
