import React from "react";

const FileUploadIconSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="87"
      height="87"
      viewBox="0 0 87 87"
      fill="none"
    >
      <mask
        id="mask0_2244_17903"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="87"
        height="87"
      >
        <rect width="87" height="87" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2244_17903)">
        <path
          d="M23.5609 67.7879C19.3318 67.7879 15.737 66.3146 12.7766 63.3681C9.81615 60.421 8.33594 56.8192 8.33594 52.5629C8.33594 48.3941 9.83125 44.8145 12.8219 41.8238C15.8125 38.8332 19.2109 37.3379 23.0172 37.3379C23.6214 32.1421 25.8719 27.8223 29.7687 24.3785C33.6656 20.9348 38.2422 19.2129 43.4984 19.2129C49.2543 19.2129 54.1372 21.2175 58.1471 25.2268C62.1563 29.2366 64.1609 34.1195 64.1609 39.8754V44.5879H67.0609C70.3234 44.5879 73.0724 45.7056 75.3078 47.941C77.5432 50.1764 78.6609 52.9254 78.6609 56.1879C78.6609 59.4504 77.5734 62.1994 75.3984 64.4348C73.2234 66.6702 70.5047 67.7879 67.2422 67.7879H47.6672C46.0964 67.7879 44.7974 67.2744 43.7703 66.2473C42.7432 65.2202 42.2297 63.9212 42.2297 62.3504V40.6004L34.0734 48.7566L32.2609 47.0348L43.4984 35.7973L54.7359 47.0348L52.9234 48.7566L44.7672 40.6004V62.3504C44.7672 63.0754 45.0693 63.74 45.6734 64.3441C46.2776 64.9483 46.9422 65.2504 47.6672 65.2504H67.0609C69.5984 65.2504 71.7432 64.3744 73.4953 62.6223C75.2474 60.8702 76.1234 58.7254 76.1234 56.1879C76.1234 53.6504 75.2474 51.5056 73.4953 49.7535C71.7432 48.0014 69.5984 47.1254 67.0609 47.1254H61.6234V39.8754C61.6234 34.8608 59.8563 30.5863 56.3219 27.052C52.7875 23.5176 48.513 21.7504 43.4984 21.7504C38.4839 21.7504 34.2094 23.5176 30.675 27.052C27.1406 30.5863 25.3734 34.8608 25.3734 39.8754H23.3797C20.0568 39.8754 17.1417 41.1139 14.6344 43.591C12.1271 46.0681 10.8734 49.0587 10.8734 52.5629C10.8734 56.0671 12.112 59.0577 14.5891 61.5348C17.0661 64.0119 20.0568 65.2504 23.5609 65.2504H32.6234V67.7879H23.5609Z"
          fill="#4B4B4A"
        />
      </g>
    </svg>
  );
};

export default FileUploadIconSmall;
