export const videoLanguages = [
  { value: "fr_fr", label: "French", flag: "🇫🇷" },
  { value: "en_us", label: "English", flag: "🇺🇸" },
  { value: "de_de", label: "German", flag: "🇩🇪" },
  { value: "es_es", label: "Spanish", flag: "🇪🇸" },
  { value: "pt_br", label: "Portugese", flag: "🇧🇷" },
  { value: "hi_in", label: "Hindi", flag: "🇮🇳" },
  { value: "it_it", label: "Italian", flag: "🇮🇹" },
];

export const websiteLanguages = [
  { value: "es", label: "Spanish", flag: "🇪🇸" },
  { value: "pt-BR", label: "Brazilian Portuguese", flag: "🇧🇷" },
  { value: "zh-CN", label: "Chinese", flag: "🇨🇳" },
  { value: "cs", label: "Czech", flag: "🇨🇿" },
  { value: "nl", label: "Dutch", flag: "🇳🇱" },
  { value: "en", label: "English", flag: "🇺🇸" },
  { value: "fr", label: "French", flag: "🇫🇷" },
  { value: "de", label: "German", flag: "🇩🇪" },
  { value: "iw", label: "Hebrew", flag: "🇮🇱" },
  { value: "hu", label: "Hungarian", flag: "🇭🇺" },
  { value: "it", label: "Italian", flag: "🇮🇹" },
  { value: "pl", label: "Polish", flag: "🇵🇱" },
  { value: "pt-PT", label: "Portugues", flag: "🇵🇹" },
  { value: "hi", label: "Hindi", flag: "🇮🇳" },
];
