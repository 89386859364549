import React from "react";

import { List } from "../../../../../components";

const StepOne = () => {
  const steps = [
    {
      description: (
        <>
          Login to <span className="font-medium">Google Admin Console</span>{" "}
          with admin credentials and navigate to Apps {">"} Web and mobile apps.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Add app</span> {">"}{" "}
          <span className="font-medium">Add custom SAML app</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Enter the name as <span className="font-medium">Dune Security</span>{" "}
          and click <span className="font-medium">Continue</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">
          Create a New Application
        </h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepOne;
