import React from "react";

import { List } from "../../../../../components";

const StepSix = () => {
  const steps = [
    {
      description:
        "On the Service Accounts page, select the new service account.",
    },
    {
      description: (
        <>
          Go to the <span className="font-medium">Keys</span> tab and click{" "}
          <span className="font-medium">Add Key</span> {">"}{" "}
          <span className="font-medium">Create New Key</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Select <span className="font-medium">JSON</span> and click{" "}
          <span className="font-medium">Create</span>. Upload the JSON file
          here.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">Generate a JSON Key</h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
    </div>
  );
};

export default StepSix;
