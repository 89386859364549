import React from "react";

import {
  Autocomplete,
  Divider,
  List,
  TextField,
} from "../../../../../components";

const StepFour = ({
  metadataURL,
  setMetadataURL,
  emailDomains,
  setEmailDomains,
}) => {
  const handleChangeMetadataURL = (event) => {
    setMetadataURL(event.target.value);
  };

  const steps = [
    {
      description:
        "Copy the App Federation Metadata URL from the Azure Portal.",
    },
    {
      description:
        "Paste the Metadata URL here and provide your company's email domain.",
    },
  ];

  return (
    <div>
      <div>
        <h4 className="font-medium onboarding-step">Add SAML Certificates</h4>
      </div>
      <div className="mt-1">
        <List data={steps} keyName="description" textSize="onboarding-step" />
      </div>
      <div className="my-1">
        <div className="flex flex-col py-2 md:flex-row md:items-center md:gap-1">
          <div className="max-w-[172px] w-full">
            <p className="body">Metadata URL*</p>
          </div>
          <TextField
            value={metadataURL}
            placeholder="Metadata URL"
            multiline
            rows={4}
            onChangeValue={handleChangeMetadataURL}
          />
        </div>
        <Divider />
        <div className="flex flex-col py-2 md:flex-row md:items-center md:gap-1">
          <div className="flex flex-col gap-0.25 max-w-[172px] w-full">
            <p className="body">Email Domain*</p>
            <p className="detailed text-gray-surface-secondary">
              Press Enter after each domain.
            </p>
          </div>
          <Autocomplete
            values={emailDomains}
            setValues={setEmailDomains}
            placeholder="Example: dune.security"
          />
        </div>
        <Divider />
      </div>
    </div>
  );
};

export default StepFour;
