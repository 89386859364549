import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  StepFive,
  StepFour,
  StepOne,
  StepSeven,
  StepSix,
  StepThree,
  StepTwo,
} from "./utils";

import {
  getSettingsSSO,
  postOnboardingStatus,
  postSettingsSSOSAMLByManual,
} from "../../../api";

import {
  ButtonContainedGreen,
  ButtonOutlinedGreen,
  StepTemplate,
} from "../../../components";

const SetupSSOAndSCIMWithGoogleWorkspace = ({ isInitialLoading, email }) => {
  const navigate = useNavigate();

  const totalSteps = 7;

  // Details
  const [acsURL, setACSURL] = useState("");
  const [entityIDDune, setEntityIDDune] = useState("");
  const [ssoURL, setSSOURL] = useState("");
  const [entityIDIDP, setEntityIDIDP] = useState("");
  const [certificate, setCertificate] = useState("");
  const [emailDomains, setEmailDomains] = useState([]);

  // Steps
  const [stepNumber, setStepNumber] = useState(1); // Default value is 1.
  const [stepPercentage, setStepPercentage] = useState(1 / totalSteps);

  // Message
  const [errorMessage, setErrorMessage] = useState("");

  const sendOnboardingStatus = async (stepName) => {
    const requestBody = {
      stepName,
    };

    const postOnboardingStatusResponse =
      await postOnboardingStatus(requestBody);

    if (Object.keys(postOnboardingStatusResponse.error).length > 0) {
      console.error(postOnboardingStatusResponse.error.message);
      setErrorMessage(postOnboardingStatusResponse.error.message);
    }
  };

  const handleClickBack = () => {
    setErrorMessage("");

    if (stepNumber === 1) {
      navigate("/onboarding/setup-sso-and-scim/");
    } else {
      const newStep = Math.max(1, stepNumber - 1);

      setStepNumber(newStep);
      setStepPercentage(newStep / totalSteps);
    }
  };

  const handleClickNext = async () => {
    setErrorMessage("");

    // Data validation
    if (stepNumber === 2) {
      if (
        !ssoURL ||
        !entityIDIDP ||
        !certificate ||
        emailDomains.length === 0
      ) {
        setErrorMessage("Enter the required details.");
        return;
      }

      const requestBody = {
        ssoURL,
        entityIDIDP,
        certificate,
        domains: emailDomains,
      };

      const settingsSSO = await postSettingsSSOSAMLByManual(requestBody);

      if (Object.keys(settingsSSO.error).length > 0) {
        console.error(settingsSSO.error.message);
        setErrorMessage(settingsSSO.error.message);

        return;
      }
    } else if (stepNumber === 3) {
      // Update onboarding status.
      await sendOnboardingStatus("is_sso_completed");
    } else if (stepNumber === totalSteps) {
      // Last step

      // Update onboarding status.
      await sendOnboardingStatus("is_scim_completed");

      navigate("/onboarding/"); // Go back to the welcome screen after completion.
    }

    const newStep = Math.min(totalSteps, stepNumber + 1);

    setStepNumber(newStep);
    setStepPercentage(newStep / totalSteps);
  };

  useEffect(() => {
    const loadSettingsSSO = async () => {
      const settingsSSO = await getSettingsSSO();

      if (Object.keys(settingsSSO.error).length > 0) {
        console.error(settingsSSO.error.message);
      } else {
        const {
          acsURL,
          entityIDDune,
          ssoURL,
          entityIDIDP,
          certificate,
          domains,
        } = settingsSSO.result;

        setACSURL(acsURL);
        setEntityIDDune(entityIDDune);
        setSSOURL(ssoURL);
        setEntityIDIDP(entityIDIDP);
        setCertificate(certificate);
        setEmailDomains(domains);
      }
    };

    if (!isInitialLoading && email) {
      loadSettingsSSO();
    }
  }, [isInitialLoading, email]);

  return (
    <StepTemplate
      title={
        stepNumber <= 3
          ? "Setup SSO with Google Workspace"
          : "Setup SCIM with Google Workspace"
      }
      mainContent={
        <div className="flex flex-col justify-between h-full">
          <div className="px-2">
            <div className="flex justify-between">
              <h3 className="h2">Steps</h3>
              <p className="h4 text-gray-surface-secondary">
                {stepNumber}/{totalSteps}
              </p>
            </div>
            <div className="mt-2 pt-4.75 pb-2">
              {stepNumber === 1 && <StepOne />}
              {stepNumber === 2 && (
                <StepTwo
                  ssoURL={ssoURL}
                  setSSOURL={setSSOURL}
                  entityIDIDP={entityIDIDP}
                  setEntityIDIDP={setEntityIDIDP}
                  certificate={certificate}
                  setCertificate={setCertificate}
                  emailDomains={emailDomains}
                  setEmailDomains={setEmailDomains}
                />
              )}
              {stepNumber === 3 && (
                <StepThree acsURL={acsURL} entityIDDune={entityIDDune} />
              )}
              {stepNumber === 4 && <StepFour />}
              {stepNumber === 5 && <StepFive />}
              {stepNumber === 6 && <StepSix />}
              {stepNumber === 7 && <StepSeven />}
            </div>
          </div>
          <div>
            <div
              className={`border-t-2 border-green`}
              style={{ width: `calc(${stepPercentage} * 100%)` }}
            ></div>
            <div
              className="flex items-center gap-2 pt-1.5 px-2"
              style={{ borderTop: "1px solid rgba(0, 248, 127, 0.30)" }}
            >
              <ButtonOutlinedGreen onClick={handleClickBack}>
                Back
              </ButtonOutlinedGreen>
              <ButtonContainedGreen onClick={handleClickNext}>
                Next
              </ButtonContainedGreen>
              {errorMessage && (
                <div>
                  <p className="description text-red">{errorMessage}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      }
      screenshotVideo={
        stepNumber === 1
          ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/google-workspace-step-1.mov"
          : stepNumber === 2
            ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/google-workspace-step-2.mov"
            : stepNumber === 4
              ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/google-workspace-step-4.mov"
              : stepNumber === 5
                ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/google-workspace-step-5.mov"
                : stepNumber === 6
                  ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/google-workspace-step-6.mov"
                  : stepNumber === 7
                    ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/google-workspace-step-7.mov"
                    : ""
      }
      screenshotImage={
        stepNumber === 3
          ? "https://d3oo9a669kwmx7.cloudfront.net/onboarding/google-workspace-step-3.png"
          : ""
      }
    />
  );
};

export default SetupSSOAndSCIMWithGoogleWorkspace;
