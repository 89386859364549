import { getResponseGet } from "../utils";

const getUser = async () => {
  let user = {
    result: {
      firstName: "",
      lastName: "",
      initials: "",
      email: "",
      role: "",
      organization: "",
      hasPaid: false,
      isOnboarded: false,
      isInCampaign: false,
      hasBRAStarted: false,
      isBRACompleted: false,
      startTimestamp: null,
      pictureFileName: "",
    },
    error: {},
  };

  try {
    const response = await getResponseGet("/personal_user_data/");

    if (response.status !== 200) {
      throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    const data = response.data;

    user.result.firstName = data.first_name ? data.first_name : "";
    user.result.lastName = data.last_name ? data.last_name : "";
    user.result.initials = `${user.result.firstName ? user.result.firstName.charAt(0) : ""}${user.result.lastName ? user.result.lastName.charAt(0) : ""}`;
    user.result.email = data.email ? data.email : "";
    user.result.role = data.role ? data.role : "";
    user.result.organization = data.company ? data.company : "";
    user.result.hasPaid = data.has_paid_access ? data.has_paid_access : false;

    const isDMICompleted =
      data.onboarding_status && data.onboarding_status.is_dmi_completed
        ? data.onboarding_status.is_dmi_completed
        : false;

    const isInitialTestingCompleted =
      data.onboarding_status &&
      data.onboarding_status.is_initial_testing_completed
        ? data.onboarding_status.is_initial_testing_completed
        : false;

    const isSCIMCompleted =
      data.onboarding_status && data.onboarding_status.is_scim_completed
        ? data.onboarding_status.is_scim_completed
        : false;

    const isSSOCompleted =
      data.onboarding_status && data.onboarding_status.is_sso_completed
        ? data.onboarding_status.is_sso_completed
        : false;

    const isWatchtowerCompleted =
      data.onboarding_status && data.onboarding_status.is_watchtower_completed
        ? data.onboarding_status.is_watchtower_completed
        : false;

    user.result.isOnboarded =
      isDMICompleted &&
      isInitialTestingCompleted &&
      isSCIMCompleted &&
      isSSOCompleted &&
      isWatchtowerCompleted;

    user.result.isInCampaign =
      data.is_bra_completed !== null && data.is_bra_completed !== null
        ? data.is_bra_completed && !data.is_bra_completed
        : false;
    user.result.hasBRAStarted = data.is_bra_completed && data.is_bra_completed;
    user.result.isBRACompleted = data.is_bra_completed && data.is_bra_completed;
    user.result.startTimestamp = data.start_timestamp && data.start_timestamp;
    user.result.pictureFileName = data.picture_file_name
      ? data.picture_file_name
      : "";
  } catch (error) {
    user.error = error;
  }

  return user;
};

export default getUser;
