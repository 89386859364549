import React from "react";

const StepTemplate = ({
  title,
  mainContent,
  screenshotVideo,
  screenshotImage,
}) => {
  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex flex-col w-full h-full min-h-[calc(100vh-69px)] pt-2 pl-4 pr-2">
        <div>
          <h2 className="h2">{title}</h2>
        </div>
        <div className="flex mt-6.75 rounded-medium">
          {screenshotVideo && (
            <video
              className="w-full rounded-medium"
              controls
              autoPlay
              loop
              muted
              disablePictureInPicture
              controlsList="nodownload noplaybackrate"
              onContextMenu={(e) => e.preventDefault()} // Disable right-click
              key={screenshotVideo}
            >
              <source src={screenshotVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          {screenshotImage && (
            <img className="rounded-medium" src={screenshotImage} alt={title} />
          )}
        </div>
      </div>
      <div className="w-full pt-2 pb-1.5 border-l border-border-color">
        {mainContent}
      </div>
    </div>
  );
};

export default StepTemplate;
