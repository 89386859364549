import React from "react";

import { CardHeader, CardWithLoadingState, DataTable } from "../../../..";

const headers = [
  {
    id: "type",
    label: "type",
    isSortable: true,
    align: "center",
  },
  {
    id: "protectionStatus",
    label: "Status",
    isSortable: true,
    align: "left",
  },
];

const CardProtectionStatusUser = ({ protectionStatuses }) => {
  return (
    <CardWithLoadingState loadingVariable={protectionStatuses}>
      <CardHeader header="Protection Status" />
      <DataTable
        data={protectionStatuses}
        headers={headers}
        rowsPerPage={2}
        defaultOrder="asc"
        defaultOrderBy="type"
        disablePagination
        disableSearchBar
      />
      {/* <div className="flex justify-end items-start lg:items-end xl:items-start px-0.625 py-0.875 w-full">
        <div className="flex flex-row">
          <ButtonOutlinedGreen isSmall>
            View all
          </ButtonOutlinedGreen>
        </div>
      </div> */}
    </CardWithLoadingState>
  );
};

export default CardProtectionStatusUser;
