import React from "react";

import { List } from "../../../../../components";

const StepSeven = () => {
  const stepsSettings = [
    {
      description: (
        <>
          Enable <span className="font-medium">Create Users</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Enable <span className="font-medium">Update User Attributes</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Enable <span className="font-medium">Deactivate Users</span>.
        </>
      ),
    },
  ];

  const stepsUserAttributes = [
    {
      description:
        "Ensure the attributes from Okta match the required attributes in Dune Security.",
    },
  ];

  return (
    <div>
      <div>
        <div>
          <h4 className="font-medium onboarding-step">
            Configure Provisioning Settings
          </h4>
        </div>
        <div className="mt-1">
          <List
            data={stepsSettings}
            keyName="description"
            textSize="onboarding-step"
          />
        </div>
      </div>
      <div className="mt-2">
        <div>
          <h4 className="font-medium onboarding-step">Map User Attributes</h4>
        </div>
        <div className="mt-1">
          <List
            data={stepsUserAttributes}
            keyName="description"
            textSize="onboarding-step"
          />
        </div>
      </div>
    </div>
  );
};

export default StepSeven;
