import React from "react";

import { List } from "../../../../../components";

const StepOne = () => {
  const steps = [
    {
      description: "Login to your Azure Portal.",
    },
    {
      description: (
        <>
          Under Azure Services, select{" "}
          <span className="font-medium">Microsoft Entra ID</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <List data={steps} keyName="description" textSize="onboarding-step" />
    </div>
  );
};

export default StepOne;
