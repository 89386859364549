import React, { useCallback } from "react";

import { useDropzone } from "react-dropzone";

import { ButtonOutlinedGreen, ButtonTextRed } from "../buttons";

import { FileUploadIcon, FileUploadIconSmall } from "../../../utils/icons";

const FileUpload = ({
  selectedFiles,
  setSelectedFiles,
  multiple = false, // Controls multiple file uploads
  fileType,
  accept = {},
  isSmall = false,
}) => {
  const handleClickRemoveFile = (fileIndex) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== fileIndex),
    );
  };

  const handleDropFiles = useCallback(
    (acceptedFiles) => {
      // Handle file upload logic here.
      if (setSelectedFiles) {
        if (multiple) {
          // Append new files to the existing list
          setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
        } else {
          // Replace with the first file if multiple is not allowed
          setSelectedFiles([acceptedFiles[0]]);
        }
      }
    },
    [setSelectedFiles, multiple],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDropFiles,
    accept,
    maxSize: 10 * 1024 * 1024, // Set maximum file size to 10 MB.
    multiple, // Allow multiple files based on the prop
  });

  return (
    <div className="flex flex-col items-center">
      <div
        className={`flex ${!isSmall ? "flex-col" : "flex-row"} items-center justify-center ${!isSmall ? "py-2 px-4" : "py-1 px-2"} border border-dashed rounded-medium cursor-pointer ${!isSmall ? "max-w-[450px]" : "max-w-full"} w-full
          ${isDragActive ? "border-green" : "border-border-color"} 
          transition-colors duration-200 ease-in-out`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>{!isSmall ? <FileUploadIcon /> : <FileUploadIconSmall />}</div>
        <div className={`flex ${!isSmall ? "flex-row" : "flex-col ml-2"}`}>
          <div className={`${!isSmall ? "mt-1" : ""} text-center`}>
            <p className={!isSmall ? "h4" : "body"}>
              {isDragActive
                ? "Drop the file here"
                : "Select a file or Drag and drop here"}
            </p>
            <p className="body mt-0.5">{fileType}, no more than 10 MB</p>
          </div>
          <div className={!isSmall ? "mt-2" : "flex justify-center mt-0.5"}>
            <ButtonOutlinedGreen>
              {multiple ? "Select Files" : "Select File"}
            </ButtonOutlinedGreen>
          </div>
        </div>
      </div>
      {selectedFiles?.length > 0 && (
        <div className="mt-2 w-full max-w-[450px]">
          <p className="body">Selected {multiple ? "Files" : "File"}:</p>
          <ul className="mt-1">
            {selectedFiles.map((file, index) => (
              <li
                className="flex items-center justify-between mt-0.25 first:mt-0"
                key={`attachment-file-${index}`}
              >
                <span className="description">{file.name}</span>
                {multiple && (
                  <ButtonTextRed
                    isSmall
                    title="Remove additional attachment."
                    onClick={() => handleClickRemoveFile(index)}
                  >
                    Remove
                  </ButtonTextRed>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
