import React from "react";

const MicrosoftEntraIDBrandMarkIconLarge = () => {
  return (
    <svg
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="EntraID Logo" clip-path="url(#clip0_2115_12930)">
        <path
          id="Vector"
          d="M16.0545 1.85284C17.5426 1.89118 18.1888 2.37803 19.2323 3.43351C19.735 4.00072 20.2295 4.57559 20.7153 5.15788C20.9318 5.40585 21.1483 5.65381 21.3714 5.9093C21.803 6.40511 22.2311 6.90417 22.6558 7.40614C23.441 8.32832 24.2425 9.23415 25.0465 10.1394C26.3127 11.5681 27.5769 12.9977 28.8098 14.4563C29.2903 15.0186 29.791 15.5629 30.2957 16.1028C31.1082 17.3923 31.0989 18.2179 30.8842 19.7192C29.5741 21.4407 27.5949 22.4014 25.7762 23.4793C23.3297 24.9403 20.9369 26.4137 18.6379 28.1075C17.2429 28.7413 16.1589 28.8867 14.6892 28.5326C12.9729 27.6861 11.3884 26.645 9.79304 25.5868C9.3609 25.307 8.92837 25.0279 8.49543 24.7493C7.66604 24.2134 6.83864 23.6742 6.01351 23.1314C5.05289 22.5035 4.08395 21.8949 3.10788 21.2924C1.88384 20.4856 1.88384 20.4856 1.50721 19.7192C1.3399 18.1106 1.3215 17.3102 2.2384 15.9606C2.72706 15.462 2.72706 15.462 3.22558 14.9532C3.57125 14.5907 3.91693 14.2281 4.27308 13.8545C4.76829 13.3433 4.76829 13.3433 5.2735 12.8217C5.72554 12.3071 6.17343 11.7887 6.61523 11.265C7.88076 9.78353 9.17264 8.32718 10.4654 6.87027C11.4564 5.7464 12.4328 4.61034 13.4048 3.46944C14.5868 2.1103 14.5868 2.1103 16.0545 1.85284Z"
          fill="#B3B3B3"
        />
        <path
          id="Vector_2"
          d="M16.1966 2.0914C17.6351 2.28871 18.36 2.56867 19.3509 3.64812C19.6888 4.01266 19.6888 4.01266 20.0335 4.38457C20.9126 5.37922 21.7846 6.37886 22.6434 7.39173C23.4338 8.31768 24.2393 9.22847 25.0474 10.1384C26.3136 11.5671 27.5778 12.9967 28.8107 14.4553C29.2912 15.0177 29.7919 15.562 30.2966 16.1019C31.1091 17.3914 31.0998 18.2169 30.8851 19.7183C29.5751 21.4398 27.5958 22.4005 25.7771 23.4783C23.3306 24.9393 20.9378 26.4128 18.6388 28.1066C17.2987 28.7154 16.113 28.8043 14.6901 28.5317C13.5376 28.0213 12.5268 27.3565 11.4888 26.6397C11.215 26.4601 10.9411 26.2806 10.659 26.0956C9.98334 25.6465 9.32213 25.1751 8.66406 24.6998C8.66406 24.5733 8.66406 24.4468 8.66406 24.3166C8.8917 24.3145 9.11934 24.3124 9.35387 24.3102C12.1362 24.1717 13.9348 23.2549 16.2437 21.73C16.5607 21.5285 16.8778 21.3269 17.2044 21.1193C18.1279 20.5308 19.0454 19.9333 19.9629 19.3351C20.9659 18.6941 21.9709 18.0567 22.9759 17.4191C21.9267 15.9068 20.9309 14.519 19.5598 13.2968C16.141 10.1433 16.141 10.1433 15.9053 7.43215C15.867 5.63587 15.9967 3.8753 16.1966 2.0914Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M16.1953 9.37256C18.7536 11.5416 18.7536 11.5416 19.7733 12.7494C19.9714 12.9811 20.1694 13.2128 20.3735 13.4515C21.4032 14.7459 22.3759 16.0825 23.3513 17.4196C22.3016 18.1249 21.2496 18.8264 20.197 19.5272C19.8979 19.7282 19.5989 19.9292 19.2907 20.1364C19.0043 20.3265 18.7178 20.5167 18.4227 20.7127C18.1584 20.8892 17.8941 21.0658 17.6218 21.2478C16.9486 21.6347 16.9486 21.6347 16.1953 21.6347C16.1953 17.5882 16.1953 13.5417 16.1953 9.37256Z"
          fill="#A6A6A6"
        />
        <path
          id="Vector_4"
          d="M15.8145 9.37256C15.9388 9.37256 16.063 9.37256 16.1911 9.37256C16.1911 13.4191 16.1911 17.4656 16.1911 21.6347C14.9406 21.1258 14.0489 20.7568 12.9706 20.0391C12.7388 19.886 12.507 19.7328 12.2681 19.5751C12.0324 19.417 11.7967 19.2589 11.5539 19.0961C11.3109 18.935 11.0679 18.774 10.8175 18.6081C10.2224 18.2134 9.62866 17.8167 9.03516 17.4196C10.455 15.3731 12.0063 13.5113 13.6724 11.6717C13.8757 11.4464 14.079 11.221 14.2885 10.9889C14.7886 10.4419 15.3005 9.90608 15.8145 9.37256Z"
          fill="#E5E5E5"
        />
        <path
          id="Vector_5"
          d="M16.1966 22.0184C16.1966 24.1681 16.1966 26.3178 16.1966 28.5327C13.6529 28.1013 13.6529 28.1013 12.5628 27.3711C12.3321 27.2185 12.1015 27.0659 11.8639 26.9086C11.6314 26.749 11.3988 26.5895 11.1592 26.4251C10.9179 26.2656 10.6766 26.106 10.428 25.9416C9.8311 25.5415 9.2463 25.1227 8.66406 24.7007C8.66406 24.5743 8.66406 24.4478 8.66406 24.3176C8.89662 24.3121 9.12917 24.3067 9.36877 24.3011C11.6636 24.1696 13.2213 23.6566 15.1741 22.424C15.82 22.0184 15.82 22.0184 16.1966 22.0184Z"
          fill="#B3B3B3"
        />
        <path
          id="Vector_6"
          d="M16.1953 2.0914C18.851 2.6318 20.1522 4.23039 21.6329 6.47416C21.8271 6.7982 22.0213 7.12224 22.2214 7.45609C21.9757 7.47042 21.73 7.48474 21.4769 7.4995C21.1478 7.52469 20.8186 7.54989 20.4795 7.57584C19.9944 7.60918 19.9944 7.60918 19.4996 7.6432C18.1993 7.83647 18.1993 7.83647 16.1953 8.98886C16.1953 6.7127 16.1953 4.43654 16.1953 2.0914Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2115_12930">
          <rect
            width="31.885"
            height="29"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicrosoftEntraIDBrandMarkIconLarge;
