import React from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonOutlinedGreen,
  CardHeader,
  CardWithLoadingState,
  DonutChart,
  Legend,
} from "../../../..";

const CardDeviceProtectionStatus = ({ deviceProtectionStatuses }) => {
  const navigate = useNavigate();

  const goToMoreDetailsPage = () => {
    navigate("/insights/organization/device-protection-status/");
  };

  const handleClickCell = () => {
    goToMoreDetailsPage();
  };

  const handleClickDetails = () => {
    goToMoreDetailsPage();
  };

  return (
    <CardWithLoadingState loadingVariable={deviceProtectionStatuses}>
      <CardHeader header="Device Protection Status" />
      <div className="flex flex-col justify-between h-full">
        <div>
          <div>
            <p className="description text-gray-surface-contrast">
              Check your organization's Device Protocol Status to safeguard
              critical accounts. Ensure comprehensive protection by ensuring all
              devices are provisioned.
            </p>
          </div>
          <div className="flex justify-center py-0.5">
            <div className="max-w-[400px] w-full">
              <div className="flex items-center gap-1 py-0.5">
                <div className="flex max-w-[224px] w-full">
                  <DonutChart
                    data={deviceProtectionStatuses}
                    title="Total Devices"
                    minHeight={224}
                    onClickCell={handleClickCell}
                  />
                </div>
                <div className="w-[164px]">
                  <Legend data={deviceProtectionStatuses} />
                </div>
              </div>
              <div className="mt-2">
                <p className="detailed">
                  Unprovisioned and Deprovisioned devices pose a critical
                  security risk.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-start lg:items-end xl:items-start px-0.625 py-0.875 w-full">
          <div className="flex flex-row lg:flex-col xl:flex-row gap-0.625">
            {/* <ButtonOutlinedGreen isSmall>Assign Training</ButtonOutlinedGreen>
          <ButtonOutlinedGreen isSmall>Send Reminder</ButtonOutlinedGreen> */}
            <ButtonOutlinedGreen isSmall onClick={handleClickDetails}>
              Details
            </ButtonOutlinedGreen>
          </div>
        </div>
      </div>
    </CardWithLoadingState>
  );
};

export default CardDeviceProtectionStatus;
