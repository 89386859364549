export const ANOMALOUS_LOGIN_BEHAVIOR = [
  {
    firstName: "Michael",
    lastName: "Waite",
    initials: "MW",
    activity: "New Location",
    riskScore: 97,
  },
  {
    firstName: "Daniel",
    lastName: "Bray",
    initials: "DB",
    activity: "High Number of Logins",
    riskScore: 55,
  },
  {
    firstName: "Anna",
    lastName: "Philips",
    initials: "AP",
    activity: "New Location",
    riskScore: 45,
  },
  {
    firstName: "Samantha",
    lastName: "Ross",
    initials: "SR",
    activity: "New Location",
    riskScore: 27,
  },
  {
    firstName: "Lawrence",
    lastName: "Church",
    initials: "LC",
    activity: "High Number of Logins",
    riskScore: 20,
  },
];
