import React from "react";

const VideoTag = ({ trainingType }) => {
  let bgColor;

  switch (trainingType) {
    case "Functional Training":
      bgColor = "bg-blue";
      break;
    case "Compliance Modules":
      bgColor = "bg-mustard";
      break;
    case "Security Awareness":
      bgColor = "bg-maroon";
      break;
    default:
      bgColor = "bg-gray";
  }

  return (
    <div
      className={`text-white rounded-full w-fit px-0.5 py-0.25 text-h6.5 ${bgColor}`}
    >
      {trainingType}
    </div>
  );
};

export default VideoTag;
