import React from "react";

import { List } from "../../../../../components";

const StepOne = () => {
  const stepsCreate = [
    {
      description: (
        <>
          Go to the <span className="font-medium">Google Cloud Console</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Click on <span className="font-medium">IAM & Admin</span> and Select{" "}
          <span className="font-medium">Service Accounts</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Click on <span className="font-medium">Create Service Account</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Fill in Service Account Name:{" "}
          <span className="font-medium">Dune DMI</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Create</span> and{" "}
          <span className="font-medium">Continue</span>.
        </>
      ),
    },
  ];

  const stepsAccess = [
    {
      description: (
        <>
          Under Grant this service account access to project, click{" "}
          <span className="font-medium">Continue</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Done</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div>
          <h4 className="font-medium onboarding-step">
            Create a Service Account
          </h4>
        </div>
        <div className="mt-1">
          <List
            data={stepsCreate}
            keyName="description"
            textSize="onboarding-step"
          />
        </div>
      </div>
      <div className="mt-2">
        <div>
          <h4 className="font-medium onboarding-step">
            Grant Access to Project
          </h4>
        </div>
        <div className="mt-1">
          <List
            data={stepsAccess}
            keyName="description"
            textSize="onboarding-step"
          />
        </div>
      </div>
    </div>
  );
};

export default StepOne;
