import React, { useEffect, useState } from "react";

import {
  BadgeGray,
  BadgeGreen,
  BadgeOrange,
  BadgeRed,
  BadgeYellow,
  CardWithLoadingState,
} from "../../../../../common";

import { colors } from "../../../../../../styles";

function CardRiskScore({ riskScore, riskLevel, description }) {
  const [animatedRiskScore, setAnimatedRiskScore] = useState(0);
  const [borderColor, setBorderColor] = useState(null);
  const [riskGaugeLevel, setRiskGaugeLevel] = useState(0);

  useEffect(() => {
    if (riskScore) {
      let start = 0;
      const duration = 1000;
      const increment = riskScore / (duration / 50);

      const interval = setInterval(() => {
        if (!borderColor) {
          setBorderColor(colors.greenFade); // Start with the green border color.
        }

        start += increment;

        if (start >= riskScore) {
          setAnimatedRiskScore(riskScore);
          clearInterval(interval);

          // Risk gauge level
          const updatedRiskGaugeLevel = Math.round(riskScore / (100 / 32));
          if (updatedRiskGaugeLevel !== riskGaugeLevel) {
            setRiskGaugeLevel(Math.round(riskScore / (100 / 32)));
          }

          // Border color
          if (riskScore > 75) {
            if (borderColor !== colors.redFade) {
              setBorderColor(colors.redFade);
            }
          } else if (riskScore > 50) {
            if (borderColor !== colors.orangeFade) {
              setBorderColor(colors.orangeFade);
            }
          } else if (riskScore > 25) {
            if (borderColor !== colors.yellowFade) {
              setBorderColor(colors.yellowFade);
            }
          }
        } else {
          setAnimatedRiskScore(start);

          // Risk gauge level
          const updatedRiskGaugeLevel = Math.round(start / (100 / 32));
          if (updatedRiskGaugeLevel !== riskGaugeLevel) {
            setRiskGaugeLevel(Math.round(start / (100 / 32)));
          }

          // Border color
          if (start > 75) {
            if (borderColor !== colors.redFade) {
              setBorderColor(colors.redFade);
            }
          } else if (start > 50) {
            if (borderColor !== colors.orangeFade) {
              setBorderColor(colors.orangeFade);
            }
          } else if (start > 25) {
            if (borderColor !== colors.yellowFade) {
              setBorderColor(colors.yellowFade);
            }
          }
        }
      }, 50);

      return () => clearInterval(interval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskScore]);

  return (
    <CardWithLoadingState
      loadingVariable={riskScore}
      borderColor={riskScore ? borderColor : "rgba(194, 191, 184, 0.35)"}
      minHeight="174.17px"
    >
      <div>
        <h3 className="text-center card-header">Risk Score</h3>
      </div>
      <div className="mt-1">
        <div className="flex flex-row justify-between">
          {new Array(32).fill(null).map((_, index) => (
            <div
              className="rounded-[2px]"
              style={{
                background:
                  riskGaugeLevel === index + 1
                    ? index >= 24
                      ? "#ef3c1f"
                      : index >= 16
                        ? "#f59a31"
                        : index >= 8
                          ? "#fee442"
                          : "#02ff82"
                    : riskGaugeLevel < index + 1
                      ? `linear-gradient(0deg, rgba(35, 35, 35, 0.50) 0%, rgba(35, 35, 35, 0.50) 100%), ${
                          index >= 24
                            ? colors.red
                            : index >= 16
                              ? colors.orange
                              : index >= 8
                                ? colors.yellow
                                : colors.green
                        }`
                      : index >= 24
                        ? colors.red
                        : index >= 16
                          ? colors.orange
                          : index >= 8
                            ? colors.yellow
                            : colors.green,
                boxShadow:
                  riskGaugeLevel === index + 1
                    ? "0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4)"
                    : "1px 1px 3px 0px rgba(0, 0, 0, 0.30)",
                height: "18.182px",
                width: "3px",
              }}
              key={`card-risk-score-level-${index}`}
            />
          ))}
        </div>
      </div>
      {riskScore !== 0 ? (
        <div className="flex justify-center items-center mt-0.5 w-full">
          <div className="ml-0.5 w-[78px]">
            <p className="text-white font-sans text-[3.5rem] leading-[50px] font-medium">
              {Math.round(animatedRiskScore)}
            </p>
          </div>
          <div>
            {riskLevel === "Low" && <BadgeGreen text={riskLevel} />}
            {riskLevel === "Moderate" && <BadgeYellow text={riskLevel} />}
            {riskLevel === "High" && <BadgeOrange text={riskLevel} />}
            {riskLevel === "Severe" && <BadgeRed text={riskLevel} />}
          </div>
        </div>
      ) : (
        <div className="flex justify-center mt-1 mb-0.5">
          <BadgeGray text="Ongoing Initial Test" />
        </div>
      )}
      <div className="mt-0.5">
        <p className="detailed text-[0.5rem]">{description}</p>
      </div>
    </CardWithLoadingState>
  );
}

export default CardRiskScore;
