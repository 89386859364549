import React from "react";

const GreenRightArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M7.875 7.5L5 4.625L5.875 3.75L9.625 7.5L5.875 11.25L5 10.375L7.875 7.5Z"
        fill="#00FF82"
      />
    </svg>
  );
};

export default GreenRightArrowIcon;
