import React, { useState } from "react";

import {
  Alert,
  Box,
  Grid,
  Link,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import isEmail from "validator/lib/isEmail";

import { Translate } from "react-auto-translate";

import { LoadingSpinner, HomeIllustration } from "../../../utils/icons";
import {
  ButtonContainedGreen,
  CustomLink,
  FadeInSection,
} from "../../../components";

import { constants } from "../../../constants";
import { postContactUs } from "../../../api/database/post-contact-us";

import "./contact-us.css";

function ContactUs() {
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Messages (error and success)
  const [messageError, setMessageError] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  // Validation
  const [isErrorFirstName, setIsErrorFirstName] = useState(false);
  const [isErrorLastName, setIsErrorLastName] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorSubject, setIsErrorSubject] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const handleClickSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setMessageError("");
    setMessageSuccess("");
    setIsErrorFirstName(false);
    setIsErrorLastName(false);
    setIsErrorEmail(false);
    setIsErrorSubject(false);
    setIsErrorMessage(false);

    let isError = false;

    const data = new FormData(event.currentTarget);
    const firstName = data.get("first-name");
    const lastName = data.get("last-name");
    const email = data.get("email");
    const subject = data.get("subject");
    const message = data.get("message");

    if (!firstName) {
      setIsErrorFirstName(true);
      isError = true;
    }

    if (!lastName) {
      setIsErrorLastName(true);
      isError = true;
    }

    if (!email) {
      setIsErrorEmail(true);
      isError = true;
    }

    if (!subject) {
      setIsErrorSubject(true);
      isError = true;
    }

    if (!message) {
      setIsErrorMessage(true);
      isError = true;
    }

    if (isError) {
      setMessageError(constants.ERROR_EMPTY_FIELDS);
    } else if (!isEmail(email)) {
      setIsErrorEmail(true);
      setMessageError("Please enter a valid email.");
    } else {
      const requestBody = {
        first_name: firstName,
        last_name: lastName,
        email,
        subject,
        message,
      };

      const contactMessage = await postContactUs(requestBody);

      if (Object.keys(contactMessage.error).length > 0) {
        setMessageError(constants.ERROR_DEFAULT);
      } else {
        setMessageSuccess("Your message has been sent.");
      }
    }

    setIsLoading(false);
  };

  const handleCloseSnackbarError = () => {
    setMessageError("");
  };

  const handleCloseSnackbarSuccess = () => {
    setMessageSuccess("");
  };

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading} />;
  }

  return (
    <>
      <FadeInSection>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={{
            xs: "40px",
            md: "80px",
          }}
          marginBottom={{
            xs: "40px",
            md: "80px",
          }}
          padding="0 28px"
        >
          <Box maxWidth="1152px" width="100%">
            <Stack
              direction={{
                xs: "column",
                md: "row",
              }}
              alignItems="center"
            >
              <Box
                maxWidth={{
                  xs: "100%",
                  md: "600px",
                }}
                width="100%"
              >
                <Typography
                  role="heading"
                  aria-level="1"
                  component="h1"
                  className="dune-text-header-uppercase"
                >
                  <Translate>Contact</Translate>
                </Typography>
                <Typography
                  role="heading"
                  aria-level="2"
                  variant="h2"
                  component="h2"
                  className="dune-text-header-main"
                  marginTop="20px"
                >
                  <Translate>Have a question?</Translate>
                </Typography>
                <Typography variant="body" component="p" marginTop="20px">
                  <Translate>Leave a message below or email us at</Translate>{" "}
                  <Link
                    className="demo-link-email"
                    href="mailto:info@dune.security"
                    title="Email info@dune.security."
                  >
                    <Translate>info@dune.security</Translate>
                  </Link>
                  .
                </Typography>
                <Box
                  component="form"
                  noValidate
                  marginTop="40px"
                  onSubmit={handleClickSubmit}
                >
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="filled"
                        className="dune-text-field-filled"
                        name="first-name"
                        placeholder="First name"
                        type="text"
                        error={isErrorFirstName}
                        required
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} marginTop={{ xs: "20px", md: 0 }}>
                      <TextField
                        variant="filled"
                        className="dune-text-field-filled"
                        name="last-name"
                        placeholder="Last name"
                        type="text"
                        error={isErrorLastName}
                        required
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} marginTop="20px">
                      <TextField
                        variant="filled"
                        className="dune-text-field-filled"
                        name="email"
                        placeholder="Email"
                        type="text"
                        error={isErrorEmail}
                        required
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} marginTop="20px">
                      <TextField
                        variant="filled"
                        className="dune-text-field-filled"
                        name="subject"
                        placeholder="Subject"
                        type="text"
                        error={isErrorSubject}
                        required
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} marginTop="20px">
                      <TextField
                        variant="filled"
                        className="dune-text-field-filled dune-text-field-filled-multiline"
                        name="message"
                        placeholder="Leave a message..."
                        type="text"
                        error={isErrorMessage}
                        multiline
                        rows={4}
                        required
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} marginTop="20px">
                      <Box maxWidth="108px" width="100%">
                        <ButtonContainedGreen
                          disabled={isPrivacyPolicyAccepted ? false : true}
                          title="Submit"
                        >
                          Submit
                        </ButtonContainedGreen>
                      </Box>
                    </Grid>
                    <div className="flex justify-center ml-1">
                      <input
                        type="checkbox"
                        className="mr-0.5 accent-green"
                        checked={isPrivacyPolicyAccepted}
                        onChange={() =>
                          setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted)
                        }
                      />
                      <div className="text-white text-h6 mt-1">
                        By submitting this form, I consent to Dune Security
                        contacting me regarding my inquiry and for future
                        marketing communications in accordance with its{" "}
                        <CustomLink href="https://dune.security/privacy-policy/">
                          Privacy Policy
                        </CustomLink>
                        .
                      </div>
                    </div>
                  </Grid>
                </Box>
              </Box>
              <Box
                maxWidth="500px"
                width="100%"
                marginLeft={{
                  xs: 0,
                  md: "80px",
                }}
                marginTop={{
                  xs: "40px",
                  md: 0,
                }}
              >
                <HomeIllustration />
              </Box>
            </Stack>
          </Box>
        </Box>
      </FadeInSection>
      <Snackbar
        open={messageSuccess !== ""}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert className="dune-alert-success" severity="success">
          {messageSuccess}
        </Alert>
      </Snackbar>
      <Snackbar open={messageError !== ""} onClose={handleCloseSnackbarError}>
        <Alert className="dune-alert-error" severity="error">
          {messageError}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ContactUs;
