import React from "react";

import { FileUpload, List } from "../../../../../components";

const StepTwo = ({ selectedJSONKeyFile, setSelectedJSONKeyFile }) => {
  const steps = [
    {
      description: "Return to the Service Accounts Page.",
    },
    {
      description: "Click on the newly created service account.",
    },
    {
      description: (
        <>
          Ensure the Service account status is set to{" "}
          <span className="font-medium">Enabled</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Navigate to the <span className="font-medium">Keys</span> section.
        </>
      ),
    },
    {
      description: (
        <>
          Click <span className="font-medium">Add Key</span> and select{" "}
          <span className="font-medium">Create new key</span>.
        </>
      ),
    },
    {
      description: (
        <>
          Choose <span className="font-medium">JSON</span> as the key type and
          click <span className="font-medium">Create</span>.
        </>
      ),
    },
  ];

  return (
    <div>
      <div>
        <div>
          <h4 className="font-medium onboarding-step">Generate a JSON Key</h4>
        </div>
        <div className="mt-1">
          <List data={steps} keyName="description" textSize="onboarding-step" />
        </div>
      </div>
      <div className="mt-2">
        <FileUpload
          selectedFiles={selectedJSONKeyFile}
          setSelectedFiles={setSelectedJSONKeyFile}
          fileType="JSON"
          accept={{ "application/json": [".json"] }}
          isSmall
        />
      </div>
    </div>
  );
};

export default StepTwo;
