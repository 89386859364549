import React from "react";
import { DotLabelGreen, DotLabelOrange, DotLabelYellow } from "../..";

import {
  capitalizeFirstWords,
  capitalizeOnlyFirstWords,
} from "../../../utils/helper-functions/capitalize";
import { truncate } from "../../../utils/helper-functions/truncate";
import { companyNameMapping } from "../../../utils/helper-functions/company-name-mapping";

const ConfigureTestingCard = ({
  item,
  index,
  toggleSwitch,
  toggleAssetViewer,
}) => {
  const changeExtensionToPdf = (filename) => {
    if (filename.endsWith(".html")) {
      return filename.replace(".html", ".pdf");
    }
    return filename;
  };

  const getFormattedCompanyName = (incomingName) => {
    if (!incomingName) return "";
    const mapping = companyNameMapping.find(
      (map) => map.incomingName?.toLowerCase() === incomingName.toLowerCase(),
    );
    return mapping
      ? mapping.formattedName
      : capitalizeOnlyFirstWords(incomingName);
  };

  return (
    <div className="border border-gray border-opacity-35 rounded-medium overflow-hidden">
      <div
        className="relative overflow-hidden shadow-lg rounded-t-medium"
        style={{
          scrollbarWidth: "none", // Firefox
          msOverflowStyle: "none", // IE/Edge
        }}
      >
        <div
          className="overflow-hidden cursor-pointer aspect-w-4 aspect-h-5"
          style={{
            scrollbarWidth: "none", // Firefox
            msOverflowStyle: "none", // IE/Edge
          }}
          onClick={() =>
            toggleAssetViewer(item.content, item.title, item.file_name)
          }
        >
          <embed
            src={`https://d8km0djkqxooq.cloudfront.net/${changeExtensionToPdf(item.file_name)}`}
            title="pdf"
            className="flex justify-center w-full h-10 pointer-events-none"
            style={{
              scrollbarWidth: "none", // Firefox
              msOverflowStyle: "none", // IE/Edge
            }}
          />
        </div>
      </div>
      <div className="px-0.5 py-1">
        <div className="flex items-center justify-between">
          <div className="pt-0.25 text-h4">
            {getFormattedCompanyName(item.impersonation)}
          </div>
          <div className="flex items-center">
            <div className="text-h6">
              {item.is_active === true ? "Active" : "Inactive"}
            </div>
            {console.log("item.is_active", item.is_active)}
            <div
              onClick={() => toggleSwitch(index)}
              className={`w-2.75 h-1.5 flex items-center rounded-full p-0.25 cursor-pointer transition-colors duration-300 ml-0.25 ${
                item.is_active ? "bg-green" : "bg-gray"
              }`}
            >
              <div
                className={`bg-white w-1 h-1 rounded-full shadow-md transform transition-transform duration-300 ${
                  item.is_active ? "translate-x-1.25" : "-translate-x-.125"
                }`}
              ></div>
            </div>
          </div>
        </div>
        <div className="text-h5 mt-0.25">
          {capitalizeOnlyFirstWords(item.title)}
        </div>

        <div className="flex justify-between items-start text-left mt-0.625">
          <div>
            <p className="tracking-wider uppercase text-gray text-h6">
              Difficulty
            </p>
            {item.fidelity === "easy" ? (
              <DotLabelGreen label={capitalizeFirstWords(item.fidelity)} />
            ) : item.fidelity === "medium" ? (
              <DotLabelYellow label={capitalizeFirstWords(item.fidelity)} />
            ) : (
              <DotLabelOrange label={capitalizeFirstWords(item.fidelity)} />
            )}
          </div>
          <div>
            <p className="tracking-wider uppercase text-gray text-h6">Motive</p>
            <p className="text-h6">
              {truncate(capitalizeFirstWords(item.motivational_factor), 15)}
            </p>
          </div>
          <div>
            <p className="tracking-wider uppercase text-gray text-h6">Method</p>
            <p className="text-h6">
              {truncate(capitalizeFirstWords(item.vector), 15)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureTestingCard;
