import React from "react";

import CustomLink from "../custom-link";

const CustomLinkGreen = ({ children, href, title, target }) => {
  return (
    <CustomLink
      textColor="text-green"
      href={href}
      title={title}
      target={target}
    >
      {children}
    </CustomLink>
  );
};

export default CustomLinkGreen;
