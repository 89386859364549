import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { StepButton } from "./utils";

import { getDMIEmailProvider, getOnboardingStatus } from "../../../api";

import { ButtonOutlinedGreen, OnboardingTemplate } from "../../../components";

const Welcome = ({ isInitialLoading, email, organization }) => {
  const navigate = useNavigate();

  const [selectedStepNumber, setSelectedStepNumber] = useState(1);
  const [emailProvider, setEmailProvider] = useState("");

  const steps = [
    {
      description: "Set up SSO and SCIM Provisioning",
      path: "/onboarding/setup-sso-and-scim/",
    },
    {
      description: "DMI Setup",
      path: "/onboarding/setup-dmi/",
    },
    {
      description: "Setup Dune Watchtower",
      path: emailProvider
        ? `/onboarding/setup-watchtower/${emailProvider}/`
        : "",
    },
    {
      description: "Start Initial Testing",
      path: "/onboarding/launch-initial-testing/",
    },
  ];

  const handleClickGetStarted = () => {
    if (steps[selectedStepNumber - 1].path) {
      navigate(steps[selectedStepNumber - 1].path);
    }
  };

  useEffect(() => {
    const loadDMIEmailProvider = async () => {
      const getDMIEmailProviderResponse = await getDMIEmailProvider();

      if (Object.keys(getDMIEmailProviderResponse.error).length > 0) {
        console.error(getDMIEmailProviderResponse.error.message);

        return false;
      }

      const { dmiEmailProvider } = getDMIEmailProviderResponse.result;

      if (!dmiEmailProvider) {
        return false;
      }

      setEmailProvider(dmiEmailProvider);

      return true;
    };

    const loadOnboardingStatus = async () => {
      const getOnboardingStatusResponse = await getOnboardingStatus();

      if (Object.keys(getOnboardingStatusResponse.error).length > 0) {
        console.error(getOnboardingStatusResponse.error.message);
      } else {
        const {
          isSSOCompleted,
          isSCIMCompleted,
          isDMICompleted,
          isWatchtowerCompleted,
          isInitialTestingCompleted,
        } = getOnboardingStatusResponse.result;

        console.log(getOnboardingStatusResponse.result);

        if (!isSSOCompleted || !isSCIMCompleted) {
          setSelectedStepNumber(1);
          return;
        } else if (!isDMICompleted) {
          setSelectedStepNumber(2);
          return;
        }

        const hasEmailProvider = await loadDMIEmailProvider();

        if (!isWatchtowerCompleted) {
          if (hasEmailProvider) {
            setSelectedStepNumber(3);
            return;
          } else {
            setSelectedStepNumber(2);
            return;
          }
        } else if (!isInitialTestingCompleted) {
          setSelectedStepNumber(4);
          return;
        }

        setSelectedStepNumber(4);
      }
    };

    if (!isInitialLoading && email) {
      loadOnboardingStatus();
    }
  }, [isInitialLoading, email]);

  useEffect(() => {});

  return (
    <OnboardingTemplate
      title="Welcome To Dune Security!"
      organization={organization}
      mainContent={
        <div>
          <div>
            <h3 className="h4">Let's get you set up!</h3>
          </div>
          <div className="flex flex-col w-full">
            {steps.map((step, index) => (
              <div className="mt-2" key={`welcome-step-${index}`}>
                <StepButton
                  stepNumber={index + 1}
                  description={step.description}
                  path={step.path}
                  selectedStepNumber={selectedStepNumber}
                />
              </div>
            ))}
          </div>
          {/* <div className="flex justify-end mt-2 p-0.625"> */}
          <div className="flex mt-2 py-0.625 pr-0.625">
            <ButtonOutlinedGreen isSmall onClick={handleClickGetStarted}>
              Get started
            </ButtonOutlinedGreen>
          </div>
        </div>
      }
    />
  );
};

export default Welcome;
